import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ChatConnectionConfig } from '../../../../pss2-shared/chatTypes';

@Injectable({
	providedIn: 'root',
})
export class ChatHttpService {
	constructor(private http: HttpClient) {}

	initChatLoginData(): Observable<ChatConnectionConfig> {
		return this.http.get<ChatConnectionConfig>('/api/restricted/chat-login');
	}
}
