<div cdkDrag class="modal-dialog" role="dialog" aria-hidden="true">
	<div class="modal-content">
		<div class="modal-dlg-header" cdkDragHandle>
			<clr-icon class="drag-handle-icon btn btn-link" shape="drag-handle"></clr-icon>
			<h3 class="modal-title">News</h3>
			<button (click)="close()" aria-label="Close" class="close-modal-btn btn btn-link" type="button">
				<clr-icon aria-hidden="true" shape="close"></clr-icon>
			</button>
		</div>
		<div class="modal-body">
			<quill-view [content]="news" format="json" theme="snow"></quill-view>
		</div>
	</div>
</div>
