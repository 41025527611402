import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthPageComponent } from './containers/auth-page/auth-page.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { RegistrationConfirmationComponent } from './components/registration-confirmation/registration-confirmation.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RegistrationCompletedComponent } from './components/registration-completed/registration-completed.component';
import { CompanyNonActiveComponent } from './components/company-non-active/company-non-active.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordSentComponent } from './components/reset-password-sent/reset-password-sent.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { UserGuard } from './guards/user.guard';

const routes: Routes = [
	{
		path: 'auth',
		component: AuthPageComponent,
		children: [
			{ path: '', redirectTo: 'login', pathMatch: 'prefix' },
			{ path: 'login', component: LoginComponent },
			{ path: 'register', component: RegisterComponent },
			{ path: 'confirmRegistration/:activationToken', component: RegistrationConfirmationComponent },
			{ path: 'registration-completed', component: RegistrationCompletedComponent },
			{ path: 'logout', component: LogoutComponent },
			{ path: 'company-non-active', component: CompanyNonActiveComponent, canActivate: [UserGuard] },
			{ path: 'forgot-password', component: ForgotPasswordComponent },
			{ path: 'reset-password', component: ResetPasswordComponent },
			{ path: 'reset-password-sent', component: ResetPasswordSentComponent },
			{ path: 'update-password', component: UpdatePasswordComponent, canActivate: [UserGuard] },
			{ path: 'verify-otp', component: VerifyOtpComponent, canActivate: [UserGuard] },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AuthRoutingModule {}
