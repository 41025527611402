import { ClrDatagridStateInterface } from '@clr/angular';
import { GeneralTableParams } from '../../../../../pss2-shared/commonTypes';

export function clrStateToQueryObject(state: ClrDatagridStateInterface<any>): GeneralTableParams {
	const output: {
		pageSize?: number;
		page?: number;
		sortBy?: string;
		sortReverse?: boolean;
	} = {};
	if (!state) return output;
	if (state.page) {
		output.pageSize = state.page.size;
		output.page = state.page.current;
	}
	if (state.sort) {
		output.sortBy = state.sort.by as string;
		output.sortReverse = state.sort.reverse;
	}
	if (state.filters) {
		state.filters.forEach((f) => {
			output[f.property] = f.value;
		});
	}
	return output;
}
