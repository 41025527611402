import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthPageState } from '../../store/auth-page.store';
import { Store } from '@ngxs/store';
import { ForgotPassword } from '../../store/auth.actions';
import { tap } from 'rxjs/operators';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	pending$ = null;
	error$;

	form = new UntypedFormGroup({
		email: new UntypedFormControl('', Validators.required),
	});

	constructor(private store: Store) {}

	ngOnInit() {
		this.error$ = this.store.select(AuthPageState.error);
		this.pending$ = this.store
			.select(AuthPageState.pending)
			.pipe(
				tap((isPending) => {
					isPending ? this.form.disable() : this.form.enable();
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		if (this.pending$) this.pending$.unsubscribe();
	}

	submit() {
		if (this.form.valid) {
			this.store.dispatch(new ForgotPassword(this.form.value));
		}
	}
}
