import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { COSTS_BLOCKS, COSTS_BLOCKS_DEPENDENCIES, getCostsBlockName } from '../../../../../pss2-shared/costsBlocks';
import { Select, Store } from '@ngxs/store';
import { AuthState } from 'src/app/auth/store/auth.store';
import { PersistenceService } from 'src/app/shared/persistence.service';
import { PatchUserAppSettings, MuteNews, IntentToSwitchCompany, GetCompanySharedSettings } from 'src/app/auth/store/auth.actions';
import { Subscription, Observable, combineLatest, lastValueFrom, tap, filter } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { MainAppState } from 'src/app/main-app.state';
import { PopupDlgService, PopupDlgOverlayRef } from 'src/app/shared/popup-dlg.service';
import { UserNewsComponent } from '../user-news/user-news.component';
import { TranslateService } from '@ngx-translate/core';
import { UserDto } from '../../../../../pss2-shared/apiModels/index';
import { SurveyComponent } from 'src/app/components/survey/survey.component';
import { ExecutiveReportsHttpService } from 'src/app/executive-reports/executive-reports-http.service';
import { TempFileHttpService } from 'src/app/shared/temp-file-http.service';
import { PSS_PERMISSION_ID } from '../../../../../pss2-shared/permissions';
import { SharedState } from 'src/app/shared/store/shared.store';
import { ChatState } from 'src/app/chat/store/chat.store';
import { UserUrgentNewsComponent } from '../user-urgent-news/user-urgent-news.component';
import { differenceInCalendarDays, startOfDay } from 'date-fns';
import { Router } from '@angular/router';
import { SiteReportStore } from 'src/app/tools/store/site-report.store';
import { LoadActiveSiteReports } from 'src/app/tools/store/site-report.actions';
import { ModalService } from 'src/app/shared/modal-service.service';
import { UserActiveSiteReportsDlgComponent } from 'src/app/tools/components/user-active-site-reports-dlg/user-active-site-reports-dlg.component';
import { ConfirmDlgComponent } from 'src/app/shared/components/confirm-dlg/confirm-dlg.component';
import { ModalRef } from 'src/app/shared/modal-service-models/modal-ref.model';
import { zonedTimeToUtc } from 'date-fns-tz';
import { LoadActivePaveAmericaClientRequestsCount } from 'src/app/crm/store/pave-america-client-requests.actions';
import { PaveAmericaClientRequestsStore } from 'src/app/crm/store/pave-america-client-requests.store';
import { PaveAmericaClientRequestsDlgComponent } from 'src/app/shared/components/pave-america-client-requests-dlg/pave-america-client-requests-dlg.component';
import { PaveAmericaClientRequestStatus } from '@pss2-shared/paveAmerica';
import { SetLeadsCount } from 'src/app/shared/store/shared.actions';
import { LeadsHttpService } from 'src/app/workflow/services/leads-http.service';

@Component({
	selector: 'app-main-page',
	templateUrl: './main-page.component.html',
	styleUrls: ['./main-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainPageComponent implements OnInit, OnDestroy {
	get navigationCollapsed() {
		return this.persistence.get('navigationCollapsed');
	}

	set navigationCollapsed(value) {
		this.persistence.set('navigationCollapsed', value);
		this.persistence.navigationMenuChange$.next();
	}

	npsDlg: PopupDlgOverlayRef;
	urgentNewsDlg: PopupDlgOverlayRef;
	uncompletedSiteReports: ModalRef;

	user;

	@Select(MainAppState.title)
	title$: Observable<string>;

	hideWizard = true;
	sub: Subscription;
	pageTitle = '';
	canEditCompanyProfile = false;
	canEditDirectCosts = false;
	canAccessExecutiveReports = false;
	canEditSettings = false;
	canAccessMeasureTool = false;
	canAccessTodos = false;
	canAccessCompanyFiles = false;
	canConnectQbDesktop = false;
	canAccessDashboard = false;
	canAccessOpportunities = false;
	canAccessBids = false;
	canAccessJobs = false;
	canAccessSchedule = false;
	canAccessCRM = false;
	canAccessMassEmail = false;
	canReviewWebFormLeads = false;
	canReviewPaveAmericaClientRequests = false;
	canAccessSmartSearch = false;
	canAccessTrackTime = false;
	canAccessPayrollReport = false;
	canEditEmployeeData = false;
	canPartnershipData = false;
	logoImage: string;

	newsAvailable;
	newsDlg;

	possibleBlocks;

	uploadingFilesCount = 0;

	chatConnected = false;
	systemNotificationsCount = 0;

	siteReportsCount = 0;
	leadsCount = 0;

	checkUncompletedSiteReports = false;

	paveAmericaClientRequestsCount = 0;

	isPaveAmericaCompany = false;

	private brandTitle = 'PAVEMENT SOFTWARE SOLUTIONS';

	constructor(
		private persistence: PersistenceService,
		private store: Store,
		private cd: ChangeDetectorRef,
		private title: Title,
		private popupDlgService: PopupDlgService,
		private translateService: TranslateService,
		private executiveReportsHttpService: ExecutiveReportsHttpService,
		private leadsHttpService: LeadsHttpService,
		private tempFileHttpService: TempFileHttpService,
		private router: Router,
		private modalService: ModalService
	) {}

	processPermissions() {
		this.canEditCompanyProfile = this.user?.permissions.has(PSS_PERMISSION_ID.company_profile_access_ui);
		this.canAccessExecutiveReports = this.user?.permissions.has(PSS_PERMISSION_ID.executive_reports_access_ui);
		this.canEditSettings = this.user?.permissions.has(PSS_PERMISSION_ID.settings_access_ui);
		this.canEditDirectCosts = this.user?.permissions.has(PSS_PERMISSION_ID.direct_costs_ui);
		this.canAccessMeasureTool = this.user?.permissions.has(PSS_PERMISSION_ID.measure_tool_access_ui);
		this.canAccessTodos = this.user?.permissions.has(PSS_PERMISSION_ID.todo_access_ui);
		this.canAccessCompanyFiles = this.user?.permissions.has(PSS_PERMISSION_ID.company_files_access_ui);
		this.canConnectQbDesktop = this.user?.permissions.has(PSS_PERMISSION_ID.qb_desktop_access);
		this.canAccessDashboard = this.user?.permissions.has(PSS_PERMISSION_ID.dashboard_access_ui);
		this.canAccessOpportunities = this.user?.permissions.has(PSS_PERMISSION_ID.opportunity_access_ui);
		this.canAccessBids = this.user?.permissions.has(PSS_PERMISSION_ID.bid_access_ui);
		this.canAccessJobs = this.user?.permissions.has(PSS_PERMISSION_ID.job_access_ui);
		this.canAccessSchedule = this.user?.permissions.has(PSS_PERMISSION_ID.schedule_access_ui);
		this.canAccessCRM = this.user?.permissions.has(PSS_PERMISSION_ID.crm_access_ui);
		this.canAccessMassEmail = this.user?.permissions.has(PSS_PERMISSION_ID.crm_mass_email);
		this.canReviewWebFormLeads = this.user?.permissions.has(PSS_PERMISSION_ID.review_web_form_leads);
		this.canReviewPaveAmericaClientRequests =
			this.user?.permissions.has(PSS_PERMISSION_ID.review_pave_america_client_requests) && this.user?.permissions.has(PSS_PERMISSION_ID.crm_access_ui);
		this.canAccessTrackTime = this.user?.permissions.has(PSS_PERMISSION_ID.track_time_access);
		this.canAccessPayrollReport =
			this.user?.permissions.has(PSS_PERMISSION_ID.executive_reports_access_ui) ||
			this.user?.permissions.has(PSS_PERMISSION_ID.job_access_ui) ||
			this.user?.permissions.has(PSS_PERMISSION_ID.track_time_access);
		this.canAccessSmartSearch =
			this.user?.permissions.has(PSS_PERMISSION_ID.bid_access_ui) ||
			this.user?.permissions.has(PSS_PERMISSION_ID.opportunity_access_ui) ||
			this.user?.permissions.has(PSS_PERMISSION_ID.job_access_ui) ||
			this.user?.permissions.has(PSS_PERMISSION_ID.crm_access_ui);
		this.canEditEmployeeData = this.user?.permissions.has(PSS_PERMISSION_ID.edit_employee_data);
		this.canPartnershipData = this.user?.permissions.has(PSS_PERMISSION_ID.partnership_access);
	}

	ngOnInit() {
		this.sub = this.store.select(AuthState.appSettings).subscribe((settings) => {
			this.hideWizard = settings.hideWizard;
			this.cd.markForCheck();
		});
		this.sub.add(
			this.store.select(SharedState.uploadingFilesCount).subscribe((uploadingFilesCount) => {
				this.uploadingFilesCount = uploadingFilesCount;
				this.cd.markForCheck();
			})
		);
		this.sub.add(
			combineLatest([this.title$, this.translateService.get(this.brandTitle)]).subscribe(([pageTitle, brandTitle]) => {
				this.pageTitle = pageTitle;
				if (this.pageTitle) {
					this.title.setTitle(`${brandTitle} - ${this.pageTitle}`);
				} else {
					this.title.setTitle(brandTitle);
				}
				this.cd.markForCheck();
			})
		);
		this.sub.add(
			this.store.select(SiteReportStore.siteReports).subscribe(async (siteReports) => {
				this.siteReportsCount = siteReports.length;
				this.cd.markForCheck();
				if (!this.checkUncompletedSiteReports) return;
				this.checkUncompletedSiteReports = false;
				const highlightIds = siteReports
					.filter((s) => {
						const dueDateTime = new Date(s.dueDate).getTime();
						const todayTime = zonedTimeToUtc(startOfDay(new Date()), this.user.timezone).getTime();
						return todayTime > dueDateTime;
					})
					.map((s) => s.id);
				if (!highlightIds.length) return;
				this.uncompletedSiteReports = this.modalService.open(
					ConfirmDlgComponent,
					{
						title: 'You have uncompleted site reports',
						message: 'Do you want to complete them no?',
						widthOverride: 600,
					},
					false
				);

				try {
					await lastValueFrom(this.uncompletedSiteReports.onResult());
					this.openSiteReport(highlightIds);
				} catch (e) {
					//
				}
			})
		);
		this.sub.add(
			this.store.select(AuthState.user).subscribe((user: UserDto) => {
				this.user = user;
				this.processPermissions();
				if (
					this.canEditCompanyProfile &&
					user.surveyDate &&
					differenceInCalendarDays(new Date(), new Date(user.surveyDate)) > 30 &&
					this.npsDlg == null
				) {
					this.npsDlg = this.popupDlgService.open({ componentClass: SurveyComponent, hasBackdrop: false });
				}
				if (user?.needToShowUrgentNews) {
					this.urgentNewsDlg = this.popupDlgService.open({ componentClass: UserUrgentNewsComponent, hasBackdrop: false });
				}
				this.cd.markForCheck();
			})
		);
		this.sub.add(
			this.store.select(ChatState.user).subscribe((user) => {
				this.chatConnected = user != null;
				this.cd.markForCheck();
			})
		);
		this.sub.add(
			this.store.select(ChatState.systemNotifications).subscribe((systemNotifications) => {
				this.systemNotificationsCount = systemNotifications.length;
				this.cd.markForCheck();
			})
		);
		this.sub.add(
			this.store
				.select(AuthState.companySharedSettings)
				.pipe(
					filter(Boolean),
					tap((settings) => {
						this.isPaveAmericaCompany = settings.isPaveAmericaCompany;
						if (settings.logo && settings.logoInHeader) {
							this.logoImage = settings.logo;
						} else {
							this.logoImage = 'assets/img/logo/ps_thin_dark.png';
						}
						this.possibleBlocks = Object.keys(COSTS_BLOCKS_DEPENDENCIES)
							.filter((id: string) => !settings.hiddenDirectCosts.includes(id as COSTS_BLOCKS))
							.map((id) => ({ id, name: getCostsBlockName(COSTS_BLOCKS[id]) }));

						if (settings.isPaveAmericaCompany && this.canReviewPaveAmericaClientRequests) {
							this.store.dispatch(new LoadActivePaveAmericaClientRequestsCount());
						}

						this.cd.markForCheck();
					})
				)
				.subscribe()
		);
		this.sub.add(
			this.store
				.select(PaveAmericaClientRequestsStore.activeRequestsCount)
				.pipe(
					tap((count) => {
						this.paveAmericaClientRequestsCount = count;
						this.cd.markForCheck();
					})
				)
				.subscribe()
		);
		this.store.dispatch(new GetCompanySharedSettings());
		this.store.dispatch(new LoadActiveSiteReports());
		if (this.canReviewWebFormLeads) {
			this.sub.add(
				this.store.select(SharedState.leadsCount).subscribe((leadsCount) => {
					this.leadsCount = leadsCount;
					this.cd.markForCheck();
				})
			);
			this.leadsHttpService
				.getLeadsCount()
				.pipe(tap((leadsCount) => this.store.dispatch(new SetLeadsCount({ leadsCount }))))
				.subscribe();
		}
		this.checkUncompletedSiteReports = true;
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
		if (this.npsDlg) this.npsDlg.destroy();
		if (this.newsDlg) this.newsDlg.destroy();
		if (this.urgentNewsDlg) this.urgentNewsDlg.destroy();
	}

	showNews() {
		this.newsDlg = this.popupDlgService.open({ componentClass: UserNewsComponent, hasBackdrop: false });
	}

	newsClosed() {
		this.store.dispatch(new MuteNews()).subscribe(() => {
			this.cd.markForCheck();
		});
	}

	showSetupGuide() {
		this.store.dispatch(new PatchUserAppSettings({ settings: { hideWizard: false } })).subscribe(() => {
			this.cd.markForCheck();
		});
	}

	async downloadPricingStatistics() {
		const result = await lastValueFrom(this.executiveReportsHttpService.downloadJobPricingStatisticsReportCsv());
		this.tempFileHttpService.openFile(result, false);
	}

	async switchCompany(companyId: number) {
		if (this.user.companyId === companyId) return;
		this.store.dispatch(new IntentToSwitchCompany({ companyId }));
	}

	async openSiteReport(highlightIds: number[] = []) {
		await lastValueFrom(this.modalService.open(UserActiveSiteReportsDlgComponent, { highlightIds }, false).onResult());
	}

	async openPaveAmericaClientRequests() {
		this.modalService
			.open(
				PaveAmericaClientRequestsDlgComponent,
				{
					selectedStatuses: [PaveAmericaClientRequestStatus.in_inbound_review],
				},
				false
			)
			.onResult()
			.subscribe();
	}

	openNotifications() {
		this.router.navigate(['/tools/notifications']);
	}
}
