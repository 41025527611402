import { Component, OnInit, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Store } from '@ngxs/store';
import { AuthState } from './auth/store/auth.store';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { map, switchMap, filter } from 'rxjs/operators';
import { SetTitle } from './main-app.actions';
import { TranslateService } from '@ngx-translate/core';
import { ShiftKeyService } from 'src/app/shared/shift-key.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
	constructor(
		private permissionsService: NgxPermissionsService,
		private store: Store,
		private router: Router,
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private shiftKeyService: ShiftKeyService
	) {
		// this language will be used as a fallback when a translation isn't found in the current language
		this.translateService.setDefaultLang('en');
		// the lang to use, if the lang isn't available, it will use the current loader to get them
		this.translateService.use('en');
	}

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter((e) => e instanceof NavigationEnd),
				map(() => this.route),
				map((route: ActivatedRoute) => {
					while (route.firstChild) route = route.firstChild;
					return route;
				}),
				filter((route: ActivatedRoute) => route.outlet === 'primary'),
				switchMap((route) => route.data),
				map((data) => data.pageTitle),
				filter((pageTitle) => pageTitle != null),
				switchMap((pageTitle) => this.translateService.get(pageTitle))
			)
			.subscribe((pageTitle) => {
				this.store.dispatch(new SetTitle({ title: pageTitle }));
			});
		this.store.select(AuthState.user).subscribe((user) => {
			if (user == null) this.permissionsService.flushPermissions();
			else {
				this.permissionsService.loadPermissions(Array.from(user.permissions).map((p) => p));
			}
		});
	}

	@HostListener('document:keydown.shift')
	handleShiftKeyDown() {
		this.shiftKeyService.setShiftKeyPressed(true);
	}

	@HostListener('document:keyup.shift')
	handleShiftKeyUp() {
		this.shiftKeyService.setShiftKeyPressed(false);
	}
}
