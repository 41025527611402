import { Injectable } from '@angular/core';
import { ResourceHttpService } from 'src/app/shared/resource-http.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class LaborCrewsHttpService extends ResourceHttpService {
	constructor(http: HttpClient) {
		super(http, 'labor/crews');
	}
}
