import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { Router } from '@angular/router';

export class RouteNavigate {
	static readonly type = '[App] Route navigate';
	constructor(
		public commands: string[],
		public queryParams?: any,
		public skipLocationChange?: boolean
	) {}
}

@Injectable({ providedIn: 'root' })
export class RouteHandlerService {
	constructor(
		private router: Router,
		private actions$: Actions
	) {
		this.actions$.pipe(ofActionDispatched(RouteNavigate)).subscribe(({ commands, queryParams, skipLocationChange }) => {
			this.router.navigate(commands, { queryParams, skipLocationChange });
		});
	}
}
