import { Injectable, Injector, ComponentRef } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, ComponentType } from '@angular/cdk/portal';
import { OVERLAY_POPUP_DATA, PopupOverlayRef } from './overlay-popup-data';

export abstract class OverlayComponent {
	constructor(
		public dialogRef: PopupOverlayRef,
		public data: any
	) {}

	close(result?) {
		this.dialogRef.close(result);
	}
}

interface SearchPopupConfig {
	hasBackdrop?: boolean;
	backdropClose?: boolean;
	data?: any;
}

const DEFAULT_CONFIG: SearchPopupConfig = {
	hasBackdrop: true,
	backdropClose: true,
	data: null,
};

@Injectable({
	providedIn: 'root',
})
export class OverlayPopupService {
	constructor(
		private overlay: Overlay,
		private injector: Injector
	) {}

	open<T extends OverlayComponent>(component: ComponentType<T>, config: SearchPopupConfig) {
		// Override default configuration
		const dialogConfig = { ...DEFAULT_CONFIG, ...config };

		// Returns an OverlayRef which is a PortalHost
		const overlayRef = this.createOverlay(dialogConfig);

		// Instantiate remote control
		const dialogRef = new PopupOverlayRef(overlayRef);

		this.attachDialogContainer(component, overlayRef, dialogConfig, dialogRef);

		if (config.backdropClose) overlayRef.backdropClick().subscribe(() => dialogRef.close(null));

		return dialogRef;
	}

	private createOverlay(config: SearchPopupConfig) {
		const overlayConfig = this.getOverlayConfig(config);
		return this.overlay.create(overlayConfig);
	}

	private attachDialogContainer<T>(component: ComponentType<T>, overlayRef: OverlayRef, config: SearchPopupConfig, dialogRef: PopupOverlayRef) {
		const injector = this.createInjector(config, dialogRef);

		const containerPortal = new ComponentPortal(component, null, injector);
		const containerRef: ComponentRef<any> = overlayRef.attach(containerPortal);

		return containerRef.instance;
	}

	private createInjector(config: SearchPopupConfig, dialogRef: PopupOverlayRef): PortalInjector {
		const injectionTokens = new WeakMap();

		injectionTokens.set(PopupOverlayRef, dialogRef);
		injectionTokens.set(OVERLAY_POPUP_DATA, config.data);

		return new PortalInjector(this.injector, injectionTokens);
	}

	private getOverlayConfig(config: SearchPopupConfig): OverlayConfig {
		const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

		const overlayConfig = new OverlayConfig({
			hasBackdrop: config.hasBackdrop,
			backdropClass: 'modal-backdrop',
			panelClass: 'modal',
			scrollStrategy: this.overlay.scrollStrategies.block(),
			positionStrategy,
		});

		return overlayConfig;
	}
}
