import { UntypedFormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { paveAmericaOpportunityTypes } from '@pss2-shared/paveAmerica';

/**
 * makes the field required if the predicate function returns true
 */
export function requiredIfValidator(predicate: (formControl?: AbstractControl) => boolean) {
	return (formControl: AbstractControl) => {
		if (predicate(formControl)) {
			return Validators.required(formControl);
		}
		return null;
	};
}

export function ifValidator(validator: (control: AbstractControl) => ValidationErrors | null, predicate: () => boolean) {
	return (formControl: AbstractControl) => {
		if (predicate()) {
			return validator(formControl);
		}
		return null;
	};
}

export function minLength(minLength: number): (control: AbstractControl) => ValidationErrors | null {
	return (control: AbstractControl): ValidationErrors | null => {
		return control?.value?.length >= minLength ? null : { minlength: true };
	};
}

export function specialCharacters(control: AbstractControl): ValidationErrors | null {
	const regex = /^(.*[?!@#$%^&{}~()[\]+=:;\-<>'"*])([a-zA-Z0-9?!@#$%^&{}~()[\]+=:;\-<>'"*]*)$/;
	return regex.test(control.value) ? null : { specialChar: true };
}

export function upperCharacters(control: AbstractControl): ValidationErrors | null {
	const regex = /^(?=.*[A-Z])(.*)$/;
	return regex.test(control.value) ? null : { upperChar: true };
}

export function lowerCharacters(control: AbstractControl): ValidationErrors | null {
	const regex = /^(?=.*[a-z])(.*)$/;
	return regex.test(control.value) ? null : { lowerChar: true };
}

export function nonZeroValidator(control: AbstractControl): ValidationErrors | null {
	const value = control.value;
	return value == 0 ? { 'non-zero': true } : null;
}

export function arrayMinLength(minLength: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value || !Array.isArray(control.value) || control.value.length < minLength) {
			return {
				arrayMinLength: {
					requiredLength: minLength,
					actualLength: control.value ? control.value.length : 0,
				},
			};
		}

		return null;
	};
}

export function arrayExactLength(length: number) {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value || !Array.isArray(control.value) || control.value.length !== length) {
			return {
				arrayExactLength: {
					length,
					actualLength: control.value ? control.value.length : 0,
				},
			};
		}

		return null;
	};
}

export class ClearFormControl extends UntypedFormControl {
	markAsDirty(): void {}
}

export function minSelectedItems(min: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const selectedItems = control.value.filter(Boolean);

		return selectedItems.length < min
			? {
					minSelectedItems: true,
				}
			: null;
	};
}

export function commaSeparatedEmailsValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		const emails = control.value.split(',').map((email) => email.trim());
		for (const email of emails) {
			if (Validators.email(new UntypedFormControl(email))) {
				return { commaSeparatedEmails: true };
			}
		}

		return null;
	};
}

export function paveAmericaOpportunityTypeValidator(control: AbstractControl): ValidationErrors | null {
	if (!control.value) {
		return null;
	}

	if (paveAmericaOpportunityTypes.includes(control.value)) {
		return null;
	}

	return { paveAmericaOpportunityType: true };
}
