import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap, mergeMap, catchError, filter } from 'rxjs/operators';
import { LoginRedirect, CheckLoginSuccess } from '../store/auth.actions';
import { AuthHttpService } from '../services/auth-http.service';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/auth.store';
import { RouteNavigate } from 'src/app/route-handler.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(
		private store: Store,
		private authHttpService: AuthHttpService
	) {}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		return this.store.selectOnce(AuthState.user).pipe(
			mergeMap((user) => {
				if (user && user.companyIsActive && user.isActive && user.isConfirmed) return of(user);
				return this.authHttpService.checkLogin();
			}),
			catchError(() => of(null)),
			tap((user) => {
				if (user) {
					this.store.dispatch(new CheckLoginSuccess({ user }));
				} else {
					this.store.dispatch(new LoginRedirect({ returnUrl: state.url }));
				}
			}),
			filter(Boolean),
			map((user) => {
				if (!user.isOtpAuthPassed) {
					this.store.dispatch(new RouteNavigate(['/auth/verify-otp'], { returnUrl: state.url }));
				}
				if (user.securityUpdateRequired) {
					this.store.dispatch(new RouteNavigate(['/auth/update-password'], { returnUrl: state.url }));
				}
			}),
			map(() => true)
		);
	}
}
