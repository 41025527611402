<div class="login-wrapper">
	<form clrForm clrLayout="vertical" class="login" [formGroup]="form">
		<section class="title">
			<h3 class="welcome">Welcome to</h3>
			Pavement Software Solutions
			<h5 class="hint">
				There was an issue with your credit card on file and your account is temporarily on hold. Please update your payment information here using your
				email and password or reach out to one of our team members to help get your account updated at
				<a href="mailto:support@pavementsoft.com">support&#64;pavementsoft.com</a>
			</h5>
		</section>
		<div class="login-group">
			<clr-input-container>
				<input type="email" clrInput placeholder="Email" formControlName="email" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
			</clr-input-container>
			<clr-password-container>
				<input type="password" clrPassword placeholder="Password" formControlName="password" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
				<clr-control-error *clrIfError="'minlength'">It must be at least 3 characters!</clr-control-error>
			</clr-password-container>
			<clr-select-container>
				<select clrSelect formControlName="companyId">
					<option value="" disabled selected hidden>Choose company</option>
					<option *ngFor="let c of user?.companies" [ngValue]="c.id" [disabled]="c.companyIsActive || !c.userIsActive">
						{{c.name}}&nbsp;{{getCompanyDisabledLabel(c)}}
					</option>
				</select>
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
			</clr-select-container>
			<div class="clr-form-control clr-row">
				<div [class.clr-error]="stripeError" class="clr-control-container">
					<div class="clr-input-wrapper">
						<div id="card-element-company-non-active" class="clr-input"></div>
					</div>
					<span class="clr-subtext">{{stripeError}}</span>
				</div>
			</div>
			<div class="error active" *ngIf="error$ | async as error">
				{{ error.error }}
			</div>
			<button class="btn btn-primary" type="submit" [clrLoading]="submitBtnState" (click)="submit()" [disabled]="!user">Login</button>
		</div>
	</form>
</div>
