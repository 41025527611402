export enum AuthActionTypes {
	SetDocumentsCount = '[Shared] Set Documents Count',
	AddDocumentsCount = '[Shared] Add Documents Count',
	SetNotesCount = '[Shared] Set Notes Count',
	SetSiteReportsCount = '[Shared] Set Site Report Count',
	AddUploadingFiles = '[Shared] Add Uploading Files',
	FinishedUploadingFiles = '[Shared] Finished Uploading Files',
	SetInvoiceFileCount = '[Shared] Invoice File Count',
	SetLeadsCount = '[Shared] Set Leads Count',
}

export class SetDocumentsCount {
	static readonly type = AuthActionTypes.SetDocumentsCount;
	constructor(public payload: { documentsCount: number }) {}
}

export class AddDocumentsCount {
	static readonly type = AuthActionTypes.AddDocumentsCount;
	constructor(public payload: { documentsCount: number }) {}
}

export class SetNotesCount {
	static readonly type = AuthActionTypes.SetNotesCount;
	constructor(public payload: { notesCount: number }) {}
}

export class SetSiteReportCount {
	static readonly type = AuthActionTypes.SetSiteReportsCount;
	constructor(public payload: { siteReportCount: number }) {}
}

export class SetInvoiceFileCount {
	static readonly type = AuthActionTypes.SetInvoiceFileCount;
	constructor(public payload: { invoiceFileCount: number }) {}
}

export class AddUploadingFiles {
	static readonly type = AuthActionTypes.AddUploadingFiles;
	constructor(public payload: { count: number }) {}
}

export class FinishedUploadingFiles {
	static readonly type = AuthActionTypes.FinishedUploadingFiles;
	constructor(public payload: { count: number }) {}
}

export class SetLeadsCount {
	static readonly type = AuthActionTypes.SetLeadsCount;
	constructor(public payload: { leadsCount: number }) {}
}
