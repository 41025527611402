import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { zip } from 'rxjs';
import { LaborCrewsHttpService } from 'src/app/company-settings/services/labor-crews-http.service';
import { LaborEmployeesHttpService } from 'src/app/company-settings/services/labor-employees-http.service';
import { Modal } from 'src/app/shared/modal-service-models/modal.model';

@Component({
	selector: 'app-select-employees-site-report-dlg',
	templateUrl: './select-employees-site-report-dlg.component.html',
	styleUrls: ['./select-employees-site-report-dlg.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectEmployeesSiteReportDlgComponent extends Modal {
	isLoading = true;

	employeeRoster = [];

	employees = [];

	crews = [];

	searchText = '';

	get ifSearching(): boolean {
		return this.searchText.length > 0;
	}

	employeesByCrews: {
		id: number;
		name: string;
		employees: any[];
	}[] = [];

	noCrew = {
		id: null,
		name: 'No Crew',
		employees: [],
	};

	constructor(
		private employeesHttpService: LaborEmployeesHttpService,
		private crewsHttpService: LaborCrewsHttpService,
		private cd: ChangeDetectorRef
	) {
		super();
	}

	isEmployeeSelected(employeeId: number) {
		return this.employeeRoster.includes(employeeId);
	}

	isEmployeeSelectedChanged(event, employeeId: number) {
		if (!event) {
			this.employeeRoster = this.employeeRoster.filter((id) => id !== employeeId);
		} else {
			if (this.employeeRoster.includes(employeeId)) return;
			this.employeeRoster = [...this.employeeRoster, employeeId];
		}
	}

	onInjectInputs(inputs) {
		this.employeeRoster = inputs?.employeeRoster ?? [];
		zip(this.employeesHttpService.getAll({ showInactive: true }), this.crewsHttpService.getAll()).subscribe(([employees, crews]) => {
			this.employees = employees;
			this.crews = crews;
			for (const crew of crews) {
				const employeesInCrew = this.employees.filter((e) => e.crews.includes(crew.id)).filter((e) => e.isActive || this.employeeRoster.includes(e.id));
				this.employeesByCrews.push({
					id: crew.id,
					name: crew.name,
					employees: employeesInCrew,
				});
			}
			this.noCrew.employees = this.employees.filter((e) => !e.crews.length && (e.isActive || this.employeeRoster.includes(e.id)));
			this.isLoading = false;
			this.cd.markForCheck();
		});
	}

	getEmployeeNameById(employeeId) {
		return this.employees.find((e) => e.id === employeeId)?.name;
	}

	filtered(employees: any[]): any[] {
		if (!employees) return [];
		const result = this.ifSearching ? employees.filter((x) => x.name.toLowerCase().includes(this.searchText)) : employees;
		return result;
	}

	cancel() {
		this.close(null);
	}

	save() {
		this.close(this.employeeRoster);
	}
}
