import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'app-reset-password-sent',
	templateUrl: './reset-password-sent.component.html',
	styleUrls: ['./reset-password-sent.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordSentComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
