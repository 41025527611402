import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Modal } from '../../modal-service-models/modal.model';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-dnd-file-selector-dlg',
	templateUrl: './dnd-file-selector-dlg.component.html',
	styleUrls: ['./dnd-file-selector-dlg.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DndFileSelectorDlgComponent extends Modal {
	faFileUpload = faFileUpload;
	isDragOver = false;
	accept = '';
	acceptTypeArray = [];
	title = '';
	onlyOne = false;
	backdrop = false;

	constructor() {
		super();
	}

	onInjectInputs(inputs: any) {
		this.title = inputs.title ?? 'Select Files';
		if (inputs.accept) {
			this.accept = inputs.accept;
			this.acceptTypeArray = this.accept
				.split(',')
				.map((type) => type.trim().replace('*', ''))
				.filter((v) => v);
		}
		this.onlyOne = inputs.onlyOne ?? false;
		this.backdrop = inputs.backdrop ?? false;
	}

	onFilesSelected(files: File[]) {
		this.close(files);
	}
}
