<div
	class="drag-container"
	[ngStyle]="{'height.px': heightPx}"
	(dragover)="onDragOver($event)"
	(dragleave)="onDragLeave($event)"
	(drop)="onDrop($event)"
	[ngClass]="{'drag-over':isDragOver}">
	<div class="drag-control-wrapper">
		<div class="file-upload-icon">
			<fa-icon [icon]="faFileUpload"></fa-icon>
		</div>
		<div *ngIf="onlyOne">
			<button class="btn btn-primary btn-sm" (click)="selectImage?.click()">
				<input #selectImage class="upload-file-btn" type="file" (change)="filesSelected($event.target.files)" [accept]="accept" />
				Select File
			</button>
			or drop it here
		</div>
		<div *ngIf="!onlyOne">
			<button class="btn btn-primary btn-sm" (click)="selectImage?.click()">
				<input #selectImage class="upload-file-btn" type="file" (change)="filesSelected($event.target.files)" multiple [accept]="accept" />
				Select Files
			</button>
			or drop them here
		</div>
	</div>
</div>
