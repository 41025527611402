import { Component, Input } from '@angular/core';
import { ClrDatagridFilterInterface, ClrDatagridFilter } from '@clr/angular';
import { Subject, Observable } from 'rxjs';

@Component({
	selector: 'app-clr-dg-string-filter',
	templateUrl: './clr-dg-string-filter.component.html',
	styles: [],
})
export class ClrDgStringFilterComponent<T = any> implements ClrDatagridFilterInterface<T, any> {
	@Input()
	property: string;

	pSelected = null;

	/**
	 * The Observable required as part of the Filter interface
	 */
	private pChanges = new Subject<any>();

	set selected(item: any) {
		if (this.pSelected === item) return;
		this.pSelected = item;
		this.apply();
	}

	get selected(): any {
		return this.pSelected;
	}

	constructor(filterContainer: ClrDatagridFilter) {
		filterContainer.setFilter(this);
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	accepts(item: T): boolean {
		return true;
	}

	// We do not want to expose the Subject itself, but the Observable which is read-only
	public get changes(): Observable<any> {
		return this.pChanges.asObservable();
	}

	public isActive(): boolean {
		return this.selected !== null && this.selected !== '';
	}

	public get state() {
		return {
			property: this.property,
			value: this.selected,
		};
	}

	public equals(other: any): boolean {
		return other.prop === this.property && other.selected === this.selected;
	}

	clear() {
		this.selected = '';
		this.apply();
	}

	apply() {
		this.pChanges.next(this.selected);
	}
}
