<clr-icon class="icon" shape="search"></clr-icon>
<ng-select
	class="select"
	[items]="smartSearchResults$ | async"
	[minTermLength]="3"
	[loading]="smartSearchLoading"
	typeToSearchText="Please enter 3 or more characters"
	[typeahead]="smartSearchInput$"
	groupBy="results"
	[selectableGroup]="true"
	(change)="smartSearchChange($event)"
	placeholder="Smart search..."
	[closeOnSelect]="false"
	(close)="close()">
	<ng-template ng-optgroup-tmp let-item="item">
		<div class="clr-row clr-justify-content-between search-group" [class.search-group_expanded]="getGroupExpandedState(item.searchId)">
			<div class="clr-col-auto">
				<div class="search-group__title">
					<fa-icon [icon]="getSmartSearchGroupIcon(item.searchId)" [fixedWidth]="true"> </fa-icon>
					<strong>
						{{getSmartSearchGroupCaption(item.searchId)}}
					</strong>
					<span class="badge badge-info">
						{{item.resultsCount}}
					</span>
				</div>
			</div>
			<div class="clr-col-auto">
				<clr-icon shape="angle" class="search-group__arrow"></clr-icon>
			</div>
		</div>
	</ng-template>
	<ng-template ng-option-tmp let-item="item$" let-search="searchTerm">
		<span [ngOptionHighlight]="search">
			{{formatSmartSearchItem(item.parent.value.searchId, item.value)}}
		</span>
		&nbsp;
		<span *ngIf="withAdditionalInfo.includes(item.parent.value.searchId)">
			{{formatSmartSearchItemAdditionalInfo(item.parent.value.searchId, item.value)}}
		</span>
	</ng-template>
</ng-select>
