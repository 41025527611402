import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Modal } from '../../modal-service-models/modal.model';

type ColumnOrderDlgComponentInputs = {
	columnToLabel: Record<string, string>;
	columns: string[];
};

@Component({
	selector: 'app-column-order-dlg',
	templateUrl: './column-order-dlg.component.html',
	styleUrls: ['./column-order-dlg.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnOrderDlgComponent extends Modal {
	columnToLabel: Record<string, string>;
	columns: string[];

	constructor() {
		super();
	}

	onInjectInputs(inputs: ColumnOrderDlgComponentInputs): void {
		this.columnToLabel = inputs.columnToLabel;
		this.columns = [...inputs.columns];
	}

	ok() {
		this.close(this.columns);
	}

	cancel() {
		this.dismiss();
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
	}
}
