<div class="modal">
	<div class="modal-dialog modal-lg" role="dialog" aria-hidden="true">
		<div class="modal-content">
			<div class="modal-header">
				<button (click)="cancel()" aria-label="Close" class="close" type="button">
					<clr-icon aria-hidden="true" shape="close"></clr-icon>
				</button>
				<h3 class="modal-title">{{'Employee Roster' | translate}}</h3>
			</div>
			<div class="modal-body">
				<div>
					<clr-input-container>
						<input clrInput placeholder="Search" type="text" [(ngModel)]="searchText" autocomplete="off" #autofocus />
					</clr-input-container>
					<clr-tree>
						<clr-tree-node *ngFor="let crew of employeesByCrews" class="crew-name" [clrExpanded]="ifSearching">
							{{crew.name}}
							<clr-tree-node
								*ngFor="let item of filtered(crew.employees)"
								[clrSelected]="isEmployeeSelected(item.id)"
								(clrSelectedChange)="isEmployeeSelectedChanged($event, item.id)">
								{{item.name}}
							</clr-tree-node>
						</clr-tree-node>
						<clr-tree-node *ngIf="noCrew.employees.length" class="crew-name" [clrExpanded]="ifSearching">
							{{noCrew.name}}
							<clr-tree-node
								*ngFor="let item of filtered(noCrew.employees)"
								[clrSelected]="isEmployeeSelected(item.id)"
								(clrSelectedChange)="isEmployeeSelectedChanged($event, item.id)">
								{{item.name}}
							</clr-tree-node>
						</clr-tree-node>
					</clr-tree>
				</div>
				<div>
					<h4>Selected Employees:</h4>
					<div *ngFor="let employeeId of employeeRoster; let i = index;">{{i+1}}. {{getEmployeeNameById(employeeId)}}</div>
				</div>
			</div>
			<div class="modal-footer">
				<button class="btn btn-primary" type="button" (click)="save()">Save</button>
				<button class="btn" type="button" (click)="cancel()">Cancel</button>
			</div>
		</div>
	</div>
</div>
