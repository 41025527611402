<div *ngIf="backdrop" class="modal-backdrop" aria-hidden="true"></div>
<div class="modal">
	<div class="modal-dialog modal-lg" role="dialog" aria-hidden="true">
		<div class="modal-content">
			<div class="modal-dlg-header cursor-default">
				<h3 class="modal-title">{{title | translate}}</h3>
				<button (click)="close(null)" aria-label="Close" class="close-modal-btn btn btn-link" type="button">
					<clr-icon aria-hidden="true" shape="close"></clr-icon>
				</button>
			</div>
			<div class="modal-body">
				<app-dnd-file-selector
					[accept]="accept"
					[acceptTypeArray]="acceptTypeArray"
					[onlyOne]="onlyOne"
					(onFilesSelected)="onFilesSelected($event)"></app-dnd-file-selector>
			</div>
		</div>
	</div>
</div>
