import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginModelRequest, RegistrationModelRequest, UserDto } from '../../../../../pss2-shared/apiModels/index';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuthHttpService {
	constructor(private http: HttpClient) {}

	updatePayment(data): Observable<UserDto> {
		return this.http.post<UserDto>('/api/public/update-payment', data);
	}

	login(data: LoginModelRequest): Observable<UserDto> {
		return this.http.post<UserDto>('/api/public/login', data);
	}

	forgotPassword(data: { email: string }) {
		return this.http.post('/api/public/forgot-password', data);
	}

	checkLogin(): Observable<UserDto> {
		return this.http.get<UserDto>('/api/restricted/checkLogin');
	}

	switchCompany(companyId: number): Observable<UserDto> {
		return this.http.post<UserDto>('/api/restricted/switchCompany', { companyId: companyId });
	}

	logout() {
		return this.http.post('/api/restricted/logout', {});
	}

	register(data: RegistrationModelRequest): Observable<UserDto> {
		return this.http.post<UserDto>('/api/public/register', data);
	}

	confirmRegistration(payload: { token: string }): Observable<UserDto> {
		return this.http.post<UserDto>('/api/public/confirmRegistration', payload);
	}

	validateResetToken(token: string) {
		return this.http.post('/api/public/validate-reset-token', { token });
	}

	changePassword(data) {
		return this.http.post<UserDto>('/api/public/change-password', data);
	}

	requestOtpCode() {
		return this.http.get<any>('/api/verify-otp');
	}

	verifyOtpCode(data) {
		return this.http.post<UserDto>('/api/verify-otp', data);
	}
}
