import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JobStatisticReportParams, ReportFormat } from '../../../../pss2-shared/commonTypes';
import { JobsStatistic, TempFileModel } from '../../../../pss2-shared/apiModels/index';
import { GeneralTableQueryResult } from '../../../../pss2-shared/apiModels/tableQueryResult';
import { BID_TYPE } from '../../../../pss2-shared/sharedConstants';

const rootUrl = '/api/restricted/executive-reports';

@Injectable({
	providedIn: 'root',
})
export class ExecutiveReportsHttpService {
	constructor(private http: HttpClient) {}

	getPayrollReport<T = any>(params = {}) {
		return this.http.get<T[]>(`${rootUrl}/payroll`, { params });
	}

	verifyPayroll(employee: { employeeId: number; isTimeVerified: boolean }) {
		return this.http.post<{
			id: number;
			isTimeVerified: boolean;
			isTimeVerifiedBy: number;
			isTimeVerifiedAt: string;
		}>(`${rootUrl}/verify-payroll`, employee);
	}

	printPayrollReport(params = {}) {
		return this.http.get<any>(`${rootUrl}/payroll/print`, { params });
	}

	downloadPayrollReportCsv(params = {}) {
		return this.http.get<any>(`${rootUrl}/payroll/csv`, { params });
	}

	getJobsDirectCostsReport(params = {}) {
		return this.http.get<GeneralTableQueryResult>(`${rootUrl}/jobs-direct-costs-report`, { params });
	}

	printJobsDirectCostsReport(params = {}) {
		return this.http.get<any>(`${rootUrl}/jobs-direct-costs-report/print`, {
			params,
		});
	}

	downloadJobsDirectCostsReportCsv(params = {}) {
		return this.http.get<any>(`${rootUrl}/jobs-direct-costs-report/csv`, {
			params,
		});
	}

	statisticSalesPeople(params: { startDate: string; endDate: string; bidType: BID_TYPE; businessDivisionId: number[] }) {
		return this.http.get<any>(`${rootUrl}/salespeople`, {
			params,
		});
	}

	generateProposalActivityReport({
		userId,
		...params
	}: {
		format: ReportFormat;
		startDate: string;
		endDate: string;
		bidType: BID_TYPE;
		businessDivisionId: number[];
		userId?: number | null;
	}) {
		const queryParams: any = userId ? { ...params, userId } : params;

		return this.http.get<any>(`${rootUrl}/proposal-activity`, {
			params: queryParams,
		});
	}

	generateOpportunityReport(params: { userId?: number; startDate?: string; endDate?: string }) {
		return this.http.get<any>(`${rootUrl}/opportunity`, { params });
	}

	jobDailyReport(params) {
		return this.http.get<any>(`${rootUrl}/jobs-daily`, { params });
	}

	jobDailyWoReport(params) {
		return this.http.get<any>(`${rootUrl}/jobs-daily-wo`, { params });
	}

	costsByWorkOrderCSV(params) {
		return this.http.get<TempFileModel>(`${rootUrl}/jobs-daily-wo-costs-csv`, {
			params,
		});
	}

	jobVarianceReport(params) {
		return this.http.get<any>(`${rootUrl}/jobs-variance`, { params });
	}

	crewDailyReport(params) {
		return this.http.get<any>(`${rootUrl}/crews-daily`, { params });
	}

	crewProductionReport(params) {
		return this.http.get<any>(`${rootUrl}/crews-production`, { params });
	}

	jobStatisticReport(params: JobStatisticReportParams) {
		return this.http.get<JobsStatistic>(`${rootUrl}/jobs-statistic`, {
			params: params as any,
		});
	}

	generateJobStatisticReportPdf(params: JobStatisticReportParams) {
		return this.http.get<TempFileModel>(`${rootUrl}/jobs-statistic/pdf`, {
			params: params as any,
		});
	}

	generateJobGroupedCostsReportCsv(params) {
		return this.http.get<TempFileModel>(`${rootUrl}/jobs-grouped-costs/csv`, {
			params,
		});
	}

	generateJobGroupedCostsDailyReportCsv(params) {
		return this.http.get<TempFileModel>(`${rootUrl}/jobs-grouped-costs-daily/csv`, { params });
	}

	getProfitabilityReport(
		params: {
			showInactive?: boolean;
			page?: number;
			pageSize?: number;
			sortBy?: string;
			sortReverse?: boolean;
		} & { [key: string]: any }
	) {
		return this.http.get<GeneralTableQueryResult>(`${rootUrl}/profitability`, {
			params,
		});
	}

	downloadProfitabilityReportCsv(params) {
		return this.http.get<TempFileModel>(`${rootUrl}/profitability/csv`, {
			params,
		});
	}

	getCalendarDayTotals(days, costsBlocks) {
		return this.http.post<any>(`${rootUrl}/day-statistic`, {
			days,
			costsBlocks,
		});
	}

	getJobPricingStatisticsReport(params = null) {
		return params
			? this.http.get<GeneralTableQueryResult>(`${rootUrl}/pricing-statistics`, { params })
			: this.http.get<GeneralTableQueryResult>(`${rootUrl}/pricing-statistics`);
	}

	downloadJobPricingStatisticsReportCsv(params = null) {
		return params
			? this.http.get<TempFileModel>(`${rootUrl}/pricing-statistics/csv`, {
					params,
				})
			: this.http.get<TempFileModel>(`${rootUrl}/pricing-statistics/csv`);
	}

	getMaterialReport(params) {
		return this.http.get<GeneralTableQueryResult>(`${rootUrl}/material-report`, { params });
	}

	downloadMaterialReportCsv(params) {
		return this.http.get<TempFileModel>(`${rootUrl}/material-report/csv`, { params });
	}

	getBidsLostSurveyReport(params) {
		return this.http.get<GeneralTableQueryResult>(`${rootUrl}/bids-lost-survey`, { params });
	}

	getBidsLostSurveyReportCsv(params) {
		return this.http.get<TempFileModel>(`${rootUrl}/bids-lost-survey/csv`, {
			params,
		});
	}

	getJobSurveyReport(params) {
		return this.http.get<GeneralTableQueryResult>(`${rootUrl}/job-survey`, {
			params,
		});
	}

	getJobSurveyReportCsv(params) {
		return this.http.get<TempFileModel>(`${rootUrl}/job-survey/csv`, {
			params,
		});
	}
}
