import { HTTP_INTERCEPTORS } from '@angular/common/http';

// import { HttpLoggingInterceptor } from './http-logging-interceptor';
import { Http401Interceptor } from './http-401-interceptor';
import { Http403Interceptor } from './http-403-interceptor';
import { HttpCleanNullIdInterceptor } from './http-clean-null-id-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
	// { provide: HTTP_INTERCEPTORS, useClass: HttpLoggingInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: Http401Interceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: Http403Interceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: HttpCleanNullIdInterceptor, multi: true },
];
