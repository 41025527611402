<form clrForm clrLayout="vertical">
	<clr-input-container [style.margin]="0">
		<ng-select
			#multiselect
			clrInput
			placeholder="You can select multiple items"
			[groupBy]="groupBy"
			[selectableGroup]="selectableGroup"
			[selectableGroupAsModel]="selectableGroupAsModel"
			[multiple]="true"
			[items]="options"
			bindLabel="name"
			bindValue="id"
			[closeOnSelect]="false"
			name="selected"
			[(ngModel)]="_selected">
			<ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
				<div class="item-wrapper">
					<input name="item-{{index}}" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
					<span class="item-label" [class.inactive]="item.isActive === false" [title]="item.name">{{item.name}}</span>
				</div>
			</ng-template>
		</ng-select>
	</clr-input-container>
	<div class="clr-form-control">
		<div class="btn-group">
			<button class="btn btn-primary" type="submit" (click)="apply()">Apply</button>
			<button class="btn" type="button" (click)="clear()">Clear</button>
		</div>
	</div>
</form>
