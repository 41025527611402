import { Injectable } from '@angular/core';
import { PersistenceService } from './persistence.service';

@Injectable({
	providedIn: 'root',
})
export class LinksInNewTabsService {
	private _linksInNewTabs: boolean = false;

	public get linksInNewTabs(): boolean {
		return this._linksInNewTabs;
	}

	public set linksInNewTabs(value: boolean) {
		this.persistenceService.linksInNewTabs = value;
		this._linksInNewTabs = value;
	}

	public get linkTarget(): string {
		return this.persistenceService.linksInNewTabs ? '_blank' : '_self';
	}

	public constructor(private persistenceService: PersistenceService) {
		this._linksInNewTabs = persistenceService.linksInNewTabs;
	}
}
