import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/auth/store/auth.store';
import { PatchUserAppSettings } from 'src/app/auth/store/auth.actions';
import { PSS_PERMISSION_ID } from '../../../../../pss2-shared/permissions';

interface WizardStep {
	text: string;
	url: string;
	hidden?: boolean;
}

@Component({
	selector: 'app-setup-wizard',
	templateUrl: './setup-wizard.component.html',
	styles: [],
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetupWizardComponent implements AfterViewInit {
	currentStepIndex = 0;
	steps: WizardStep[];

	constructor(
		private router: Router,
		private store: Store
	) {}

	ngAfterViewInit() {
		const user = this.store.selectSnapshot(AuthState.user);
		if (user.permissions.has(PSS_PERMISSION_ID.company_profile_access_ui)) {
			this.steps = [
				{
					text: 'Complete user profile information',
					url: `/setup/users/edit/${user.id}/profile`,
				},
				{
					text: 'Complete company profile information',
					url: '/setup/company-profile',
				},
				{
					text: 'Complete office profile and select proposal template',
					url: `/setup/offices/edit/${user.offices[0]}/profile`,
				},
				{
					text: 'Add more offices',
					url: '/setup/offices',
				},
				{
					text: 'Add users',
					url: '/setup/users',
				},
				{
					text: 'Add categories',
					url: '/setup/categories',
				},
				{
					text: 'Add Calendar Crews',
					url: '/setup/labor-crews',
				},
				{
					text: 'Modify Proposal Preface, Pricing, Terms',
					url: `/setup/offices/edit/${user.offices[0]}/proposal-text`,
				},
				{
					text: 'Modify Proposal Item components',
					url: '/setup/proposal-items',
				},
				{
					text: 'Modify Direct Costs',
					url: '/setup/direct-costs/asphalt',
				},
			];
		} else {
			this.steps = [
				{
					text: 'Complete user profile information',
					url: `/setup/users/edit/${user.id}/profile`,
				},
			];
		}
	}

	stepVisible(step: WizardStep) {
		return !step.hidden;
	}

	switchStep() {
		if (this.currentStepIndex < this.steps.filter(this.stepVisible).length - 1) {
			this.currentStepIndex += 1;
		} else {
			this.currentStepIndex = 0;
		}
	}

	stepClosed(step: WizardStep, closed: boolean) {
		step.hidden = closed;
		if (this.steps.filter(this.stepVisible).length === 0) this.cancelWizard();
	}

	async cancelWizard() {
		this.store.dispatch(new PatchUserAppSettings({ settings: { hideWizard: true } }));
	}

	async runStepAction(step: WizardStep) {
		await this.router.navigate([step.url]);
		this.switchStep();
	}

	onStepChange(index) {
		this.currentStepIndex = index;
	}
}
