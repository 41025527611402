import { InjectionToken, EventEmitter } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { Observable } from 'rxjs';

export interface SearchPopupData {
	title: string;
	items$: Observable<any[]>;
}

export const SEARCH_POPUP_DATA = new InjectionToken<SearchPopupData>('SEARCH_POPUP_DATA');

interface SearchResult {
	items: any[];
}

export const OVERLAY_POPUP_DATA = new InjectionToken<any>('OVERLAY_POPUP_DATA');

export class PopupOverlayRef {
	private closeEmitter = new EventEmitter<any>();
	close$ = this.closeEmitter.asObservable();

	constructor(private overlayRef: OverlayRef) {}

	destroy(): void {
		this.closeEmitter.complete();
		this.overlayRef.dispose();
	}

	close(data?: any): void {
		this.overlayRef.dispose();
		this.closeEmitter.emit(data);
		this.closeEmitter.complete();
	}
}

export class SearchPopupOverlayRef {
	private closeEmitter = new EventEmitter<SearchResult>();
	close$ = this.closeEmitter.asObservable();

	constructor(private overlayRef: OverlayRef) {}

	destroy(): void {
		this.overlayRef.dispose();
	}

	close(data: SearchResult): void {
		this.overlayRef.dispose();
		this.closeEmitter.emit(data);
		this.closeEmitter.complete();
	}
}
