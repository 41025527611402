import { Component, AfterViewInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, ControlContainer, UntypedFormControl, Validators } from '@angular/forms';
import { COUNTRIES, STATES } from '../../../../../../pss2-shared/countries';
import { decodeGoogleAddress } from '../../../../../../pss2-shared/utilFuncs';
import { ClrForm } from '@clr/angular';

export function getAddressFormGroup(required = true, disabled = false) {
	return new UntypedFormGroup({
		street: new UntypedFormControl({ value: '', disabled }, required ? Validators.required : null),
		suite: new UntypedFormControl({ value: '', disabled }),
		city: new UntypedFormControl({ value: '', disabled }, required ? Validators.required : null),
		country: new UntypedFormControl({ value: 'US', disabled }, required ? Validators.required : null),
		state: new UntypedFormControl({ value: '', disabled }, required ? Validators.required : null),
		postalCode: new UntypedFormControl({ value: '', disabled }),
		geolocation: new UntypedFormGroup({
			lat: new UntypedFormControl({ value: null, disabled }),
			lng: new UntypedFormControl({ value: null, disabled }),
		}),
	});
}

@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements AfterViewInit {
	@ViewChild(ClrForm, { static: true })
	clrForm: ClrForm;

	@Input()
	isReadonly = false;

	@Output()
	autocompleteSelected = new EventEmitter<void>();

	countries = COUNTRIES;
	states = STATES;

	get form(): UntypedFormGroup {
		return this.controlContainer.control as UntypedFormGroup;
	}

	get street() {
		return this.form.controls.street.value;
	}

	get suite() {
		return this.form.controls.suite.value;
	}

	get city() {
		return this.form.controls.city.value;
	}

	get country() {
		return this.countries[this.form.controls.country.value] ?? '';
	}

	get state() {
		const states = this.states[this.form.controls.country.value] ?? [];

		const state = states.find((state) => state.abbreviation === this.form.controls.state.value);

		return state?.name ?? '';
	}

	get postalCode() {
		return this.form.controls.postalCode.value;
	}

	get geolocation() {
		return this.form.controls.geolocation.value;
	}

	constructor(private controlContainer: ControlContainer) {}

	ngAfterViewInit() {}

	onAutocompleteSelected($event: google.maps.places.PlaceResult) {
		const geolocation = $event.geometry &&
			$event.geometry.location && {
				lat: $event.geometry.location.lat(),
				lng: $event.geometry.location.lng(),
			};
		const address = decodeGoogleAddress($event.address_components, geolocation);
		const oldAddress = this.form.value;
		this.form.patchValue(address);
		if (geolocation?.lat != oldAddress?.geolocation?.lat || geolocation?.lng != oldAddress?.geolocation?.lng) {
			this.autocompleteSelected.emit();
		}
	}
}
