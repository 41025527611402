import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-dnd-file-selector',
	templateUrl: './dnd-file-selector.component.html',
	styleUrls: ['./dnd-file-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DndFileSelectorComponent implements OnInit {
	faFileUpload = faFileUpload;
	isDragOver = false;

	@Input()
	accept = '';

	@Input()
	acceptTypeArray = [];

	@Input()
	onlyOne = false;

	@Output()
	onFilesSelected = new EventEmitter<File[]>();

	@Input()
	heightPx = 300;

	constructor(private toaster: ToastrService) {}

	ngOnInit(): void {}

	getFileName(file: File) {
		let fileName = file.name.substring(0, 30);
		if (file.name.length > 30) {
			fileName = fileName + '...';
		}
		return fileName;
	}

	filesSelected(filesList) {
		const files = Array.from(filesList) as File[];
		if (this.onlyOne && files.length !== 1) {
			this.toaster.error('One file should be selected!');
			return;
		}
		for (const f of files) {
			if (!f.type && f.size % 4096 == 0) {
				const fileName = this.getFileName(f);
				this.toaster.error(`${fileName} is a folder`);
				return;
			}
			if (!this.acceptTypeArray.length) continue;
			const isAcceptable = this.acceptTypeArray.some((t) => f.type.includes(t));
			if (isAcceptable) continue;
			const fileName = this.getFileName(f);
			this.toaster.error(`${fileName} is of invalid type`);
			return;
		}
		this.onFilesSelected.emit(files);
	}

	onDragOver(event) {
		event.preventDefault();
		event.stopPropagation();
		this.isDragOver = true;
	}

	onDragLeave(event) {
		event.preventDefault();
		event.stopPropagation();
		this.isDragOver = false;
	}

	onDrop(event) {
		event.preventDefault();
		event.stopPropagation();
		this.filesSelected(event.dataTransfer.files);
		this.isDragOver = false;
	}
}
