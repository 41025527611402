import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { UpdateUser } from 'src/app/auth/store/auth.actions';
import { CompanySettingsHttpService } from 'src/app/company-settings/services/company-settings-http.service';
import { PopupDlgOverlayRef } from 'src/app/shared/popup-dlg.service';

@Component({
	selector: 'app-user-urgent-news',
	templateUrl: './user-urgent-news.component.html',
	styleUrls: ['./user-urgent-news.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserUrgentNewsComponent implements OnInit {
	news;
	date;
	loading = true;
	constructor(
		private httpService: CompanySettingsHttpService,
		private dialogRef: PopupDlgOverlayRef,
		private cd: ChangeDetectorRef,
		private store: Store
	) {}

	ngOnInit() {
		this.httpService.getUrgentNews().subscribe((data) => {
			this.news = data.activeText;
			this.date = data.date;
			this.loading = false;
			this.cd.markForCheck();
		});
	}

	acknowledge() {
		this.httpService.acknowledgeUrgentNews(this.date).subscribe(() => {});
		this.store.dispatch(new UpdateUser({ needToShowUrgentNews: false }));
		this.dialogRef.close(null);
	}
}
