<form [formGroup]="form">
	<ng-container *ngIf="!isReadonly; else readonlyTemplate">
		<clr-input-container>
			<label>Street</label>
			<input
				ngx-gp-autocomplete
				(onAddressChange)="onAutocompleteSelected($event)"
				clrInput
				type="text"
				formControlName="street"
				placeholder="Type to search address" />
			<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
		</clr-input-container>
		<clr-input-container>
			<label>Suite</label>
			<input clrInput type="text" formControlName="suite" />
		</clr-input-container>
		<clr-input-container>
			<label>City</label>
			<input clrInput type="text" formControlName="city" />
			<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
		</clr-input-container>
		<clr-select-container>
			<label>Country</label>
			<select clrSelect formControlName="country">
				<option *ngFor="let country of countries | keyvalue" [value]="country.key">{{country.value}}</option>
			</select>
		</clr-select-container>
		<clr-select-container>
			<label>State</label>
			<select clrSelect formControlName="state">
				<option *ngFor="let state of states[form.controls.country.value]" [value]="state.abbreviation">
					{{state.name}}
				</option>
			</select>
			<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
		</clr-select-container>
		<clr-input-container>
			<label>Zip Code</label>
			<input clrInput type="text" formControlName="postalCode" />
		</clr-input-container>
	</ng-container>

	<clr-input-container *ngIf="geolocation.lat != null && geolocation.lng != null">
		<label>Location</label>
		<label class="readonly-label">lat: {{geolocation.lat | number: '1.0-7'}}</label>
		<label class="readonly-label">lng: {{geolocation.lng | number: '1.0-7'}}</label>
	</clr-input-container>
</form>

<ng-template #loading> Loading Data... </ng-template>

<ng-template #readonlyTemplate>
	<div class="clr-form-control clr-row clr-align-items-end readonly-field">
		<label class="clr-control-label clr-col-2">Street</label>
		<label class="clr-control-label clr-col-10">{{street}}</label>
	</div>
	<div class="clr-form-control clr-row clr-align-items-end readonly-field">
		<label class="clr-control-label clr-col-2">Suite</label>
		<label class="clr-control-label clr-col-10">{{suite}}</label>
	</div>
	<div class="clr-form-control clr-row clr-align-items-end readonly-field">
		<label class="clr-control-label clr-col-2">City</label>
		<label class="clr-control-label clr-col-10">{{city}}</label>
	</div>
	<div class="clr-form-control clr-row clr-align-items-end readonly-select">
		<label class="clr-control-label clr-col-2">Country</label>
		<label class="clr-control-label clr-col-10">{{country}}</label>
	</div>
	<div class="clr-form-control clr-row clr-align-items-end readonly-select">
		<label class="clr-control-label clr-col-2">State</label>
		<label class="clr-control-label clr-col-10">{{state}}</label>
	</div>
	<div class="clr-form-control clr-row clr-align-items-end readonly-field">
		<label class="clr-control-label clr-col-2">Zip Code</label>
		<label class="clr-control-label clr-col-10 clr-align-self-center">{{postalCode}}</label>
	</div>
</ng-template>
