import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { DEFAULT_TIMEZONE } from '../../../../pss2-shared/timezones';
import { utcToZonedTime } from 'date-fns-tz';
import { AuthState } from '../auth/store/auth.store';

@Pipe({
	name: 'timezone',
})
export class TimezonePipe implements PipeTransform {
	timezone;

	constructor(private store: Store) {
		this.timezone = this.store.selectSnapshot(AuthState.user)?.timezone ?? DEFAULT_TIMEZONE;
	}

	transform(date: string | Date): Date {
		if (!date) return null;
		return utcToZonedTime(date, this.timezone);
	}
}
