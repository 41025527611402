import { Injectable, Injector, ComponentRef, InjectionToken, EventEmitter, Component } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, ComponentType } from '@angular/cdk/portal';

export const POPUP_DLG_DATA = new InjectionToken<any>('POPUP_DLG_DATA');

export class PopupDlgOverlayRef {
	private closeEmitter = new EventEmitter<any>();
	close$ = this.closeEmitter.asObservable();

	constructor(private overlayRef: OverlayRef) {}

	destroy(): void {
		this.overlayRef.dispose();
	}

	close(data: any): void {
		this.overlayRef.dispose();
		this.closeEmitter.emit(data);
		this.closeEmitter.complete();
	}
}

interface PopupDlgConfig {
	componentClass: ComponentType<any>;
	hasBackdrop?: boolean;
	backdropClose?: boolean;
	noSpace?: boolean;
	data?: any;
	origin?: any;
	useOriginWidth?: boolean;
	positions?: ConnectionPositionPair[];
}

const DEFAULT_CONFIG: PopupDlgConfig = {
	componentClass: null,
	hasBackdrop: true,
	backdropClose: true,
	data: null,
	origin: null,
};

@Injectable({
	providedIn: 'root',
})
export class PopupDlgService {
	constructor(
		private overlay: Overlay,
		private injector: Injector
	) {}

	open(config: PopupDlgConfig): PopupDlgOverlayRef {
		// Override default configuration
		const dialogConfig = { ...DEFAULT_CONFIG, ...config };

		// Returns an OverlayRef which is a PortalHost
		const overlayRef = this.createOverlay(dialogConfig);

		// Instantiate remote control
		const dialogRef = new PopupDlgOverlayRef(overlayRef);

		this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

		if (config.backdropClose) {
			overlayRef.backdropClick().subscribe(() => dialogRef.close(null));
		}

		return dialogRef;
	}

	private createOverlay(config: PopupDlgConfig) {
		const overlayConfig = this.getOverlayConfig(config);
		return this.overlay.create(overlayConfig);
	}

	private attachDialogContainer(overlayRef: OverlayRef, config: PopupDlgConfig, dialogRef: PopupDlgOverlayRef) {
		const injector = this.createInjector(config, dialogRef);

		const containerPortal = new ComponentPortal(config.componentClass, null, injector);
		const containerRef: ComponentRef<Component> = overlayRef.attach(containerPortal);

		return containerRef.instance;
	}

	private createInjector(config: PopupDlgConfig, dialogRef: PopupDlgOverlayRef): PortalInjector {
		const injectionTokens = new WeakMap();

		injectionTokens.set(PopupDlgOverlayRef, dialogRef);
		injectionTokens.set(POPUP_DLG_DATA, config.data);

		return new PortalInjector(this.injector, injectionTokens);
	}

	private getPositionStrategy(config: PopupDlgConfig) {
		if (config.origin) {
			return this.overlay
				.position()
				.flexibleConnectedTo(config.origin)
				.withPositions(
					config.positions ?? [
						new ConnectionPositionPair({ originX: 'end', originY: 'top' }, { overlayX: 'start', overlayY: 'top' }),
						new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'start', overlayY: 'bottom' }),
						new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'end', overlayY: 'top' }),
						new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'end', overlayY: 'bottom' }),
					]
				)
				.withViewportMargin(10);
			// const rect: DOMRect = config.origin.getBoundingClientRect();
			// return this.overlay.position()
			// 	.global()
			// 	.left( `${rect.x}px` )
			// 	.top( `${rect.y + rect.height + 1}px` );
		} else {
			return this.overlay.position().global().centerHorizontally().centerVertically();
		}
	}

	private getOverlayConfig(config: PopupDlgConfig): OverlayConfig {
		const positionStrategy = this.getPositionStrategy(config);

		if (config.origin)
			return new OverlayConfig({
				scrollStrategy: this.overlay.scrollStrategies.block(),
				positionStrategy,
			});

		const panelClass = ['modal'];

		if (config.noSpace) {
			panelClass.push('no-space');
		}

		const overlayConfig = new OverlayConfig({
			hasBackdrop: config.hasBackdrop,
			backdropClass: 'modal-backdrop',
			panelClass,
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			positionStrategy,
		});

		return overlayConfig;
	}
}
