import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Login } from '../../store/auth.actions';
import { tap } from 'rxjs/operators';
import { AuthPageState } from '../../store/auth-page.store';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { LoginModelRequest } from '../../../../../../pss2-shared/apiModels/index';
import { PersistenceService } from 'src/app/shared/persistence.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
	pending$ = null;
	error$;

	form: UntypedFormGroup = new UntypedFormGroup({
		email: new UntypedFormControl('', Validators.required),
		password: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
	});

	backgroundImage: string | null;

	constructor(
		private store: Store,
		private route: ActivatedRoute,
		private persistenceService: PersistenceService
	) {}

	ngOnInit() {
		this.error$ = this.store.select(AuthPageState.error);
		this.pending$ = this.store
			.select(AuthPageState.pending)
			.pipe(
				tap((isPending) => {
					isPending ? this.form.disable() : this.form.enable();
				})
			)
			.subscribe();

		this.backgroundImage = this.persistenceService.getLoginBackground();
	}

	ngOnDestroy() {
		if (this.pending$) this.pending$.unsubscribe();
	}

	submit() {
		if (this.form.valid) {
			const loginRequest = this.form.value as LoginModelRequest;
			const date = new Date();
			date.setHours(0, 0, 0, 0);
			loginRequest.localTime = date.toISOString();
			this.store.dispatch(new Login(loginRequest, this.route.snapshot.queryParams.returnUrl));
		}
	}

	getBackgroundImage() {
		if (!this.backgroundImage) {
			return null;
		}

		return `url(${this.backgroundImage})`;
	}
}
