import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import {
	ChatLogin,
	ConnectToServer,
	DisconnectFromServer,
	IncomingMessage,
	IncomingSystemNotification,
	MarkChatMessagesRead,
	PullChatHistory,
	PullChatOnlineState,
	RemoveSystemNotification,
	SendMessage,
	UserChatStateUpdated,
} from './chat.actions';
import { Injectable } from '@angular/core';
import {
	ChatContact,
	ChatMessage,
	ChatOnlineState,
	ChatSystemNotification,
	ChatUserDto,
	ChatUserState,
	CHAT_RPC_COMMANDS,
	CHAT_RPC_NOTIFICATIONS,
} from '../../../../../pss2-shared/chatTypes';
import { webSocket } from 'rxjs/webSocket';
import { ChatHttpService } from '../chat-http.service';
import { catchError, filter } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import JsonRPC from 'simple-jsonrpc-js';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { AuthState } from 'src/app/auth/store/auth.store';
import { environment } from 'src/environments/environment';

export class ChatModel {
	user: ChatUserDto;
	messages: ChatMessage[];
	contacts: ChatContact[];
	incomingMessage: ChatMessage;
	systemNotifications: ChatSystemNotification[];
}

@State<ChatModel>({
	name: 'chat',
	defaults: { user: null, messages: [], contacts: [], incomingMessage: null, systemNotifications: [] },
})
@Injectable()
export class ChatState {
	jrpc = new JsonRPC();
	disconnectWebSocket: () => void;

	@Selector()
	static user(state: ChatModel) {
		return state.user;
	}

	@Selector()
	static messages(state: ChatModel) {
		return state.messages;
	}

	@Selector()
	static incomingMessage(state: ChatModel) {
		return state.incomingMessage;
	}

	@Selector()
	static contacts(state: ChatModel) {
		return state.contacts;
	}

	@Selector()
	static systemNotifications(state: ChatModel) {
		return state.systemNotifications;
	}

	constructor(
		private store: Store,
		private chatHttpService: ChatHttpService
	) {
		this.jrpc.on(CHAT_RPC_NOTIFICATIONS.incoming_message, ['message'], async (message: ChatMessage) => {
			this.store.dispatch(new IncomingMessage(message));
		});
		this.jrpc.on(CHAT_RPC_NOTIFICATIONS.user_state, ['state'], async (state: ChatUserState) => {
			this.store.dispatch(new UserChatStateUpdated(state));
		});
		this.jrpc.on(CHAT_RPC_NOTIFICATIONS.incoming_system_notification, ['notification'], async (notification: ChatSystemNotification) => {
			this.store.dispatch(new IncomingSystemNotification(notification));
		});
	}

	@Action(IncomingSystemNotification)
	incomingSystemNotification(ctx: StateContext<ChatModel>, { payload }: IncomingSystemNotification) {
		return ctx.setState(
			patch({
				systemNotifications: append([payload]),
			})
		);
	}

	@Action(RemoveSystemNotification)
	removeSystemNotification(ctx: StateContext<ChatModel>, { payload }: RemoveSystemNotification) {
		return ctx.setState(
			patch({
				systemNotifications: removeItem(payload.index),
			})
		);
	}

	@Action(ChatLogin)
	login(ctx: StateContext<ChatModel>, { payload }) {
		return this.jrpc.call(CHAT_RPC_COMMANDS.login, [payload]).then(() => {
			ctx.patchState({ user: payload });
		});
	}

	@Action(IncomingMessage)
	incomingMessage(ctx: StateContext<ChatModel>, { payload }: IncomingMessage) {
		const state = ctx.getState();
		const isMessageSentByMe = payload.from === state.user.userId && payload.to !== state.user.userId;
		return ctx.setState(
			patch({
				incomingMessage: isMessageSentByMe ? null : payload,
				messages: append([payload]),
			})
		);
	}

	@Action(UserChatStateUpdated)
	userChatStateUpdated(ctx: StateContext<ChatModel>, { payload }) {
		const state = ctx.getState();
		if (!state.user) return;
		if (payload.userId === state.user.userId) return;

		return ctx.setState(patch({ contacts: updateItem<ChatContact>((c) => c.id === payload.userId, patch({ online: payload.online })) }));
	}

	@Action(PullChatHistory)
	pullChatHistory(ctx: StateContext<ChatModel>, { payload }: PullChatHistory) {
		return this.jrpc.call(CHAT_RPC_COMMANDS.pullChatHistory, [payload]).then((result: { total: number; results: ChatMessage[] }) => {
			ctx.patchState({ messages: result.results });
		});
	}

	@Action(PullChatOnlineState)
	pullChatState(ctx: StateContext<ChatModel>) {
		return this.jrpc.call(CHAT_RPC_COMMANDS.pullChatState, []).then((onlineState: ChatOnlineState) => {
			ctx.patchState({ contacts: onlineState.contacts });
		});
	}

	@Action(SendMessage)
	sendMessage(ctx, { payload }) {
		return this.jrpc.call(CHAT_RPC_COMMANDS.sendChatMessage, [payload]);
	}

	@Action(MarkChatMessagesRead)
	markChatMessagesRead(ctx: StateContext<ChatModel>, { payload }: MarkChatMessagesRead) {
		return this.jrpc.call(CHAT_RPC_COMMANDS.markMessagesRead, [payload]);
	}

	@Action(ConnectToServer)
	connect(ctx: StateContext<ChatModel>) {
		this.chatHttpService.initChatLoginData().subscribe((connConf) => {
			const ws = webSocket<string>({ url: `wss://${window.location.host}/ws`, serializer: (v) => v, deserializer: ({ data }) => data }); //jsonrpc will do serialization/deserialization
			this.disconnectWebSocket = () => ws.complete();
			this.jrpc.toStream = (data) => ws.next(data);

			ws.pipe(
				catchError((err) => {
					if (environment.production) console.error('WS error ', err);
					ctx.dispatch(new DisconnectFromServer({ code: 500, reason: err.message }));
					return EMPTY;
				}),
				filter((data) => data != null)
			).subscribe((data) => {
				this.jrpc.messageHandler(data);
			});

			const user = this.store.selectSnapshot(AuthState.user);
			ctx.dispatch(new ChatLogin({ userId: user.id, companyId: user.companyId, authToken: connConf.token }));
		});
	}

	@Action(DisconnectFromServer)
	disconnect(ctx: StateContext<ChatModel>) {
		if (this.disconnectWebSocket) this.disconnectWebSocket();
		this.disconnectWebSocket = null;
		this.jrpc.toStream = () => {};
		return ctx.patchState({ user: null, messages: [], contacts: [], incomingMessage: null, systemNotifications: [] });
	}
}
