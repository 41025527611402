import { Injectable } from '@angular/core';
import { WindowRef } from './windowRef';
import { ToastrService } from 'ngx-toastr';
import { TempFileModel } from '../../../../pss2-shared/apiModels/tempFileModel';
@Injectable({
	providedIn: 'root',
})
export class TempFileHttpService {
	window;
	baseUrl = './api/restricted/temp-files/';

	constructor(
		winRef: WindowRef,
		private toaster: ToastrService
	) {
		this.window = winRef.getNativeWindow();
	}

	openFile(fileData: TempFileModel, doWait: boolean = false, sa: boolean = false): boolean {
		if (!doWait) doWait = false;
		const fileName = encodeURIComponent(fileData.name);
		const url = sa ? `${this.baseUrl}${fileData.fileId}/${doWait}/${fileName}?sa=true` : `${this.baseUrl}${fileData.fileId}/${doWait}/${fileName}`;
		if (!this.window.open(url, '_blank')) {
			this.toaster.error('Your browser blocks pop-up windows. To view documents you should allow pop-ups on current web-site.');
			return false;
		}
		return true;
	}

	openURLInTab(url: string, title?: string) {
		const openedWindow = this.window.open(url, '_blank');
		if (!openedWindow) this.toaster.error('Your browser blocks pop-up windows. To view documents you should allow pop-ups on current web-site.');
		else {
			if (title) openedWindow.document.title = title;
		}
		return openedWindow;
	}
}
