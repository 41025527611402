import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConvertOfficeToCompanyIntention, ConvertOfficeToCompanyRules } from '../../../../../pss2-shared/apiModels/officeToCompanyModel';
import { COSTS_BLOCKS } from '../../../../../pss2-shared/costsBlocks';
import { BID_TAX_STATUS, BID_TAX_STATUS_GROUPS, CalendarEventTileColorOption } from '../../../../../pss2-shared/sharedConstants';
import { WebFormSettings } from '../../../../../pss2-shared/apiModels/index';
import { LongTasksLock } from '@pss2-shared/longTasks';

export interface CompanySettings {
	initialOpportunityStatusId: number;
	defaultBusinessDivisionId: number;
	defaultCategoryId: number;
	useInvoiceDescriptions: boolean;
	logo: string;
	logoInHeader: boolean;
	hiddenDirectCosts: COSTS_BLOCKS[];
	useProjectManager: boolean;
	eventTitle: string[];
	eventHours: number;
	scheduleHeader: COSTS_BLOCKS[];
	eventTilePrimaryColor: CalendarEventTileColorOption;
	eventTileSecondaryColor: CalendarEventTileColorOption;
	bidTaxStatusGroup: BID_TAX_STATUS_GROUPS;
	defaultBidTaxStatus: BID_TAX_STATUS;
	isWeekStartsOnMonday: boolean;
	eventStartTime: string;
	isPaveAmericaCompany: boolean;
}
@Injectable({
	providedIn: 'root',
})
export class CompanySettingsHttpService {
	constructor(private http: HttpClient) {}

	getCompanyProfile(): Observable<any> {
		return this.http.get('/api/restricted/company/profile');
	}

	getCompanyPaymentProfile(): Observable<any> {
		return this.http.get('/api/restricted/company/payment-profile');
	}

	updateCompanyPaymentProfile(stripeToken: string): Observable<any> {
		return this.http.post('/api/restricted/company/payment-profile', { stripeToken });
	}

	pauseCompany(): Observable<any> {
		return this.http.post('/api/restricted/company/pause-company', {});
	}

	updateCompanyProfile(data) {
		return this.http.put('/api/restricted/company/profile', data);
	}

	getAvailableProposalTemplates() {
		return this.http.get('/api/restricted/company/proposal-templates');
	}

	getUserAppSettings() {
		return this.http.get('/api/restricted/company/user-app-settings');
	}

	patchUserAppSettings(settings: any) {
		return this.http.put('/api/restricted/company/user-app-settings', settings);
	}

	muteNews() {
		return this.http.put('/api/restricted/app-news/news', {});
	}

	getNews() {
		return this.http.get<{ text: string }>('/api/restricted/app-news/news');
	}

	getUrgentNews() {
		return this.http.get<{ activeText: string; date: string }>('/api/restricted/app-news/urgent-news');
	}

	acknowledgeUrgentNews(lastSeenUrgentNewsDate: string) {
		return this.http.put('/api/restricted/app-news/urgent-news', { lastSeenUrgentNewsDate });
	}

	sendCompanySurvey(score: number) {
		return this.http.put<{ surveyDate: string }>('/api/restricted/company/survey', { score });
	}

	getCompanySharedSettings() {
		return this.http.get<CompanySettings>('/api/restricted/company/shared-settings');
	}

	getCompanyLoginBackground() {
		return this.http.get<{ loginBackground: string | null }>('/api/restricted/company/login-background');
	}

	getOfficeToCompanyIntention(officeId: number) {
		return this.http.get<ConvertOfficeToCompanyIntention>(`/api/restricted/company/offices/${officeId}/convert-to-company-intention`);
	}

	convertOfficeToCompany(officeId: number, data: ConvertOfficeToCompanyRules) {
		return this.http.post<any>(`/api/restricted/company/offices/${officeId}/convert-to-company`, data);
	}

	getWebFormSettings(): Observable<WebFormSettings> {
		return this.http.get<WebFormSettings>('/api/restricted/company/web-form-settings');
	}

	updateWebFormSettings(payload: WebFormSettings): Observable<any> {
		return this.http.post('/api/restricted/company/web-form-settings', payload);
	}

	getPartners() {
		return this.http.get<any[]>('/api/restricted/company/partners');
	}

	getCompanyLongTasksLock() {
		return this.http.get<LongTasksLock>('/api/restricted/company/long-tasks-lock');
	}
}
