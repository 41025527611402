<nav style="position: relative; bottom: 0;">
	<ul class="nav">
		<li class="nav-item">
			<a class="nav-link" routerLink="login" routerLinkActive="active">Login</a>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="register" routerLinkActive="active">Register</a>
		</li>
	</ul>
</nav>
<router-outlet></router-outlet>
