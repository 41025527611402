import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadActivePaveAmericaClientRequestsCount } from './pave-america-client-requests.actions';
import { PaveAmericaHttpService } from '../services/pave-america-http.service';

export class PaveAmericaClientRequestsState {
	activeRequestsCount: number;
}

@State<PaveAmericaClientRequestsState>({
	name: 'paveAmericaClientRequests',
	defaults: {
		activeRequestsCount: 0,
	},
})
@Injectable()
export class PaveAmericaClientRequestsStore {
	constructor(private paveAmericaHttpService: PaveAmericaHttpService) {}

	@Selector()
	static activeRequestsCount(state: PaveAmericaClientRequestsState) {
		return state.activeRequestsCount;
	}

	@Action(LoadActivePaveAmericaClientRequestsCount)
	loadRequests(ctx: StateContext<PaveAmericaClientRequestsState>) {
		return this.paveAmericaHttpService.getActivePaveAmericaClientRequestsCount().pipe(
			catchError(() => {
				return of(0);
			}),
			tap((activeRequestsCount) => {
				ctx.patchState({ activeRequestsCount });
			})
		);
	}
}
