import { State, Selector, Action, StateContext } from '@ngxs/store';
import { PageRequestSuccess, PageRequestFailure, PageRequestPending } from './auth.actions';
import { Injectable } from '@angular/core';

export class AuthPageModel {
	error: { error: string };
	pending: boolean;
}

@State<AuthPageModel>({
	name: 'authPage',
	defaults: { error: null, pending: false },
})
@Injectable()
export class AuthPageState {
	@Selector()
	static pending(state: AuthPageModel) {
		return state.pending;
	}
	@Selector()
	static error(state: AuthPageModel) {
		return state.error;
	}

	@Action(PageRequestSuccess)
	pageSuccess(ctx: StateContext<AuthPageModel>) {
		ctx.patchState({ error: null, pending: false });
	}

	@Action(PageRequestPending)
	pagePending(ctx: StateContext<AuthPageModel>) {
		console.log('set to true');
		ctx.patchState({ error: null, pending: true });
	}

	@Action(PageRequestFailure)
	pageFailure(ctx: StateContext<AuthPageModel>, { payload }: PageRequestFailure) {
		ctx.patchState({ error: payload.error, pending: false });
	}
}
