import { ComponentRef } from '@angular/core';

import { Modal } from './modal.model';
import { Subject, Observable } from 'rxjs';
import { ModalContainerComponent } from '../components/modal-container/modal-container.component';

export class ModalRef {
	private result$ = new Subject<any>();

	constructor(
		private modalContainer: ComponentRef<ModalContainerComponent>,
		private modal: ComponentRef<Modal>
	) {
		this.modal.instance.modalInstance = this;
	}

	close(output?: any): void {
		this.result$.next(output);
		this.destroy$();
	}

	dismiss(output?: any): void {
		this.result$.error(output);
		this.destroy$();
	}

	onResult<T = any>(): Observable<T> {
		return this.result$.asObservable();
	}

	private destroy$(): void {
		const domEl: HTMLElement = this.modalContainer.location.nativeElement;
		this.modal.destroy();
		this.modalContainer.destroy();
		this.result$.complete();
		if (domEl.parentNode) domEl.parentNode.removeChild(domEl);
	}
}
