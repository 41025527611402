import { HttpClient } from '@angular/common/http';

export abstract class ResourceHttpService {
	get entityUrl() {
		return `${this.rootUrl}/${this.resourcePath}`;
	}

	constructor(
		public http: HttpClient,
		private resourcePath: string,
		private rootUrl = '/api/restricted'
	) {}

	getOne<T = any>(id: number) {
		return this.http.get<T>(`${this.entityUrl}/${id}`);
	}

	getAll<T = any>(params = {}) {
		return this.http.get<T[]>(`${this.entityUrl}`, { params });
	}

	saveOne<T = any>(data: any) {
		return this.http.post<T>(`${this.entityUrl}`, data);
	}

	resolve() {
		return this;
	}
}
