import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceHttpService } from 'src/app/shared/resource-http.service';
import { CreateOpportunityFromLead, TempFileModel } from '../../../../../pss2-shared/apiModels/index';

@Injectable({
	providedIn: 'root',
})
export class LeadsHttpService extends ResourceHttpService {
	constructor(http: HttpClient) {
		super(http, 'workflow/leads');
	}

	getLeadsCount() {
		return this.http.get<number>(`${this.entityUrl}/count`);
	}

	getLeadsCSV(params) {
		return this.http.get<TempFileModel>(`${this.entityUrl}/csv`, { params });
	}

	createOpportunity(id: number, payload: CreateOpportunityFromLead) {
		return this.http.post(`${this.entityUrl}/${id}/create-opportunity`, payload);
	}

	decline(id: number) {
		return this.http.post(`${this.entityUrl}/${id}/decline`, {});
	}
}
