import { UserDto } from '../../../../../pss2-shared/apiModels/userModel';
import { RegistrationModelRequest } from '../../../../../pss2-shared/apiModels/registrationModel';
import { LoginModelRequest } from '../../../../../pss2-shared/apiModels/loginModel';

export enum AuthActionTypes {
	Login = '[Auth] User Login',
	ForgotPassword = '[Auth] Forgot password',
	ValidateResetToken = '[Auth] Validate Reset Token',
	ChangePassword = '[Auth] Change Password',
	CheckLoginSuccess = '[Auth] Check Login Success',
	UpdateUser = '[Auth] Update User',
	Logout = '[Auth] User Logout',
	LoginSuccess = '[Auth] Login Success',
	LoginRedirect = '[Auth] Redirected to Login',
	Register = '[Auth] Register',
	UpdatePayment = '[Auth] Update Payment',
	RegisterSuccess = '[Auth] Register Success',
	ConfirmRegistration = '[Auth] Confirm Registration',
	GetUserAppSettings = '[Auth] Get User App Settings',
	GotUserAppSettings = '[Auth] Got User App Settings',
	PatchUserAppSettings = '[Auth] Patch User App Settings',
	MuteNews = '[Auth] User Mute News',
	HttpError401 = '[Auth] HttpError401',
	HttpError403 = '[Auth] HttpError403',
	SwitchCompany = '[Auth] Switch Company',
	IntentToSwitchCompany = '[Auth] Intent To Switch Company',
	UpdateSecurityInfo = '[Auth] Update Security Info',
	GetLoginBackground = '[Auth] Get Login Background',
	VerifyOtpCode = '[Auth] Verify Otp Code',
	GetCompanySharedSettings = '[Auth] Get Company Shared Settings',
}

export enum AuthPageActionTypes {
	PageRequestFailure = '[Auth] Page Request Failure',
	PageRequestPending = '[Auth] Page Request Pending',
	PageRequestSuccess = '[Auth] Page Request Success',
}

export class Logout {
	static readonly type = AuthActionTypes.Logout;
}

export class CheckLoginSuccess {
	static readonly type = AuthActionTypes.CheckLoginSuccess;
	constructor(public payload: { user: UserDto }) {}
}

export class UpdateUser {
	static readonly type = AuthActionTypes.UpdateUser;
	constructor(public payload: Partial<UserDto>) {}
}

export class Login {
	static readonly type = AuthActionTypes.Login;
	constructor(
		public payload: LoginModelRequest,
		public returnUrl: string
	) {}
}

export class ForgotPassword {
	static readonly type = AuthActionTypes.ForgotPassword;
	constructor(public payload: { email: string }) {}
}

export class LoginSuccess {
	static readonly type = AuthActionTypes.LoginSuccess;
	constructor(public payload: { user: UserDto; returnUrl?: string; isJustAfterConfirmation?: boolean; tryToSwitchCompany?: boolean }) {}
}

export class ValidateResetToken {
	static readonly type = AuthActionTypes.ValidateResetToken;
	constructor(public payload: { token: string }) {}
}

export class ChangePassword {
	static readonly type = AuthActionTypes.ChangePassword;
	constructor(public payload: { password: string; question: string; answer: string; token: string }) {}
}

export class GetUserAppSettings {
	static readonly type = AuthActionTypes.GetUserAppSettings;
}

export class GotUserAppSettings {
	static readonly type = AuthActionTypes.GotUserAppSettings;
	constructor(public payload: { settings: any }) {}
}

export class PatchUserAppSettings {
	static readonly type = AuthActionTypes.PatchUserAppSettings;
	constructor(public payload: { settings: any }) {}
}

export class LoginRedirect {
	static readonly type = AuthActionTypes.LoginRedirect;
	constructor(public payload?: { returnUrl: string }) {}
}

export class UpdatePayment {
	static readonly type = AuthActionTypes.UpdatePayment;
	constructor(public payload: { email: string; password: string; stripeToken: string; companyId: number }) {}
}

export class Register {
	static readonly type = AuthActionTypes.Register;
	constructor(public payload: RegistrationModelRequest) {}
}

export class RegisterSuccess {
	static readonly type = AuthActionTypes.RegisterSuccess;
}

export class ConfirmRegistration {
	static readonly type = AuthActionTypes.ConfirmRegistration;
	constructor(public payload: { token: string }) {}
}

export class MuteNews {
	static readonly type = AuthActionTypes.MuteNews;
}

export class PageRequestFailure {
	static readonly type = AuthPageActionTypes.PageRequestFailure;
	constructor(public payload: any) {}
}

export class PageRequestPending {
	static readonly type = AuthPageActionTypes.PageRequestPending;
}

export class PageRequestSuccess {
	static readonly type = AuthPageActionTypes.PageRequestSuccess;
}

export class HttpError401 {
	static readonly type = AuthActionTypes.HttpError401;
	constructor(public payload: any) {}
}

export class HttpError403 {
	static readonly type = AuthActionTypes.HttpError403;
	constructor(public payload: any) {}
}

export class SwitchCompany {
	static readonly type = AuthActionTypes.SwitchCompany;
	constructor(public payload: { companyId: number }) {}
}

export class IntentToSwitchCompany {
	static readonly type = AuthActionTypes.IntentToSwitchCompany;
	constructor(public payload: { companyId: number }) {}
}

export class UpdateSecurityInfo {
	static readonly type = AuthActionTypes.UpdateSecurityInfo;
	constructor(
		public payload: {
			security: { newSecurityQuestion: string; newSecurityAnswer: string; newPassword: string; currentPassword: string };
			returnUrl?: string;
		}
	) {}
}

export class GetLoginBackground {
	static readonly type = AuthActionTypes.GetLoginBackground;
	constructor() {}
}

export class VerifyOtpCode {
	static readonly type = AuthActionTypes.VerifyOtpCode;
	constructor(public payload: { data: { token: string; rememberMe: boolean; localTime: string }; returnUrl?: string }) {}
}

export class GetCompanySharedSettings {
	static readonly type = AuthActionTypes.GetCompanySharedSettings;
}
