import { State, Selector, Action, StateContext } from '@ngxs/store';
import {
	AddDocumentsCount,
	AddUploadingFiles,
	FinishedUploadingFiles,
	SetDocumentsCount,
	SetInvoiceFileCount,
	SetLeadsCount,
	SetNotesCount,
	SetSiteReportCount,
} from './shared.actions';
import { Injectable } from '@angular/core';
import { SetDefaultState } from 'src/app/main-app.actions';

export class SharedStateModel {
	documentsCount: number;
	notesCount: number;
	siteReportCount: number;
	uploadingFilesCount: number;
	invoiceFileCount: number;
	leadsCount: number;
}

@State<SharedStateModel>({
	name: 'shared',
	defaults: {
		documentsCount: null,
		notesCount: null,
		siteReportCount: null,
		invoiceFileCount: null,
		uploadingFilesCount: 0,
		leadsCount: null,
	},
})
@Injectable()
export class SharedState {
	@Selector()
	static documentsCount(state: SharedStateModel) {
		return state.documentsCount;
	}

	@Selector()
	static notesCount(state: SharedStateModel) {
		return state.notesCount;
	}

	@Selector()
	static siteReportCount(state: SharedStateModel) {
		return state.siteReportCount;
	}

	@Selector()
	static invoiceFileCount(state: SharedStateModel) {
		return state.invoiceFileCount;
	}

	@Selector()
	static uploadingFilesCount(state: SharedStateModel) {
		return state.uploadingFilesCount;
	}

	@Selector()
	static leadsCount(state: SharedStateModel) {
		return state.leadsCount;
	}

	constructor() {}

	@Action(SetDocumentsCount)
	updateDocumentsCount(ctx: StateContext<SharedStateModel>, { payload }: SetDocumentsCount) {
		ctx.patchState({ documentsCount: payload.documentsCount });
	}

	@Action(AddDocumentsCount)
	addDocumentsCount(ctx: StateContext<SharedStateModel>, { payload }: AddDocumentsCount) {
		const state = ctx.getState();
		ctx.patchState({
			documentsCount: state.documentsCount + payload.documentsCount,
		});
	}

	@Action(SetNotesCount)
	updateNotesCount(ctx: StateContext<SharedStateModel>, { payload }: SetNotesCount) {
		ctx.patchState({ notesCount: payload.notesCount });
	}

	@Action(SetSiteReportCount)
	updateSiteReportCount(ctx: StateContext<SharedStateModel>, { payload }: SetSiteReportCount) {
		ctx.patchState({ siteReportCount: payload.siteReportCount });
	}

	@Action(SetInvoiceFileCount)
	updateInvoiceFileCount(ctx: StateContext<SharedStateModel>, { payload }: SetInvoiceFileCount) {
		ctx.patchState({ invoiceFileCount: payload.invoiceFileCount });
	}

	@Action(AddUploadingFiles)
	addUploadingFilesCount(ctx: StateContext<SharedStateModel>, { payload }: AddUploadingFiles) {
		const state = ctx.getState();
		ctx.patchState({ uploadingFilesCount: state.uploadingFilesCount + payload.count });
	}

	@Action(FinishedUploadingFiles)
	finishedUploadingFilesCount(ctx: StateContext<SharedStateModel>, { payload }: FinishedUploadingFiles) {
		const state = ctx.getState();
		ctx.patchState({ uploadingFilesCount: state.uploadingFilesCount - payload.count });
	}

	@Action(SetLeadsCount)
	updateLeadsCount(ctx: StateContext<SharedStateModel>, { payload }: SetLeadsCount) {
		ctx.patchState({ leadsCount: payload.leadsCount });
	}
	@Action(SetDefaultState)
	setDefault(ctx: StateContext<SharedStateModel>) {
		return ctx.setState({
			documentsCount: null,
			notesCount: null,
			siteReportCount: null,
			invoiceFileCount: null,
			uploadingFilesCount: 0,
			leadsCount: null,
		});
	}
}
