<div class="login-wrapper" [style.background-image]="getBackgroundImage()">
	<form clrForm clrLayout="vertical" class="login" [formGroup]="form">
		<section class="title">
			<h3 class="welcome">Welcome to</h3>
			Pavement Software Solutions
			<h5 class="hint">Register executive of new company</h5>
		</section>
		<div class="login-group">
			<clr-input-container>
				<input type="text" clrInput placeholder="User Name" formControlName="userName" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
			</clr-input-container>
			<clr-input-container>
				<input type="text" clrInput placeholder="Company Name" formControlName="name" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
			</clr-input-container>
			<clr-input-container>
				<input type="text" clrInput placeholder="Phone Number" formControlName="phone" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
			</clr-input-container>
			<clr-input-container>
				<input type="email" clrInput placeholder="Email" formControlName="email" [email]="true" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
				<clr-control-error *clrIfError="'email'">Email is not valid!</clr-control-error>
			</clr-input-container>
			<clr-select-container>
				<select clrSelect formControlName="country">
					<option *ngFor="let country of countries | keyvalue" [value]="country.key">{{country.value}}</option>
				</select>
			</clr-select-container>
			<clr-password-container>
				<input type="password" clrPassword placeholder="Password" formControlName="password" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
				<clr-control-error *clrIfError="'minlength'">It must be at least 9 characters!</clr-control-error>
				<clr-control-error *clrIfError="'upperChar'">It must include at least one uppercase letter!</clr-control-error>
				<clr-control-error *clrIfError="'lowerChar'">It must include at least one lowercase letter!</clr-control-error>
				<clr-control-error *clrIfError="'specialChar'">It must include at least one special character (e.g. ?!&#64;#$%^&*)!</clr-control-error>
			</clr-password-container>
			<clr-control-container>
				<app-password-strength-meter [password]="password" [userInputs]="userInputs"></app-password-strength-meter>
			</clr-control-container>
			<clr-password-container>
				<input type="password" clrPassword placeholder="Confirm Password" formControlName="confirmPassword" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
				<clr-control-error *clrIfError="'minlength'">It must be at least 9 characters!</clr-control-error>
				<clr-control-error *clrIfError="'upperChar'">It must include at least one uppercase letter!</clr-control-error>
				<clr-control-error *clrIfError="'lowerChar'">It must include at least one lowercase letter!</clr-control-error>
				<clr-control-error *clrIfError="'specialChar'">It must include at least one special character (e.g. ?!&#64;#$%^&*)!</clr-control-error>
				<clr-control-error *clrIfError="'passwordsNotSame'">Passwords are not the same!</clr-control-error>
			</clr-password-container>
			<clr-select-container>
				<select clrSelect formControlName="question">
					<option value="" disabled selected hidden>Choose security question</option>
					<option *ngFor="let question of questions" [value]="question">{{question}}</option>
				</select>
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
			</clr-select-container>
			<clr-input-container>
				<input type="text" clrInput placeholder="Type in security question answer" formControlName="answer" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
			</clr-input-container>
			<div class="clr-form-control clr-row">
				<div [class.clr-error]="stripeError" class="clr-control-container">
					<div class="clr-input-wrapper">
						<div id="card-element" class="clr-input"></div>
					</div>
					<span class="clr-subtext">{{stripeError}}</span>
				</div>
			</div>
			<clr-toggle-container>
				<clr-toggle-wrapper>
					<input type="checkbox" clrToggle formControlName="agreeToTerms" />
					<label>I have read and agree to the <a href="https://pavementsoft.com/terms-conditions/" target="_blank">terms and conditions</a>.</label>
				</clr-toggle-wrapper>
				<clr-control-error *clrIfError="'notAgreed'">Must agree to terms and conditions</clr-control-error>
			</clr-toggle-container>
			<div class="error active" *ngIf="error$ | async as error">
				{{ error.error }}
			</div>
			<button class="btn btn-primary" type="submit" (click)="submit()" [clrLoading]="submitBtnState">Register</button>
		</div>
		<img class="ssl-logo" src="/assets/img/logo/ssl.png" />
	</form>
</div>
