<span *ngIf="pending$ | async" class="spinner">Loading...</span>
<div *ngIf="error$ | async as error" class="alert alert-app-level alert-danger" style="margin-bottom:24px" role="alert">
	<div class="alert-items">
		<div class="alert-item static">
			<div class="alert-icon-wrapper">
				<clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
			</div>
			<div class="alert-text">
				{{error.error}}
			</div>
			<div class="alert-actions">
				<button (click)="close()" class="btn alert-action">Close</button>
			</div>
		</div>
	</div>
</div>
