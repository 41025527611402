import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Logout } from '../../store/auth.actions';
import { Store } from '@ngxs/store';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
	constructor(private store: Store) {}

	ngOnInit() {
		this.store.dispatch(new Logout());
	}
}
