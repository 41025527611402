import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PSS_PERMISSION_ID } from '@pss2-shared/permissions';
import {
	CreatePaveAmericaClientRequest,
	DetailedPaveAmericaClientRequest,
	PaveAmericaClient,
	PaveAmericaClientRequest,
	UpdatePaveAmericaClientRequest,
} from '@pss2-shared/apiModels';

@Injectable({
	providedIn: 'root',
})
export class PaveAmericaHttpService {
	private baseUrl = '/api/restricted/pave-america';

	constructor(private http: HttpClient) {}

	getPaveAmericaClients(params: { search?: string; clientId?: number; paveAmericaClientId?: number }) {
		return this.http.get<Record<string, PaveAmericaClient[]>>(`${this.baseUrl}/clients`, { params });
	}

	getActivePaveAmericaClientRequestsCount() {
		return this.http.get<number>(`${this.baseUrl}/active-requests-count`);
	}

	getUsers() {
		return this.http.get<{ id: number; name: string; permissions: PSS_PERMISSION_ID[]; isActive: boolean }[]>(`${this.baseUrl}/users`);
	}

	getPaveAmericaClientRequests(params) {
		return this.http.get<{ results: PaveAmericaClientRequest[]; total: number }>(`${this.baseUrl}/requests`, { params });
	}

	getPaveAmericaClientRequest(id: number) {
		return this.http.get<DetailedPaveAmericaClientRequest>(`${this.baseUrl}/requests/${id}`);
	}

	createPaveAmericaClientRequest(payload: CreatePaveAmericaClientRequest) {
		return this.http.post<any>(`${this.baseUrl}/requests`, payload);
	}

	updatePaveAmericaClientRequest(id: number, payload: UpdatePaveAmericaClientRequest) {
		return this.http.put<any>(`${this.baseUrl}/requests/${id}`, payload);
	}

	approvePaveAmericaClientRequest(id: number, payload: UpdatePaveAmericaClientRequest) {
		return this.http.post<any>(`${this.baseUrl}/requests/${id}/approve`, payload);
	}

	declinePaveAmericaClientRequest(id: number) {
		return this.http.post<any>(`${this.baseUrl}/requests/${id}/decline`, {});
	}

	getProjectDepartments() {
		return this.http.get<{ id: number; name: string; isDefault: boolean }[]>(`${this.baseUrl}/project-departments`);
	}

	getGlCodes() {
		return this.http.get<{ id: number; name: string; isDefault: boolean }[]>(`${this.baseUrl}/gl-codes`);
	}
}
