import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { AdminAuthHttpService } from '../services/admin-auth-http.service';
import { AdminPermissionsService } from '../services/admin-permissions.service';
import { RouteNavigate } from 'src/app/route-handler.service';
import { LoginSuccess, PageRequestFailure, PageRequestSuccess } from 'src/app/auth/store/auth.actions';
import { AdminDto, AdminLoginModelRequest } from '../../../../../pss2-shared/apiModels/admin/index';

export class AdminLogin {
	static readonly type = '[Admin Auth] Login';
	constructor(public payload: AdminLoginModelRequest) {}
}

export class AdminVerifyOtpCode {
	static readonly type = '[Admin Auth] Verify Otp Code';
	constructor(public payload: { token: string }) {}
}

export class AdminLoginAs {
	static readonly type = '[Admin Auth] Login As';
	constructor(public payload: { email: string }) {}
}

export class AdminLogout {
	static readonly type = '[Admin Auth] Logout';
}

export class AdminLoginRedirect {
	static readonly type = '[Admin Auth] Login Redirect';
}

export class AdminLoginSuccess {
	static readonly type = '[Admin Auth] Login Success';
	constructor(public payload: AdminDto) {}
}

export class AdminCheckLogin {
	static readonly type = '[Admin Auth] Check Login';
	constructor() {}
}

export class AdminCheckLoginSuccess {
	static readonly type = '[Admin Auth] Check Login Success';
	constructor(public payload: AdminDto) {}
}

export class AdminLoadPermissions {
	static readonly type = '[Admin Auth] Load Permissions';
	constructor(public payload: AdminDto) {}
}

export class AdminFlushPermissions {
	static readonly type = '[Admin Auth] Flush Permissions';
}

@State<Partial<AdminDto>>({
	name: 'adminAuth',
	defaults: {},
})
@Injectable()
export class AdminAuthState {
	@Selector()
	static id(state: AdminDto) {
		return state.id;
	}

	@Selector()
	static admin(state: AdminDto) {
		return state;
	}

	constructor(
		private authService: AdminAuthHttpService,
		private adminPermissionsService: AdminPermissionsService
	) {}

	@Action(AdminLogin)
	login(ctx: StateContext<AdminDto>, { payload }: AdminLogin) {
		return this.authService.login(payload).pipe(
			tap((result) => {
				ctx.dispatch(new AdminLoginSuccess(result));
			}),
			catchError((error) => ctx.dispatch(new PageRequestFailure(error)))
		);
	}

	@Action(AdminLoginAs)
	loginAs(ctx: StateContext<AdminDto>, { payload }: AdminLoginAs) {
		return this.authService.loginAsUser(payload).pipe(tap((user) => ctx.dispatch(new LoginSuccess({ user }))));
	}

	@Action(AdminLoginSuccess)
	loginSuccess(ctx: StateContext<AdminDto>, { payload }: AdminLoginSuccess) {
		ctx.patchState(payload);
		ctx.dispatch(new AdminLoadPermissions(payload));
		if (payload.isOtpAuthPassed) {
			ctx.dispatch(new RouteNavigate(['/admin']));
		} else {
			ctx.dispatch(new RouteNavigate(['/admin/verify-otp']));
		}
	}

	@Action(AdminCheckLogin)
	checkLogin(ctx: StateContext<AdminDto>) {
		return this.authService.checkLogin().pipe(
			tap((result) => {
				ctx.dispatch(new AdminCheckLoginSuccess(result));
			}),
			catchError(() => ctx.dispatch(new AdminLoginRedirect()))
		);
	}

	@Action(AdminCheckLoginSuccess)
	checkLoginSuccess(ctx: StateContext<AdminDto>, { payload }: AdminCheckLoginSuccess) {
		ctx.patchState(payload);
		ctx.dispatch(new AdminLoadPermissions(payload));
	}

	@Action(AdminLogout)
	logout(ctx: StateContext<Partial<AdminDto>>) {
		return this.authService.logout().pipe(
			tap(() => {
				ctx.setState({});
				ctx.dispatch([new AdminFlushPermissions(), new RouteNavigate(['/'])]);
			})
		);
	}

	@Action(AdminLoginRedirect)
	loginRedirect(ctx: StateContext<AdminDto>) {
		ctx.dispatch(new RouteNavigate(['/admin/login']));
	}

	@Action(AdminVerifyOtpCode)
	verifyOtpCode(ctx: StateContext<AdminDto>, { payload }: AdminVerifyOtpCode) {
		return this.authService.verifyOtpCode(payload).pipe(
			tap((admin) => {
				ctx.setState(admin);
				ctx.dispatch([new RouteNavigate(['/admin']), new PageRequestSuccess()]);
			}),
			catchError((error) => ctx.dispatch([new AdminFlushPermissions(), new PageRequestFailure(error)]))
		);
	}

	@Action(AdminLoadPermissions)
	setPermissions(ctx: StateContext<AdminDto>, { payload }: AdminLoadPermissions) {
		this.adminPermissionsService.loadPermissions(payload);
	}

	@Action(AdminFlushPermissions)
	flushPermissions() {
		this.adminPermissionsService.flushPermissions();
	}
}
