<div class="login-wrapper">
	<form clrForm class="login" [formGroup]="form" clrLayout="vertical">
		<section class="title">
			<h3 class="welcome">Two-Factor Authentication</h3>
			<h5 class="hint">Your account is protected with two-factor authentication. We've sent you an email. Please enter the code below</h5>
		</section>
		<div class="login-group">
			<clr-input-container>
				<label class="clr-required-mark">Two-Factor Code</label>
				<input type="text" clrInput placeholder="Enter the code" formControlName="token" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
			</clr-input-container>
			<clr-checkbox-container>
				<clr-checkbox-wrapper>
					<input type="checkbox" clrCheckbox formControlName="rememberMe" />
					<label>Remember me</label>
				</clr-checkbox-wrapper>
			</clr-checkbox-container>
			<div>
				<button *ngIf="!isCodeSent" class="btn btn-sm btn-link send-code-btn" (click)="resendCode()" [clrLoading]="sendCodeBtnState">
					Resend code via email
				</button>
				<div *ngIf="isCodeSent">Email is sent! Please enter the code above.</div>
			</div>
			<div class="error active" *ngIf="error$ | async as error">
				{{ error.error }}
			</div>
			<button class="btn btn-primary" [clrLoading]="submitBtnState" (click)="submit()">Continue</button>
		</div>
	</form>
</div>
