import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthPageState } from '../../store/auth-page.store';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidationErrors } from '@angular/forms';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { ValidateResetToken, ChangePassword } from '../../store/auth.actions';
import { AuthState } from '../../store/auth.store';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { lowerCharacters, minLength, specialCharacters, upperCharacters } from 'src/app/shared/utils/formUtils';
import { omit } from 'ramda';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	@ViewChild(ClrForm, { static: true })
	clrForm: ClrForm;

	pending$ = null;
	error$ = null;

	submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	subject$ = new Subject();

	form: UntypedFormGroup = new UntypedFormGroup({
		password: new UntypedFormControl('', [
			Validators.required,
			minLength(9),
			specialCharacters,
			upperCharacters,
			lowerCharacters,
			this.passwordsUpdaterValidator,
		]),
		confirmPassword: new UntypedFormControl('', [
			Validators.required,
			minLength(9),
			specialCharacters,
			upperCharacters,
			lowerCharacters,
			this.passwordsTheSameValidator,
		]),
		question: new UntypedFormControl('', Validators.required),
		answer: new UntypedFormControl('', Validators.required),
		token: new UntypedFormControl('', Validators.required),
	});

	get password() {
		return this.form.value.password;
	}

	passwordsTheSameValidator(confirmPass: UntypedFormControl): ValidationErrors | null {
		if (!confirmPass || !confirmPass.parent) {
			return null;
		}
		const pass = confirmPass.parent.get('password');
		if (!pass) return null;
		return pass && confirmPass && pass.value === confirmPass.value ? null : { passwordsNotSame: true };
	}

	passwordsUpdaterValidator(pass: UntypedFormControl): ValidationErrors | null {
		if (!pass || !pass.parent) {
			return null;
		}
		const confirmPass = pass.parent.get('confirmPassword');
		if (!confirmPass) return null;
		confirmPass.updateValueAndValidity();
		return null;
	}

	constructor(
		private store: Store,
		private toaster: ToastrService,
		private route: ActivatedRoute,
		private cd: ChangeDetectorRef
	) {
		this.error$ = this.store.select(AuthPageState.error);
	}

	ngOnInit() {
		this.store.dispatch(new ValidateResetToken({ token: this.route.snapshot.queryParams.token }));

		this.store.select(AuthState.question).subscribe((question) => {
			this.form.setValue({
				password: '',
				confirmPassword: '',
				question: question || '',
				answer: '',
				token: this.route.snapshot.queryParams.token,
			});
			this.cd.markForCheck();
		});
		this.error$.pipe(takeUntil(this.subject$)).subscribe(() => {
			this.submitBtnState = ClrLoadingState.DEFAULT;
			this.cd.markForCheck();
		});
		this.pending$ = this.store
			.select(AuthPageState.pending)
			.pipe(tap((isPending) => (isPending ? this.form.disable() : this.form.enable())))
			.subscribe();
	}

	ngOnDestroy() {
		if (this.pending$) this.pending$.unsubscribe();
		this.subject$.complete();
	}

	async submit() {
		if (!this.form.valid) {
			this.clrForm.markAsTouched();
			this.toaster.error('Please fill all required fields!');
			return;
		}
		this.submitBtnState = ClrLoadingState.LOADING;
		this.cd.markForCheck();
		const newPasswordData: any = omit(['confirmPassword'], this.form.value);
		this.store.dispatch(new ChangePassword(newPasswordData));
	}
}
