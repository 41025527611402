<div class="modal">
	<div class="modal-dialog modal-xl" role="dialog" aria-hidden="true">
		<div class="modal-content">
			<div class="modal-header">
				<button (click)="cancel()" aria-label="Close" class="close" type="button">
					<clr-icon aria-hidden="true" shape="close"></clr-icon>
				</button>
				<h3 class="modal-title">{{'Active Site Reports' | translate}}</h3>
			</div>
			<div class="modal-body">
				<app-spinner *ngIf="isLoading" [size]="1"></app-spinner>

				<clr-datagrid *ngIf="!isLoading" class="grid">
					<clr-dg-column>Form</clr-dg-column>
					<clr-dg-column [clrDgSortOrder]="-1" clrDgSortBy="dueDate">Due Date</clr-dg-column>
					<clr-dg-column>Assigned Users</clr-dg-column>
					<clr-dg-column>Pre Work</clr-dg-column>
					<clr-dg-column>Job #</clr-dg-column>
					<clr-dg-column style="width: 120px;"></clr-dg-column>

					<clr-dg-row *ngFor="let siteReport of siteReports" [ngClass]="{'highlighted': highlightIds.includes(siteReport.id)}">
						<clr-dg-cell>
							<a [routerLink]="['/tools/site-reports/edit', siteReport.id]" (click)="cancel()">{{siteReport.companySiteReportFormTitle}}</a>
						</clr-dg-cell>
						<clr-dg-cell [ngClass]="{'outdated': isOutDated(siteReport.dueDate)}">{{siteReport.dueDate |
                            timezone | date}}</clr-dg-cell>
						<clr-dg-cell>{{getUserNames(siteReport.assignedUsers)}}</clr-dg-cell>
						<clr-dg-cell>{{siteReport.isPreWork ? 'Yes' : 'No'}}</clr-dg-cell>
						<clr-dg-cell>{{siteReport.jobNumber}}</clr-dg-cell>
						<clr-dg-cell>
							<button
								class="btn btn-sm btn-primary complete-button"
								(click)="complete(siteReport)"
								[clrLoading]="completeBtnState"
								[disabled]="!canBeCompleted(siteReport)">
								Complete
							</button>
						</clr-dg-cell>
					</clr-dg-row>
				</clr-datagrid>
			</div>
		</div>
	</div>
</div>
