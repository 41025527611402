import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LoadActiveSiteReports, SiteReportsSetIsLoading } from './site-report.actions';
import { SiteReportsHttpService } from '../site-reports-http.service';
import { catchError, filter, tap } from 'rxjs';
import { SiteReport } from '../../../../../pss2-shared/siteReport';
import { SetDefaultState } from 'src/app/main-app.actions';

export class SiteReportModel {
	siteReports: SiteReport[];
	isLoading: boolean;
}

@State<SiteReportModel>({
	name: 'siteReport',
	defaults: {
		siteReports: [],
		isLoading: false,
	},
})
@Injectable()
export class SiteReportStore {
	constructor(private siteReportsHttpService: SiteReportsHttpService) {}

	@Selector()
	static siteReports(state: SiteReportModel) {
		return state.siteReports;
	}

	@Selector()
	static isSiteReportsLoading(state: SiteReportModel) {
		return state.isLoading;
	}

	@Action(LoadActiveSiteReports)
	loadActieSiteReports(ctx: StateContext<SiteReportModel>) {
		return this.siteReportsHttpService
			.userSiteReports()
			.pipe(
				filter(() => !ctx.getState().isLoading),
				tap(() => ctx.dispatch(new SiteReportsSetIsLoading({ isLoading: true }))),
				catchError(() => {
					ctx.dispatch(new SiteReportsSetIsLoading({ isLoading: false }));
					return [];
				})
			)
			.subscribe((siteReports) => {
				ctx.patchState({ siteReports: siteReports });
				ctx.dispatch(new SiteReportsSetIsLoading({ isLoading: false }));
			});
	}

	@Action(SiteReportsSetIsLoading)
	siteReportSetIsLoading(ctx: StateContext<SiteReportModel>, { payload }: SiteReportsSetIsLoading) {
		ctx.patchState({ isLoading: payload.isLoading });
	}

	@Action(SetDefaultState)
	setDefault(ctx: StateContext<SiteReportModel>) {
		return ctx.setState({
			siteReports: [],
			isLoading: false,
		});
	}
}
