<div class="login-wrapper">
	<form clrForm class="login" [formGroup]="form" (ngSubmit)="submit()">
		<section class="title">
			<h3 class="welcome">Reset password to</h3>
			Pavement Software Solutions
			<h5 class="hint">Please, fill fields below</h5>
		</section>
		<div class="login-group">
			<clr-input-container>
				<input type="text" readonly clrInput placeholder="Your security question" formControlName="question" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
			</clr-input-container>
			<clr-input-container>
				<input type="text" clrInput placeholder="Type in security question answer" formControlName="answer" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
			</clr-input-container>
			<clr-password-container>
				<input type="password" clrPassword placeholder="New Password" formControlName="password" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
				<clr-control-error *clrIfError="'minlength'">It must be at least 9 characters!</clr-control-error>
				<clr-control-error *clrIfError="'upperChar'">It must include at least one uppercase letter!</clr-control-error>
				<clr-control-error *clrIfError="'lowerChar'">It must include at least one lowercase letter!</clr-control-error>
				<clr-control-error *clrIfError="'specialChar'">It must include at least one special character (e.g. ?!&#64;#$%^&*)!</clr-control-error>
			</clr-password-container>
			<clr-control-container>
				<app-password-strength-meter [password]="password"></app-password-strength-meter>
			</clr-control-container>
			<clr-password-container>
				<input type="password" clrPassword placeholder="Confirm New Password" formControlName="confirmPassword" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
				<clr-control-error *clrIfError="'minlength'">It must be at least 9 characters!</clr-control-error>
				<clr-control-error *clrIfError="'upperChar'">It must include at least one uppercase letter!</clr-control-error>
				<clr-control-error *clrIfError="'lowerChar'">It must include at least one lowercase letter!</clr-control-error>
				<clr-control-error *clrIfError="'specialChar'">It must include at least one special character (e.g. ?!&#64;#$%^&*)!</clr-control-error>
				<clr-control-error *clrIfError="'passwordsNotSame'">Passwords are not the same!</clr-control-error>
			</clr-password-container>
			<div class="error active" *ngIf="error$ | async as error">
				{{ error.error }}
			</div>
			<button class="btn btn-primary" type="submit" [disabled]="!form.valid" [clrLoading]="submitBtnState">Change Password</button>
		</div>
	</form>
</div>
