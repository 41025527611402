import { Component, Input, AfterViewInit, ViewChild, AfterContentChecked } from '@angular/core';
import { ClrDatagridFilterInterface, ClrDatagridFilter } from '@clr/angular';
import { Subject, Observable } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';

interface SelectOption {
	id: number;
	name: string;
	isActive?: boolean;
}

@Component({
	selector: 'app-clr-dg-multiselect-filter',
	templateUrl: './clr-dg-multiselect-filter.component.html',
	styleUrls: ['./clr-dg-multiselect-filter.component.scss'],
})
export class ClrDgMultiSelectFilterComponent<T = any> implements ClrDatagridFilterInterface<T, any>, AfterViewInit, AfterContentChecked {
	@Input('options')
	set options(newOptions: any[]) {
		this.pOptions = newOptions;
	}
	get options() {
		return this.pOptions;
	}

	@Input()
	property: string;

	private pOptions: SelectOption[] = [];

	@Input()
	flatList = false;

	_selected = [];
	@Input('selected')
	set selected(selected: any[]) {
		if (!selected) return;
		this._selected = selected;
		this.apply();
	}

	@Input()
	groupBy = null;
	@Input()
	selectableGroup = false;
	@Input()
	selectableGroupAsModel = true;

	@ViewChild('multiselect', { static: true })
	multiselect: NgSelectComponent;
	/**
	 * The Observable required as part of the Filter interface
	 */
	private pChanges = new Subject<any[]>();

	// We do not want to expose the Subject itself, but the Observable which is read-only
	public get changes(): Observable<any[]> {
		return this.pChanges.asObservable();
	}

	public get state() {
		return {
			property: this.property,
			value: this._selected,
		};
	}

	constructor(filterContainer: ClrDatagridFilter) {
		filterContainer.setFilter(this);
	}

	ngAfterViewInit() {
		this.setOptions(this.pOptions);
	}

	ngAfterContentChecked(): void {
		if (!this.multiselect.focused) this.multiselect.focus(); //doesn't work for some reason
	}

	setOptions(newOptions: any[]) {
		if (!newOptions) return;
		if (this.flatList) {
			this.pOptions = newOptions.map((i) => ({ id: i, name: i }));
		} else {
			this.pOptions = newOptions;
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	accepts(item: T): boolean {
		return true;
	}

	public isActive(): boolean {
		return this._selected && this._selected.length > 0;
	}

	public equals(other: any): boolean {
		return other.prop === this.property && other.selected === this._selected;
	}

	apply() {
		this.pChanges.next(this._selected);
	}

	clear() {
		this._selected = [];
		this.apply();
	}
}
