import { Injectable } from '@angular/core';
import { ResourceHttpService } from 'src/app/shared/resource-http.service';
import { HttpClient } from '@angular/common/http';
import { Address } from '../../../../../pss2-shared/address';
import { BID_STATUSES } from '@pss2-shared/index';

@Injectable({
	providedIn: 'root',
})
export class OfficesHttpService extends ResourceHttpService {
	constructor(http: HttpClient) {
		super(http, 'company/offices');
	}

	getIncreaseAllBidItemsPricesData(officeId: number) {
		return this.http.get<any>(`${this.entityUrl}/${officeId}/increase-all-bid-items-prices-data`);
	} 

	getOfficeTextTemplate(textPropName: string, officeId: number) {
		return this.http.get<any>(`/api/restricted/company/office-text-template/${officeId}/${textPropName}`);
	}

	getAddresses(officeId: number, query?: { search: string }) {
		return this.http.get<Address[]>(`/api/restricted/company/office-addresses/${officeId}`, { params: query });
	}

	getOfficeSequences(officeId: number) {
		return this.http.get<any>(`${this.entityUrl}/sequences/${officeId}`);
	}

	updateOfficeSequences(officeId: number, data: any) {
		return this.http.put(`${this.entityUrl}/sequences/${officeId}`, data);
	}

	increaseBidItemsPrices(officeId: number, discount: number, startDate: Date, endDate: Date, statuses: BID_STATUSES[]) {
		return this.http.put(`${this.entityUrl}/${officeId}/increase-bid-items-prices`, { discount, startDate, endDate, statuses });
	}

	canBeRemoved(officeId: number) {
		return this.http.get<{ canBeRemoved: boolean }>(`${this.entityUrl}/can-be-removed/${officeId}`);
	}

	removeOne(officeId: number) {
		return this.http.delete(`${this.entityUrl}/${officeId}`);
	}

	getJobInvoiceTemplate(jobId: number) {
		return this.http.get<{ body: string; subject: string; signature: string }>(`${this.entityUrl}/job-invoice-email-template/${jobId}`);
	}

	getBidOfficeTextTemplate(bidId: number, textPropName: string) {
		return this.http.get<any>(`${this.entityUrl}/${bidId}/${textPropName}`);
	}

	getBidLostEmailTemplate(bidId: number) {
		return this.http.get<{ body: string; subject: string; signature: string }>(`${this.entityUrl}/bid-lost-email-template/${bidId}`);
	}

	getOfficeSiteReportSettings(officeId: number, params: any = {}) {
		return this.http.get<{ total: number; results: [] }>(`${this.entityUrl}/${officeId}/office-site-report-settings`, { params });
	}

	getOfficeSiteReportSetting(officeId: number, id: number) {
		return this.http.get<any>(`${this.entityUrl}/${officeId}/office-site-report-settings/${id}`);
	}

	saveOfficeSiteReportSettings(officeId: number, body: any) {
		return this.http.post(`${this.entityUrl}/${officeId}/office-site-report-settings`, body);
	}

	deleteOfficeSiteReportSettings(officeId: number, id: number) {
		return this.http.delete(`${this.entityUrl}/${officeId}/office-site-report-settings/${id}`);
	}
}
