<div cdkDrag class="modal-dialog" role="dialog" aria-hidden="true">
	<div class="modal-content">
		<div class="modal-dlg-header" cdkDragHandle>
			<clr-icon class="drag-handle-icon btn btn-link" shape="drag-handle"></clr-icon>
			<h3 class="modal-title">Urgent News</h3>
		</div>
		<div class="modal-body">
			<span *ngIf="loading" class="spinner spinner-md"> Loading... </span>
			<quill-view *ngIf="!loading" [content]="news" format="json" theme="snow"></quill-view>
		</div>
		<div class="modal-footer">
			<button (click)="acknowledge()" class="btn btn-primary" type="button" [disabled]="loading">Acknowledge</button>
		</div>
	</div>
</div>
