import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Modal } from '../../modal-service-models/modal.model';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-confirm-dlg',
	templateUrl: './confirm-dlg.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslatePipe],
})
export class ConfirmDlgComponent extends Modal {
	inputs = {
		title: 'Confirmation',
		message: '',
		okBtn: 'Yes',
		cancelBtn: 'No',
		isDanger: false,
		iconShape: 'warning-standard',
		widthOverride: null,
	};

	constructor(private translateService: TranslateService) {
		super();
	}

	onInjectInputs(inputs): void {
		this.inputs = Object.assign({}, this.inputs, inputs);
	}

	ok(): void {
		this.close(true);
	}

	cancel(): void {
		this.dismiss(false);
	}
}
