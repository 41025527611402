import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { HttpError401 } from '../auth/store/auth.actions';

const whiteList = ['/api/restricted/checkLogin', '/api/admin/check-login'];

@Injectable({
	providedIn: 'root',
})
export class Http401Interceptor implements HttpInterceptor {
	toastrThrottle = false;

	constructor(private store: Store) {}

	intercept(request: HttpRequest<any>, next: HttpHandler) {
		return next.handle(request).pipe(
			catchError((err) => {
				if (err.status === 401 && !whiteList.includes(request.url)) this.showToast(err);
				return throwError(err);
			})
		);
	}

	showToast(err: any) {
		// only emit once per second or less
		if (this.toastrThrottle) {
			console.log('Ignored http error 401', err);
			return;
		}
		this.toastrThrottle = true;
		setTimeout(() => (this.toastrThrottle = false), 999);
		this.store.dispatch(new HttpError401(err));
	}
}
