export enum AuthActionTypes {
	LoadActiveSiteReports = '[Site Report] Load Active Site Reports',
	SiteReportsSetIsLoading = '[Site Report] Set Is Loading',
}

export class LoadActiveSiteReports {
	static readonly type = AuthActionTypes.LoadActiveSiteReports;
}

export class SiteReportsSetIsLoading {
	static readonly type = AuthActionTypes.SiteReportsSetIsLoading;
	constructor(public payload: { isLoading: boolean }) {}
}
