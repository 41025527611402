export enum PSS_PERMISSION_ID {
	review_web_form_leads = 'review_web_form_leads',
	partnership_access = 'partnership_access',
	opportunity_access_ui = 'opportunity_access_ui',
	opportunity_access_any = 'opportunity_access_any',
	opportunity_access_office = 'opportunity_access_office',
	opportunity_bid_assistant = 'opportunity_bid_assistant',
	bid_access_ui = 'bid_access_ui',
	bid_view_costs = 'bid_view_costs',
	bid_job_view_margin = 'bid_job_view_margin',
	bid_access_any = 'bid_access_any',
	bid_access_office = 'bid_access_office',
	bid_approve = 'bid_approve',
	bid_edit_gl_code = 'bid_edit_gl_code',
	sales_lead = 'sales_lead',
	bid_receive_won_email = 'bid_receive_won_email',
	bid_read_changelog = 'bid_read_changelog',
	job_access_ui = 'job_access_ui',
	track_time_access = 'track_time_access',
	track_time_only_self = 'track_time_only_self',
	job_read_any = 'job_read_any',
	job_read_office = 'job_read_office',
	job_write_readable = 'job_write_readable',
	job_edit_daily = 'job_edit_daily',
	job_verify_daily = 'job_verify_daily',
	job_unverify_any_daily = 'job_unverify_any_daily',
	job_activate_delete = 'job_activate_delete',
	job_read_scheduled_for_user_crews = 'job_read_scheduled_for_user_crews',
	job_read_money_info = 'job_read_money_info',
	job_edit_employee_time_entries = 'job_edit_employee_time_entries',
	job_unverify_any_employee_time_entries = 'job_unverify_any_employee_time_entries',
	job_access_accountant = 'job_access_accountant',
	job_receive_status_email = 'job_receive_status_email',
	job_receive_employee_injury_report_email = 'job_receive_employee_injury_report_email',
	job_read_change_log = 'job_read_change_log',
	schedule_access_ui = 'schedule_access_ui',
	schedule_read_any_event = 'schedule_read_any_event',
	schedule_read_office_events = 'schedule_read_office_events',
	schedule_update_own_events = 'schedule_update_own_events',
	schedule_create_job_event = 'schedule_create_job_event',
	schedule_update_any_readable = 'schedule_update_any_readable',
	schedule_remove_any_readable = 'schedule_remove_any_readable',
	schedule_update_event_toggles = 'schedule_update_event_toggles',
	todo_access_ui = 'todo_access_ui',
	todo_access_any = 'todo_access_any',
	dashboard_access_ui = 'dashboard_access_ui',
	dashboard_access_salesperson = 'dashboard_access_salesperson',
	dashboard_access_foreman = 'dashboard_access_foreman',
	dashboard_access_executive = 'dashboard_access_executive',
	crm_access_ui = 'crm_access_ui',
	crm_edit_entities = 'crm_edit_entities',
	crm_merge_entities = 'crm_merge_entities',
	crm_mass_email = 'crm_mass_email',
	review_pave_america_client_requests = 'review_pave_america_client_requests',
	measure_tool_access_ui = 'measure_tool_access_ui',
	measure_tool_access_any_project = 'measure_tool_access_any_project',
	executive_reports_access_ui = 'executive_reports_access_ui',
	company_files_access_ui = 'company_files_access_ui',
	import_export_access_ui = 'import_export_access_ui',
	qb_desktop_access = 'qb_desktop_access',
	docusign_access = 'docusign_access',
	hubspot_access = 'hubspot_access',
	qb_online_access = 'qb_online_access',
	company_profile_access_ui = 'company_profile_access_ui',
	office_profile_access_ui = 'office_profile_access_ui',
	edit_employee_data = 'edit_employee_data',
	settings_access_ui = 'settings_access_ui',
	direct_costs_ui = 'direct_costs_ui',
}

export const PSS_PERMISSIONS_DEFINITIONS: { [key in keyof typeof PSS_PERMISSION_ID]: any } = {
	review_web_form_leads: 'Review Web Form Leads',
	partnership_access: 'Partnership Module',
	opportunity_access_ui: 'Opportunities Module',
	opportunity_access_any: 'View and Edit all Opportunities',
	opportunity_access_office: 'View and Edit Opportunities in assigned Office',
	opportunity_bid_assistant: 'Opportunity/Bid Assistant',
	bid_access_ui: 'Bids Module',
	bid_view_costs: 'View Costs in Bids',
	bid_job_view_margin: 'View Margin in Bids/Jobs',
	bid_access_any: 'View and Edit all Bids',
	bid_access_office: 'View and Edit Bids in assigned Office',
	bid_approve: 'Approve Bids',
	bid_edit_gl_code: 'Edit GL Code',
	sales_lead: 'Sales Lead',
	bid_receive_won_email: 'Receive Job Won Emails',
	bid_read_changelog: 'View Bid History',
	job_access_ui: 'Job Module',
	track_time_access: 'Track Time Module',
	track_time_only_self: 'Track Time Only Self',
	job_read_any: 'View any Job',
	job_read_office: 'View Jobs in assigned Office',
	job_write_readable: 'Edit Job Details',
	job_edit_daily: 'Edit Job Dailies',
	job_verify_daily: 'Verify Job Dailies',
	job_unverify_any_daily: 'Unverify any Job Daily',
	job_activate_delete: 'Activate/Delete Viewable Jobs',
	job_read_scheduled_for_user_crews: 'View Jobs Assigned to Crew',
	job_read_money_info: 'View Job Financial Information',
	job_edit_employee_time_entries: 'Edit Employee Time Entries',
	job_unverify_any_employee_time_entries: 'Unverify any Employee Time Entry',
	job_access_accountant: 'Access Accountant Job Areas',
	job_receive_status_email: 'Receive Job Status Emails',
	job_receive_employee_injury_report_email: 'Receive Job Employee Injury Report Emails',
	job_read_change_log: 'View Job History',
	schedule_access_ui: 'Schedule Module',
	schedule_read_any_event: 'View all Schedule Items',
	schedule_read_office_events: 'View Office Schedule Items',
	schedule_update_own_events: 'Edit Owned Schedule Items',
	schedule_create_job_event: 'Create Schedule Items',
	schedule_update_event_toggles: 'Update Scheduled Item Status',
	schedule_update_any_readable: 'Update any Scheduled Items',
	schedule_remove_any_readable: 'Remove any Scheduled Items',
	todo_access_ui: 'To-Do Module',
	todo_access_any: 'View all To-Do items',
	dashboard_access_ui: 'Dashboard Module',
	dashboard_access_salesperson: 'View Salesperson Dashboard',
	dashboard_access_foreman: 'View Foreman Dashboard',
	dashboard_access_executive: 'Executive Dashboard',
	crm_access_ui: 'CRM Module',
	crm_edit_entities: 'Edit CRM Data',
	crm_merge_entities: 'Merge CRM Data',
	crm_mass_email: 'Mass Email',
	review_pave_america_client_requests: 'Review Pave America Client Requests',
	measure_tool_access_ui: 'Measure Tool Module',
	measure_tool_access_any_project: 'View all Measure Projects',
	executive_reports_access_ui: 'Access Executive Reports',
	company_files_access_ui: 'Access Company Files',
	import_export_access_ui: 'Import/Export Access',
	qb_desktop_access: 'QB Desktop Access',
	docusign_access: 'Docusign Access',
	hubspot_access: 'Hubspot Access',
	qb_online_access: 'QB Online Access',
	edit_employee_data: 'Employee data / Labor Rates',
	company_profile_access_ui: 'View and Edit Company Info',
	office_profile_access_ui: 'View and Edit Office Info',
	settings_access_ui: 'View and Edit System Settings',
	direct_costs_ui: 'View and Edit Direct Costs',
};

export const DEFAULT_GROUPS = [
	{
		role: 'executive',
		permissions: [
			PSS_PERMISSION_ID.review_web_form_leads,
			PSS_PERMISSION_ID.opportunity_access_ui,
			PSS_PERMISSION_ID.opportunity_access_any,
			PSS_PERMISSION_ID.bid_access_ui,
			PSS_PERMISSION_ID.bid_view_costs,
			PSS_PERMISSION_ID.bid_job_view_margin,
			PSS_PERMISSION_ID.bid_access_any,
			PSS_PERMISSION_ID.bid_approve,
			PSS_PERMISSION_ID.bid_receive_won_email,
			PSS_PERMISSION_ID.bid_read_changelog,
			PSS_PERMISSION_ID.job_access_ui,
			PSS_PERMISSION_ID.track_time_access,
			PSS_PERMISSION_ID.job_read_any,
			PSS_PERMISSION_ID.job_write_readable,
			PSS_PERMISSION_ID.job_edit_daily,
			PSS_PERMISSION_ID.job_verify_daily,
			PSS_PERMISSION_ID.job_read_money_info,
			PSS_PERMISSION_ID.job_edit_employee_time_entries,
			PSS_PERMISSION_ID.job_access_accountant,
			PSS_PERMISSION_ID.job_receive_status_email,
			PSS_PERMISSION_ID.job_receive_employee_injury_report_email,
			PSS_PERMISSION_ID.schedule_access_ui,
			PSS_PERMISSION_ID.schedule_read_any_event,
			PSS_PERMISSION_ID.schedule_create_job_event,
			PSS_PERMISSION_ID.schedule_update_any_readable,
			PSS_PERMISSION_ID.schedule_remove_any_readable,
			PSS_PERMISSION_ID.todo_access_ui,
			PSS_PERMISSION_ID.todo_access_any,
			PSS_PERMISSION_ID.dashboard_access_ui,
			PSS_PERMISSION_ID.dashboard_access_executive,
			PSS_PERMISSION_ID.crm_access_ui,
			PSS_PERMISSION_ID.crm_edit_entities,
			PSS_PERMISSION_ID.crm_mass_email,
			PSS_PERMISSION_ID.crm_merge_entities,
			PSS_PERMISSION_ID.measure_tool_access_ui,
			PSS_PERMISSION_ID.measure_tool_access_any_project,
			PSS_PERMISSION_ID.executive_reports_access_ui,
			PSS_PERMISSION_ID.company_files_access_ui,
			PSS_PERMISSION_ID.import_export_access_ui,
			PSS_PERMISSION_ID.qb_desktop_access,
			PSS_PERMISSION_ID.docusign_access,
			PSS_PERMISSION_ID.hubspot_access,
			PSS_PERMISSION_ID.qb_online_access,
			PSS_PERMISSION_ID.edit_employee_data,
			PSS_PERMISSION_ID.company_profile_access_ui,
			PSS_PERMISSION_ID.office_profile_access_ui,
			PSS_PERMISSION_ID.settings_access_ui,
			PSS_PERMISSION_ID.job_read_change_log,
			PSS_PERMISSION_ID.job_activate_delete,
			PSS_PERMISSION_ID.direct_costs_ui,
			PSS_PERMISSION_ID.partnership_access,
		],
	},
	{
		role: 'administrative',
		permissions: [
			PSS_PERMISSION_ID.review_web_form_leads,
			PSS_PERMISSION_ID.opportunity_access_ui,
			PSS_PERMISSION_ID.opportunity_access_office,
			PSS_PERMISSION_ID.bid_access_ui,
			PSS_PERMISSION_ID.bid_view_costs,
			PSS_PERMISSION_ID.bid_job_view_margin,
			PSS_PERMISSION_ID.bid_access_office,
			PSS_PERMISSION_ID.bid_receive_won_email,
			PSS_PERMISSION_ID.job_access_ui,
			PSS_PERMISSION_ID.track_time_access,
			PSS_PERMISSION_ID.job_read_office,
			PSS_PERMISSION_ID.job_write_readable,
			PSS_PERMISSION_ID.job_edit_daily,
			PSS_PERMISSION_ID.job_verify_daily,
			PSS_PERMISSION_ID.job_read_money_info,
			PSS_PERMISSION_ID.job_edit_employee_time_entries,
			PSS_PERMISSION_ID.job_access_accountant,
			PSS_PERMISSION_ID.job_receive_status_email,
			PSS_PERMISSION_ID.schedule_access_ui,
			PSS_PERMISSION_ID.schedule_read_office_events,
			PSS_PERMISSION_ID.schedule_create_job_event,
			PSS_PERMISSION_ID.schedule_update_any_readable,
			PSS_PERMISSION_ID.todo_access_ui,
			PSS_PERMISSION_ID.todo_access_any,
			PSS_PERMISSION_ID.dashboard_access_ui,
			PSS_PERMISSION_ID.dashboard_access_executive,
			PSS_PERMISSION_ID.crm_access_ui,
			PSS_PERMISSION_ID.crm_merge_entities,
			PSS_PERMISSION_ID.crm_edit_entities,
			PSS_PERMISSION_ID.measure_tool_access_ui,
			PSS_PERMISSION_ID.measure_tool_access_any_project,
			PSS_PERMISSION_ID.executive_reports_access_ui,
			PSS_PERMISSION_ID.company_files_access_ui,
			PSS_PERMISSION_ID.import_export_access_ui,
			PSS_PERMISSION_ID.qb_desktop_access,
			PSS_PERMISSION_ID.docusign_access,
			PSS_PERMISSION_ID.hubspot_access,
			PSS_PERMISSION_ID.qb_online_access,
			PSS_PERMISSION_ID.company_profile_access_ui,
			PSS_PERMISSION_ID.office_profile_access_ui,
			PSS_PERMISSION_ID.settings_access_ui,
			PSS_PERMISSION_ID.direct_costs_ui,
		],
	},
	{
		role: 'sales lead',
		permissions: [
			PSS_PERMISSION_ID.opportunity_access_ui,
			PSS_PERMISSION_ID.bid_access_ui,
			PSS_PERMISSION_ID.bid_receive_won_email,
			PSS_PERMISSION_ID.bid_view_costs,
			PSS_PERMISSION_ID.bid_job_view_margin,
			PSS_PERMISSION_ID.bid_approve,
			PSS_PERMISSION_ID.sales_lead,
			PSS_PERMISSION_ID.job_access_ui,
			PSS_PERMISSION_ID.track_time_access,
			PSS_PERMISSION_ID.job_write_readable,
			PSS_PERMISSION_ID.job_read_money_info,
			PSS_PERMISSION_ID.job_receive_status_email,
			PSS_PERMISSION_ID.schedule_access_ui,
			PSS_PERMISSION_ID.schedule_read_office_events,
			PSS_PERMISSION_ID.schedule_update_any_readable,
			PSS_PERMISSION_ID.schedule_create_job_event,
			PSS_PERMISSION_ID.todo_access_ui,
			PSS_PERMISSION_ID.todo_access_any,
			PSS_PERMISSION_ID.dashboard_access_ui,
			PSS_PERMISSION_ID.dashboard_access_salesperson,
			PSS_PERMISSION_ID.crm_access_ui,
			PSS_PERMISSION_ID.crm_edit_entities,
			PSS_PERMISSION_ID.measure_tool_access_ui,
			PSS_PERMISSION_ID.docusign_access,
			PSS_PERMISSION_ID.partnership_access,
		],
	},
	{
		role: 'salesperson',
		permissions: [
			PSS_PERMISSION_ID.opportunity_access_ui,
			PSS_PERMISSION_ID.bid_access_ui,
			PSS_PERMISSION_ID.bid_view_costs,
			PSS_PERMISSION_ID.bid_job_view_margin,
			PSS_PERMISSION_ID.job_access_ui,
			PSS_PERMISSION_ID.track_time_access,
			PSS_PERMISSION_ID.job_write_readable,
			PSS_PERMISSION_ID.job_read_money_info,
			PSS_PERMISSION_ID.schedule_access_ui,
			PSS_PERMISSION_ID.schedule_read_office_events,
			PSS_PERMISSION_ID.schedule_update_own_events,
			PSS_PERMISSION_ID.schedule_create_job_event,
			PSS_PERMISSION_ID.todo_access_ui,
			PSS_PERMISSION_ID.dashboard_access_ui,
			PSS_PERMISSION_ID.dashboard_access_salesperson,
			PSS_PERMISSION_ID.crm_access_ui,
			PSS_PERMISSION_ID.crm_edit_entities,
			PSS_PERMISSION_ID.measure_tool_access_ui,
			PSS_PERMISSION_ID.docusign_access,
			PSS_PERMISSION_ID.partnership_access,
		],
	},
	{
		role: 'salesperson no scheduling',
		permissions: [
			PSS_PERMISSION_ID.opportunity_access_ui,
			PSS_PERMISSION_ID.bid_access_ui,
			PSS_PERMISSION_ID.bid_view_costs,
			PSS_PERMISSION_ID.bid_job_view_margin,
			PSS_PERMISSION_ID.job_access_ui,
			PSS_PERMISSION_ID.track_time_access,
			PSS_PERMISSION_ID.job_read_office,
			PSS_PERMISSION_ID.job_write_readable,
			PSS_PERMISSION_ID.job_read_money_info,
			PSS_PERMISSION_ID.schedule_access_ui,
			PSS_PERMISSION_ID.schedule_read_office_events,
			PSS_PERMISSION_ID.todo_access_ui,
			PSS_PERMISSION_ID.dashboard_access_ui,
			PSS_PERMISSION_ID.dashboard_access_salesperson,
			PSS_PERMISSION_ID.crm_access_ui,
			PSS_PERMISSION_ID.crm_edit_entities,
			PSS_PERMISSION_ID.measure_tool_access_ui,
			PSS_PERMISSION_ID.docusign_access,
			PSS_PERMISSION_ID.partnership_access,
		],
	},
	{
		role: 'sales assistant',
		permissions: [
			PSS_PERMISSION_ID.opportunity_access_ui,
			PSS_PERMISSION_ID.opportunity_bid_assistant,
			PSS_PERMISSION_ID.bid_access_ui,
			PSS_PERMISSION_ID.job_access_ui,
			PSS_PERMISSION_ID.track_time_access,
			PSS_PERMISSION_ID.job_write_readable,
			PSS_PERMISSION_ID.schedule_access_ui,
			PSS_PERMISSION_ID.schedule_read_office_events,
			PSS_PERMISSION_ID.schedule_update_own_events,
			PSS_PERMISSION_ID.todo_access_ui,
			PSS_PERMISSION_ID.dashboard_access_ui,
			PSS_PERMISSION_ID.dashboard_access_salesperson,
			PSS_PERMISSION_ID.crm_access_ui,
			PSS_PERMISSION_ID.crm_edit_entities,
			PSS_PERMISSION_ID.measure_tool_access_ui,
		],
	},
	{
		role: 'production super manager',
		permissions: [
			PSS_PERMISSION_ID.bid_access_ui,
			PSS_PERMISSION_ID.bid_view_costs,
			PSS_PERMISSION_ID.bid_job_view_margin,
			PSS_PERMISSION_ID.bid_access_office,
			PSS_PERMISSION_ID.job_access_ui,
			PSS_PERMISSION_ID.job_read_office,
			PSS_PERMISSION_ID.job_write_readable,
			PSS_PERMISSION_ID.job_edit_daily,
			PSS_PERMISSION_ID.job_read_money_info,
			PSS_PERMISSION_ID.track_time_access,
			PSS_PERMISSION_ID.job_edit_employee_time_entries,
			PSS_PERMISSION_ID.edit_employee_data,
			PSS_PERMISSION_ID.schedule_access_ui,
			PSS_PERMISSION_ID.schedule_read_office_events,
			PSS_PERMISSION_ID.schedule_create_job_event,
			PSS_PERMISSION_ID.schedule_update_any_readable,
			PSS_PERMISSION_ID.schedule_remove_any_readable,
			PSS_PERMISSION_ID.todo_access_ui,
			PSS_PERMISSION_ID.dashboard_access_ui,
			PSS_PERMISSION_ID.dashboard_access_executive,
			PSS_PERMISSION_ID.crm_access_ui,
			PSS_PERMISSION_ID.measure_tool_access_ui,
			PSS_PERMISSION_ID.measure_tool_access_any_project,
			PSS_PERMISSION_ID.executive_reports_access_ui,
			PSS_PERMISSION_ID.settings_access_ui,
			PSS_PERMISSION_ID.direct_costs_ui,
		],
	},
	{
		role: 'foreman',
		permissions: [
			PSS_PERMISSION_ID.job_access_ui,
			PSS_PERMISSION_ID.job_read_scheduled_for_user_crews,
			PSS_PERMISSION_ID.job_edit_daily,
			PSS_PERMISSION_ID.track_time_access,
			PSS_PERMISSION_ID.schedule_access_ui,
			PSS_PERMISSION_ID.schedule_read_office_events,
			PSS_PERMISSION_ID.schedule_update_event_toggles,
			PSS_PERMISSION_ID.todo_access_ui,
			PSS_PERMISSION_ID.dashboard_access_ui,
			PSS_PERMISSION_ID.dashboard_access_foreman,
		],
	},
	{
		role: 'foreman no scheduling',
		permissions: [
			PSS_PERMISSION_ID.job_access_ui,
			PSS_PERMISSION_ID.job_edit_daily,
			PSS_PERMISSION_ID.job_read_office,
			PSS_PERMISSION_ID.track_time_access,
			PSS_PERMISSION_ID.schedule_access_ui,
			PSS_PERMISSION_ID.schedule_read_office_events,
			PSS_PERMISSION_ID.todo_access_ui,
			PSS_PERMISSION_ID.dashboard_access_ui,
			PSS_PERMISSION_ID.dashboard_access_foreman,
		],
	},
	{
		role: 'accountant',
		permissions: [
			PSS_PERMISSION_ID.bid_access_ui,
			PSS_PERMISSION_ID.bid_access_any,
			PSS_PERMISSION_ID.bid_view_costs,
			PSS_PERMISSION_ID.bid_job_view_margin,
			PSS_PERMISSION_ID.job_access_ui,
			PSS_PERMISSION_ID.job_read_any,
			PSS_PERMISSION_ID.job_read_money_info,
			PSS_PERMISSION_ID.job_access_accountant,
			PSS_PERMISSION_ID.job_receive_status_email,
			PSS_PERMISSION_ID.job_edit_daily,
			PSS_PERMISSION_ID.job_verify_daily,
			PSS_PERMISSION_ID.schedule_access_ui,
			PSS_PERMISSION_ID.schedule_read_any_event,
			PSS_PERMISSION_ID.todo_access_ui,
			PSS_PERMISSION_ID.qb_online_access,
			PSS_PERMISSION_ID.qb_desktop_access,
		],
	},
	{
		role: 'pm',
		permissions: [
			PSS_PERMISSION_ID.bid_job_view_margin,
			PSS_PERMISSION_ID.job_access_ui,
			PSS_PERMISSION_ID.job_write_readable,
			PSS_PERMISSION_ID.track_time_access,
			PSS_PERMISSION_ID.job_read_office,
			PSS_PERMISSION_ID.job_read_money_info,
			PSS_PERMISSION_ID.schedule_access_ui,
			PSS_PERMISSION_ID.schedule_read_office_events,
			PSS_PERMISSION_ID.schedule_update_own_events,
			PSS_PERMISSION_ID.schedule_update_event_toggles,
			PSS_PERMISSION_ID.todo_access_ui,
		],
	},
	{
		role: 'pm no scheduling',
		permissions: [
			PSS_PERMISSION_ID.bid_job_view_margin,
			PSS_PERMISSION_ID.job_access_ui,
			PSS_PERMISSION_ID.job_write_readable,
			PSS_PERMISSION_ID.track_time_access,
			PSS_PERMISSION_ID.job_read_office,
			PSS_PERMISSION_ID.job_read_money_info,
			PSS_PERMISSION_ID.schedule_access_ui,
			PSS_PERMISSION_ID.schedule_read_office_events,
			PSS_PERMISSION_ID.schedule_update_event_toggles,
			PSS_PERMISSION_ID.todo_access_ui,
		],
	},
	{
		role: 'employee',
		permissions: [PSS_PERMISSION_ID.track_time_access, PSS_PERMISSION_ID.track_time_only_self, PSS_PERMISSION_ID.job_read_office],
	},
];

export enum PSS_RATES_ID {
	pss_auto_executive_user_access = 'pss_auto_executive_user_access',
	pss_auto_foreman_user_access = 'pss_auto_foreman_user_access',
	pss_auto_employee_user_access = 'pss_auto_employee_user_access',
}

export const PSS_RATES_DEFINITIONS: { [key in keyof typeof PSS_RATES_ID]: string } = {
	pss_auto_executive_user_access: 'Executive ($99/month)',
	pss_auto_foreman_user_access: 'Foreman ($49/month)',
	pss_auto_employee_user_access: 'Employee (free)',
};

export const PSS_RATES_PERMISSIONS: { [key in keyof typeof PSS_RATES_ID]: PSS_PERMISSION_ID[] } = {
	pss_auto_executive_user_access: [
		PSS_PERMISSION_ID.review_web_form_leads,
		PSS_PERMISSION_ID.partnership_access,
		PSS_PERMISSION_ID.opportunity_access_ui,
		PSS_PERMISSION_ID.opportunity_access_any,
		PSS_PERMISSION_ID.opportunity_access_office,
		PSS_PERMISSION_ID.bid_access_ui,
		PSS_PERMISSION_ID.opportunity_bid_assistant,
		PSS_PERMISSION_ID.bid_view_costs,
		PSS_PERMISSION_ID.bid_access_any,
		PSS_PERMISSION_ID.bid_access_office,
		PSS_PERMISSION_ID.bid_approve,
		PSS_PERMISSION_ID.sales_lead,
		PSS_PERMISSION_ID.bid_receive_won_email,
		PSS_PERMISSION_ID.bid_read_changelog,
		PSS_PERMISSION_ID.job_read_any,
		PSS_PERMISSION_ID.job_edit_employee_time_entries,
		PSS_PERMISSION_ID.job_write_readable,
		PSS_PERMISSION_ID.job_access_accountant,
		PSS_PERMISSION_ID.job_receive_status_email,
		PSS_PERMISSION_ID.job_receive_employee_injury_report_email,
		PSS_PERMISSION_ID.schedule_read_any_event,
		PSS_PERMISSION_ID.schedule_create_job_event,
		PSS_PERMISSION_ID.schedule_update_any_readable,
		PSS_PERMISSION_ID.schedule_remove_any_readable,
		PSS_PERMISSION_ID.todo_access_any,
		PSS_PERMISSION_ID.dashboard_access_salesperson,
		PSS_PERMISSION_ID.dashboard_access_executive,
		PSS_PERMISSION_ID.crm_access_ui,
		PSS_PERMISSION_ID.crm_edit_entities,
		PSS_PERMISSION_ID.crm_merge_entities,
		PSS_PERMISSION_ID.crm_mass_email,
		PSS_PERMISSION_ID.measure_tool_access_ui,
		PSS_PERMISSION_ID.measure_tool_access_any_project,
		PSS_PERMISSION_ID.executive_reports_access_ui,
		PSS_PERMISSION_ID.company_files_access_ui,
		PSS_PERMISSION_ID.import_export_access_ui,
		PSS_PERMISSION_ID.qb_desktop_access,
		PSS_PERMISSION_ID.docusign_access,
		PSS_PERMISSION_ID.hubspot_access,
		PSS_PERMISSION_ID.qb_online_access,
		PSS_PERMISSION_ID.company_profile_access_ui,
		PSS_PERMISSION_ID.office_profile_access_ui,
		PSS_PERMISSION_ID.settings_access_ui,
		PSS_PERMISSION_ID.direct_costs_ui,
		PSS_PERMISSION_ID.job_read_change_log,
		PSS_PERMISSION_ID.job_activate_delete,
		PSS_PERMISSION_ID.edit_employee_data,
	],
	pss_auto_foreman_user_access: [
		PSS_PERMISSION_ID.dashboard_access_ui,
		PSS_PERMISSION_ID.schedule_update_event_toggles,
		PSS_PERMISSION_ID.schedule_access_ui,
		PSS_PERMISSION_ID.schedule_read_office_events,
		PSS_PERMISSION_ID.schedule_update_own_events,
		PSS_PERMISSION_ID.job_read_money_info,
		PSS_PERMISSION_ID.job_access_ui,
		PSS_PERMISSION_ID.job_read_scheduled_for_user_crews,
		PSS_PERMISSION_ID.bid_job_view_margin,
		PSS_PERMISSION_ID.todo_access_ui,
		PSS_PERMISSION_ID.dashboard_access_foreman,
	],
	pss_auto_employee_user_access: [PSS_PERMISSION_ID.track_time_access, PSS_PERMISSION_ID.job_read_office, PSS_PERMISSION_ID.track_time_only_self],
};

export const getUserRate = (permissions: PSS_PERMISSION_ID[]): PSS_RATES_ID => {
	if (permissions.some((p) => PSS_RATES_PERMISSIONS[PSS_RATES_ID.pss_auto_executive_user_access].includes(p))) {
		return PSS_RATES_ID.pss_auto_executive_user_access;
	}
	if (permissions.some((p) => PSS_RATES_PERMISSIONS[PSS_RATES_ID.pss_auto_foreman_user_access].includes(p))) {
		return PSS_RATES_ID.pss_auto_foreman_user_access;
	}
	return PSS_RATES_ID.pss_auto_employee_user_access;
};

export const getUserRateName = (permissions: PSS_PERMISSION_ID[]): string => {
	return PSS_RATES_DEFINITIONS[getUserRate(permissions)];
};
