import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Modal } from 'src/app/shared/modal-service-models/modal.model';
import { LoadActiveSiteReports } from '../../store/site-report.actions';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import { SiteReportStore } from '../../store/site-report.store';
import { UsersHttpService } from 'src/app/company-settings/services/users-http.service';
import { ClrLoadingState } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { SiteReportsHttpService } from '../../site-reports-http.service';
import { ModalService } from 'src/app/shared/modal-service.service';
import { CompleteSiteReportDlgComponent } from '../complete-site-report-dlg/complete-site-report-dlg.component';
import { startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { AuthState } from 'src/app/auth/store/auth.store';

@Component({
	selector: 'app-user-active-site-reports-dlg',
	templateUrl: './user-active-site-reports-dlg.component.html',
	styleUrls: ['./user-active-site-reports-dlg.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserActiveSiteReportsDlgComponent extends Modal implements OnInit, OnDestroy {
	completeBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	isLoading = true;

	subject$ = new Subject<void>();

	highlightIds: number[] = [];

	employeeIds: number[] = [];

	siteReports = [];

	users = [];

	user;

	constructor(
		private store: Store,
		private cd: ChangeDetectorRef,
		private usersHttpService: UsersHttpService,
		private toaster: ToastrService,
		private siteReportHttpService: SiteReportsHttpService,
		private modalService: ModalService
	) {
		super();
		this.user = this.store.selectSnapshot(AuthState.user);
	}

	ngOnInit() {
		this.store.dispatch(new LoadActiveSiteReports());

		this.store
			.select(SiteReportStore.isSiteReportsLoading)
			.pipe(takeUntil(this.subject$))
			.subscribe((isLoading) => {
				this.isLoading = isLoading;
				this.cd.markForCheck();
			});

		this.store
			.select(SiteReportStore.siteReports)
			.pipe(takeUntil(this.subject$))
			.subscribe((siteReports) => {
				this.siteReports = siteReports;
				this.assertsHighlighted();
				this.cd.markForCheck();
			});

		this.usersHttpService.getAll({ showInactive: true }).subscribe((users) => {
			this.users = users;
			this.cd.markForCheck();
		});
	}

	ngOnDestroy(): void {
		this.subject$.next();
		this.subject$.complete();
	}

	onInjectInputs(inputs) {
		this.highlightIds = inputs?.highlightIds ?? [];
		this.employeeIds = inputs?.employeeIds ?? [];
	}

	assertsHighlighted() {
		if (!this.highlightIds.length) return;
		if (this.siteReports.some((s) => this.highlightIds.includes(s.id))) return;
		this.close();
	}

	getUserNames(userIds: number[]) {
		return this.users
			.filter((u) => userIds.includes(u.id))
			.map((u) => u.name)
			.join(', ');
	}

	cancel() {
		this.close(null);
	}

	canBeCompleted(siteReport) {
		return siteReport.isPreWork ? new Date(siteReport.dueDate).getTime() < new Date().getTime() : true;
	}

	async complete(siteReport) {
		if (!this.canBeCompleted(siteReport)) {
			this.toaster.error('This report cannot be completed before due date!');
			return;
		}

		this.completeBtnState = ClrLoadingState.LOADING;
		this.cd.markForCheck();

		try {
			const completedForm = await lastValueFrom(
				this.modalService
					.open(
						CompleteSiteReportDlgComponent,
						{
							id: siteReport.id,
							employeeIds: this.employeeIds,
						},
						false
					)
					.onResult()
			);

			if (completedForm == null) {
				this.completeBtnState = ClrLoadingState.DEFAULT;
				this.cd.markForCheck();
				return;
			}
			console.log(completedForm)
			if (siteReport.startedBy == null || !completedForm.justStart) {
				await lastValueFrom(this.siteReportHttpService.complete(completedForm.formData, completedForm.justStart));
			} else {
				await lastValueFrom(this.siteReportHttpService.saveOne(completedForm.formData));
			}
		} catch (e) {
			this.toaster.error(e?.error?.error);
		}
		this.store.dispatch(new LoadActiveSiteReports());
		this.completeBtnState = ClrLoadingState.DEFAULT;
		this.cd.markForCheck();
	}

	isOutDated(date: string) {
		const dateTime = new Date(date).getTime();
		const todayTime = zonedTimeToUtc(startOfDay(new Date()), this.user.timezone).getTime();
		return todayTime > dateTime;
	}
}
