<div cdkDrag class="modal-dialog" role="dialog" aria-hidden="true">
	<div class="modal-content">
		<!-- <div class="modal-dlg-header" cdkDragHandle>
            <clr-icon class="drag-handle-icon btn btn-link" shape="drag-handle"></clr-icon>
            <h3 class="modal-title">Quick Add Property</h3>
            <button (click)="cancel()" aria-label="Close" class="close-modal-btn btn btn-link" type="button">
                <clr-icon aria-hidden="true" shape="close"></clr-icon>
            </button>
        </div> -->
		<div class="modal-body">
			<h3 style="margin: 1rem 0;">How likely are you to recommend PavementSoft to a friend or colleage?</h3>
			<div class="text-center">
				<button class="btn btn-icon btn-primary" *ngFor="let item of [].constructor(10); let i = index" (click)="numClick(i+1)">{{i+1}}</button>
			</div>
		</div>
	</div>
</div>
