<div class="modal">
	<div class="modal-dialog modal-xl" role="dialog" aria-hidden="true">
		<div class="modal-content">
			<div class="modal-header">
				<button (click)="cancel()" aria-label="Close" class="close" type="button">
					<clr-icon aria-hidden="true" shape="close"></clr-icon>
				</button>
				<h3 class="modal-title">Pave America Client Requests</h3>
			</div>
			<div class="modal-body">
				<app-pave-america-client-requests [clientId]="clientId" [selectedStatuses]="selectedStatuses"></app-pave-america-client-requests>
			</div>
		</div>
	</div>
</div>
