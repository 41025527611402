import { Directive, ElementRef, Input } from '@angular/core';
import { Subject, fromEvent, takeUntil } from 'rxjs';

@Directive({
	selector: '[appClarityDgFilter]',
})
export class ClarityDgFilterDirective {
	@Input() clrDgFilter;

	subject = new Subject<void>();

	constructor(private element: ElementRef) {}

	ngOnInit() {
		const clearButton = this.createFilterButton();
		const clearButtonEvent = fromEvent(clearButton, 'click');
		clearButtonEvent.pipe(takeUntil(this.subject)).subscribe(() => {
			this.clrDgFilter?.clear();
		});
		this.element.nativeElement.appendChild(clearButton);
	}

	ngOnDestroy() {
		this.subject.next();
		this.subject.unsubscribe();
	}

	createFilterButton() {
		const button = document.createElement('button');
		button.classList.add('datagrid-filter-toggle', 'clear-filter-button');
		const clrIcon = document.createElement('cds-icon');
		clrIcon.setAttribute('shape', 'filter-off');
		clrIcon.setAttribute('size', '10');
		clrIcon.setAttribute('solid', '');
		button.appendChild(clrIcon);
		return button;
	}
}
