import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, Type, ComponentRef, Input } from '@angular/core';
import { Modal } from '../../modal-service-models/modal.model';

@Component({
	templateUrl: './modal-container.component.html',
})
export class ModalContainerComponent {
	@ViewChild('modalContainer', { read: ViewContainerRef }) private modalContainer: ViewContainerRef;

	@Input()
	hasBackdrop = true;

	constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

	createModal<T extends Modal>(component: Type<T>): ComponentRef<T> {
		this.modalContainer.clear();

		const factory: ComponentFactory<T> = this.componentFactoryResolver.resolveComponentFactory(component);

		return this.modalContainer.createComponent(factory, 0);
	}
}
