import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { AuthModule } from './auth/auth.module';
import { httpInterceptorProviders } from './http-interceptors/index';
import { MainPageComponent } from './components/main-page/main-page.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HttpClientJsonpModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { RouteHandlerService } from './route-handler.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SetupWizardComponent } from './components/setup-wizard/setup-wizard.component';
import { UserNewsComponent } from './components/user-news/user-news.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SmartSearchComponent } from './components/smart-search/smart-search.component';
import { SurveyComponent } from './components/survey/survey.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { MainAppState } from './main-app.state';
import { CustomMissingTranslationHandler } from './shared/utils/translateUtils';
import { SwitchCompanyComponent } from './switch-company/switch-company.component';
import { UserUrgentNewsComponent } from './components/user-urgent-news/user-urgent-news.component';
import { ChatModule } from './chat/chat.module';
import { SiteReportStore } from './tools/store/site-report.store';
import { PaveAmericaClientRequestsStore } from './crm/store/pave-america-client-requests.store';

// Noop handler for factory function
export function noop() {
	return () => {};
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		NotFoundPageComponent,
		MainPageComponent,
		UserUrgentNewsComponent,
		SetupWizardComponent,
		UserNewsComponent,
		SmartSearchComponent,
		SurveyComponent,
		SwitchCompanyComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
			useDefaultLang: true,
			missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
		}),
		SharedModule.forRoot(),
		HttpClientModule,
		HttpClientJsonpModule,
		NgxsModule.forRoot([MainAppState, SiteReportStore, PaveAmericaClientRequestsStore], {
			developmentMode: !environment.production,
			compatibility: { strictContentSecurityPolicy: true },
		}),
		NgxPermissionsModule.forRoot(),
		DragDropModule,
		AuthModule,
		AppRoutingModule,
		NgxGoogleAnalyticsModule.forRoot(environment.ga),
		NgxGoogleAnalyticsRouterModule,
		ChatModule,
	],
	providers: [
		httpInterceptorProviders,
		{
			provide: APP_INITIALIZER,
			useFactory: noop,
			deps: [RouteHandlerService],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
