import { WeatherForecast } from './weatherApi';

export enum SITE_REPORT_FORM_QUESTION_TYPE {
	specify = 'specify',
	dropdown = 'dropdown',
	multiple_dropdown = 'multiple_dropdown',
}

export const SiteReportFormQuestionTypeToLabelMap: {
	[key in SITE_REPORT_FORM_QUESTION_TYPE]: string;
} = {
	[SITE_REPORT_FORM_QUESTION_TYPE.specify]: 'Specify',
	[SITE_REPORT_FORM_QUESTION_TYPE.dropdown]: 'Dropdown',
	[SITE_REPORT_FORM_QUESTION_TYPE.multiple_dropdown]: 'Multiple Dropdown',
};

export enum SITE_REPORT_PERIODICITY_TYPE {
	daily = 'daily',
	weekly = 'weekly',
	monthly = 'monthly',
	quarterly = 'quarterly',
	annually = 'annually',
}

export const SiteReportPeriodicityTypeToLabelMap: {
	[key in SITE_REPORT_PERIODICITY_TYPE]: string;
} = {
	[SITE_REPORT_PERIODICITY_TYPE.daily]: 'Daily',
	[SITE_REPORT_PERIODICITY_TYPE.weekly]: 'Weekly',
	[SITE_REPORT_PERIODICITY_TYPE.monthly]: 'Monthly',
	[SITE_REPORT_PERIODICITY_TYPE.quarterly]: 'Quarterly',
	[SITE_REPORT_PERIODICITY_TYPE.annually]: 'Annually',
};

export enum SITE_REPORT_EMPLOYEE_ROSTER_TYPE {
	no = 'no',
	yes = 'yes',
	yes_with_signature = 'yes_with_signature',
}

export const SiteReportEmployeeRosterTypeToLabelMap: {
	[key in SITE_REPORT_EMPLOYEE_ROSTER_TYPE]: string;
} = {
	[SITE_REPORT_EMPLOYEE_ROSTER_TYPE.no]: 'No',
	[SITE_REPORT_EMPLOYEE_ROSTER_TYPE.yes]: 'Yes',
	[SITE_REPORT_EMPLOYEE_ROSTER_TYPE.yes_with_signature]: 'Yes, with signature',
};

export interface SiteReportUploadSignature {
	base64?: string;
	fileName?: string;
	fileType?: string;

	url?: string;
}

export interface UserSiteReportTask {
	id: number;
	officeSiteReportFormSettingId?: number;
	userId?: number;
	periodicity?: SITE_REPORT_PERIODICITY_TYPE;

	officeName?: string;
	isPreWork?: boolean;
	employeeRosterType: SITE_REPORT_EMPLOYEE_ROSTER_TYPE;
	isSignatureRequired?: boolean;
	atLeastOneImageRequired: boolean;
	companySiteReportFormTitle?: string;
}

export interface SiteReportRosterEmployee {
	id?: number;
	siteReportId?: number;
	employeeId: number;
	signatureId?: number;
	signature?: SiteReportUploadSignature;
}

export interface SiteReport {
	id?: number;
	companyId?: number;
	companySiteReportFormId: number;
	companySiteReportFormTitle?: string;
	companySiteReportFormReference?: string;
	jobId: number;
	dueDate: any;
	isPreWork: boolean;
	employeeRosterType: SITE_REPORT_EMPLOYEE_ROSTER_TYPE;
	isSignatureRequired: boolean;
	atLeastOneImageRequired: boolean;
	reference: string;
	createdAt: any;
	createdBy: number;
	updatedAt: any;
	updatedBy: number;
	completedAt: any;
	completedBy: number;
	startedAt: any;
	startedBy: number;
	signatureId: number;
	eventId: number;
	crewId: number;
	userSiteReportTaskId: number;
	comment: string;
	weatherForecast: WeatherForecast;

	jobNumber?: string;

	assignedUsers?: number[];
	employeeRoster?: SiteReportRosterEmployee[];

	signature?: SiteReportUploadSignature;

	sections?: Partial<SiteReportSection>[];

	files?: { id: number; name: string; fileName: string; mine: string; createdAt: any }[];
}

export interface SiteReportSection {
	id?: number;
	siteReportId?: number;
	title: string;
	questions?: Partial<SiteReportQuestion>[];
}

export interface SiteReportQuestion {
	id?: number;
	siteReportSectionId?: string;
	question: string;
	type: string;
	options: string;
	isRequired: boolean;
	answer: string[];
	details: string;
}

export const PRE_WORK_SITE_REPORT_DUE_DATE_ERROR = 'Please fill pre work site reports for this date!';

export enum SITE_REPORT_CHANGE_EVENTS {
	site_report_due_date_change = 'site_report_due_date_change',
	site_report_company_site_form_change = 'site_report_company_site_form_change',
	site_report_is_pre_work_change = 'site_report_is_pre_work_change',
	site_report_employee_roster_type_change = 'site_report_employee_roster_type_change',
	site_report_is_signature_required_change = 'site_report_is_signature_required_change',
	site_report_at_least_one_image_required_change = 'site_report_at_least_one_image_required_change',
	site_report_jobId_change = 'site_report_jobId_change',
	site_report_form_questions_change = 'site_report_form_questions_change',
	site_report_assigned_users_change = 'site_report_assigned_users_change',
	site_report_employee_roster_change = 'site_report_assigned_employees_change',
	site_report_comment_change = 'site_report_comment_change',
}

export enum SITE_REPORT_ATTACHMENTS_CHANGE_EVENTS {
	site_report_attachment_created_change = 'site_report_attachment_created_change',
	site_report_attachment_removed_change = 'site_report_attachment_removed_change',
}

interface SiteReportCT {
	[n: string]: any;
}

export interface SiteReportGeneralCTExpandableRow<T> extends SiteReportCT {
	expandableRow?: T;
}

export interface SiteReportDueDateCT extends SiteReportCT {
	dueDate: string;
	dueDateFrom: string;
}

export interface SiteReportCompanySiteFormCT extends SiteReportCT {
	companySiteReportFormId: number;
	companySiteReportFormIdFrom: number;

	companySiteReportFormName?: string;
	companySiteReportFormFromName?: string;
}

export interface SiteReportIsPreWorkCT extends SiteReportCT {
	isPreWork: boolean;
	isPreWorkFrom: boolean;
}

export interface SiteReportEmployeeRosterCT extends SiteReportCT {
	employeeRosterType: SITE_REPORT_EMPLOYEE_ROSTER_TYPE;
	employeeRosterTypeFrom: SITE_REPORT_EMPLOYEE_ROSTER_TYPE;
}

export interface SiteReportIsSignatureRequiredCT extends SiteReportCT {
	isSignatureRequired: boolean;
	isSignatureRequiredFrom: boolean;
}

export interface SiteReportAtLeastOneImageRequiredCT extends SiteReportCT {
	atLeastOneImageRequired: boolean;
	atLeastOneImageRequiredFrom: boolean;
}

export interface SiteReportReferenceCT extends SiteReportCT {
	reference: string;
	referenceFrom: string;
}

export interface SiteReportJobIdCT extends SiteReportCT {
	jobId: number;
	jobIdFrom: number;

	jobNumber?: string;
	jobNumberFrom?: string;
}

export interface SiteReportAssignedUsersCT extends SiteReportCT {
	assignedUsers: number[];
	assignedUsersFrom: number[];
}

export interface SiteReportEmployeesRosterCT extends SiteReportCT {
	employeeRoster: number[];
	employeeRosterFrom: number[];
}

export interface SiteReportCommentCT extends SiteReportCT {
	comment: string;
	commentFrom: string;
}

export interface SiteReportFormQuestionsDataChangeCT {
	sections: {
		siteReportSectionId: number;
		siteReportSectionTitle?: string;
		questions: {
			siteReportSectionQuestionId: number;
			siteReportSectionQuestion?: string;
			answer: string[];
			answerFrom: string[];
			details: string;
			detailsFrom: string;
		}[];
	}[];
}

export interface SiteReportFormQuestionsChangeCT extends SiteReportGeneralCTExpandableRow<SiteReportFormQuestionsDataChangeCT> {}

export interface SiteReportAttachmentCreatedCT extends SiteReportCT {
	fileId: number;
	name: string;
}

export type SiteReportAttachmentRemovedCT = SiteReportAttachmentCreatedCT;

export type SiteReportLogChangedTo =
	| SiteReportDueDateCT
	| SiteReportCompanySiteFormCT
	| SiteReportIsPreWorkCT
	| SiteReportEmployeeRosterCT
	| SiteReportIsSignatureRequiredCT
	| SiteReportAtLeastOneImageRequiredCT
	| SiteReportJobIdCT
	| SiteReportJobIdCT
	| SiteReportAssignedUsersCT
	| SiteReportEmployeesRosterCT
	| SiteReportFormQuestionsChangeCT
	| SiteReportAttachmentCreatedCT
	| SiteReportAttachmentRemovedCT
	| SiteReportCommentCT;

export type SIRE_REPORT_CHANGE_EVENT = SITE_REPORT_CHANGE_EVENTS | SITE_REPORT_ATTACHMENTS_CHANGE_EVENTS;

export interface SiteReportChangeLogRecord<T extends SiteReportLogChangedTo = SiteReportLogChangedTo> {
	siteReportId: number;
	userId?: number;
	event: SIRE_REPORT_CHANGE_EVENT;
	changedTo?: T;
}

export function getSiteReportChangelogEventName(event: SIRE_REPORT_CHANGE_EVENT): string {
	switch (event) {
		case SITE_REPORT_CHANGE_EVENTS.site_report_due_date_change:
			return 'Site Report Due Date change';
		case SITE_REPORT_CHANGE_EVENTS.site_report_company_site_form_change:
			return 'Site Report Site Form change';
		case SITE_REPORT_CHANGE_EVENTS.site_report_is_pre_work_change:
			return 'Site Report Pre Work change';
		case SITE_REPORT_CHANGE_EVENTS.site_report_employee_roster_type_change:
			return 'Site Report Employee Roster Type change';
		case SITE_REPORT_CHANGE_EVENTS.site_report_is_signature_required_change:
			return 'Site Report Signature change';
		case SITE_REPORT_CHANGE_EVENTS.site_report_at_least_one_image_required_change:
			return 'Site Report At Least One Image Required change';
		case SITE_REPORT_CHANGE_EVENTS.site_report_jobId_change:
			return 'Site Report Job change';
		case SITE_REPORT_CHANGE_EVENTS.site_report_form_questions_change:
			return 'Site Report Form change';
		case SITE_REPORT_CHANGE_EVENTS.site_report_assigned_users_change:
			return 'Site Report Assigned Users change';
		case SITE_REPORT_CHANGE_EVENTS.site_report_employee_roster_change:
			return 'Site Report Employee Roster change';
		case SITE_REPORT_CHANGE_EVENTS.site_report_comment_change:
			return 'Site Report Comment change';
		case SITE_REPORT_ATTACHMENTS_CHANGE_EVENTS.site_report_attachment_created_change:
			return 'Site Report Attachment created change';
		case SITE_REPORT_ATTACHMENTS_CHANGE_EVENTS.site_report_attachment_removed_change:
			return 'Site Report Attachment removed change';
		default:
			return 'Unknown';
	}
}
