import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ShiftKeyService {
	private shiftKeyPressedSubject = new BehaviorSubject<boolean>(false);

	get shiftKeyPressed$(): Observable<boolean> {
		return this.shiftKeyPressedSubject.asObservable();
	}

	setShiftKeyPressed(state: boolean) {
		this.shiftKeyPressedSubject.next(state);
	}
}
