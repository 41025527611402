import { Injectable, ComponentFactoryResolver, ComponentFactory, ApplicationRef, Type } from '@angular/core';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { Modal } from './modal-service-models/modal.model';
import { ModalRef } from './modal-service-models/modal-ref.model';

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	private modalContainer: HTMLElement;
	private modalContainerFactory: ComponentFactory<ModalContainerComponent>;

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef
	) {
		this.setupModalContainerFactory();
	}

	open<T extends Modal>(component: Type<T>, inputs?: any, hasBackdrop = true): ModalRef {
		this.setupModalContainerDiv();

		const modalContainerRef = this.appRef.bootstrap(this.modalContainerFactory, this.modalContainer);
		modalContainerRef.instance.hasBackdrop = hasBackdrop;

		const modalComponentRef = modalContainerRef.instance.createModal(component);

		if (inputs) {
			modalComponentRef.instance.onInjectInputs(inputs);
		}

		const modalRef = new ModalRef(modalContainerRef, modalComponentRef);

		return modalRef;
	}

	private setupModalContainerDiv(): void {
		this.modalContainer = document.createElement('div');
		document.getElementsByTagName('body')[0].appendChild(this.modalContainer);
	}

	private setupModalContainerFactory(): void {
		this.modalContainerFactory = this.componentFactoryResolver.resolveComponentFactory(ModalContainerComponent);
	}
}
