<div *ngIf="backdrop" class="modal-backdrop" style="z-index: 1050;" aria-hidden="true"></div>
<div class="modal">
	<div class="modal-dialog modal-lg" role="dialog" aria-hidden="true">
		<div class="modal-content">
			<div class="modal-dlg-header cursor-default">
				<h3 class="modal-title">
					<span *ngIf="name" style="padding-right: .2rem;">{{name}}</span>
					<span>{{'Signature' | translate}}</span>
				</h3>
				<button (click)="close(null)" aria-label="Close" class="close-modal-btn btn btn-link" type="button">
					<clr-icon aria-hidden="true" shape="close"></clr-icon>
				</button>
			</div>
			<div class="modal-body" style="padding-top: .3rem;">
				<app-signature-pad (onSave)="save($event)" (onCancel)="close(null)"></app-signature-pad>
			</div>
		</div>
	</div>
</div>
