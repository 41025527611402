import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Observable, from, takeUntil, Subject } from 'rxjs';
import { injectAndLoadStripe } from '../../stripeHelper';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { UpdatePayment } from '../../store/auth.actions';
import { AuthPageState } from '../../store/auth-page.store';
import { environment } from '../../../../environments/environment';
import { AuthState } from '../../store/auth.store';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-company-non-active',
	templateUrl: './company-non-active.component.html',
	styleUrls: ['./company-non-active.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyNonActiveComponent implements OnInit {
	@ViewChild(ClrForm, { static: true })
	clrForm: ClrForm;

	stripe;
	options;

	stripeLoader$: Observable<any>;
	stripeError: string;
	card;
	user;

	submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	error$;

	subject$ = new Subject();

	form: UntypedFormGroup = new UntypedFormGroup({
		email: new UntypedFormControl('', Validators.required),
		password: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
		companyId: new UntypedFormControl('', [Validators.required]),
	});

	constructor(
		private route: ActivatedRoute,
		private cd: ChangeDetectorRef,
		private toaster: ToastrService,
		private http: HttpClient,
		private store: Store,
		private translateService: TranslateService
	) {
		this.stripeLoader$ = from(injectAndLoadStripe());
		this.error$ = this.store.select(AuthPageState.error);
	}

	ngOnInit() {
		const params = this.route.snapshot.queryParams;
		if (params.companyId) {
			this.form.controls.companyId.patchValue(Number(params.companyId));
		}
		this.store
			.select(AuthState.user)
			.pipe(takeUntil(this.subject$))
			.subscribe((user) => {
				this.user = user;
				this.cd.markForCheck();
			});
		this.error$.pipe(takeUntil(this.subject$)).subscribe(() => {
			this.submitBtnState = ClrLoadingState.DEFAULT;
			this.cd.markForCheck();
		});
		this.stripeLoader$.subscribe((Stripe) => {
			this.stripe = new Stripe(environment.STRIPE_KEY);
			const elements = this.stripe.elements();
			this.card = elements.create('card');
			this.card.mount('#card-element-company-non-active');
			this.card.addEventListener('change', (event) => {
				if (event.error) {
					this.stripeError = event.error.message;
				} else {
					this.stripeError = '';
				}
				this.cd.markForCheck();
			});
		});
	}

	ngOnDestroy() {
		this.subject$.next(null);
		this.subject$.complete();
	}

	async submit() {
		if (!this.stripe) {
			console.log('No stripe!');
			return;
		}
		if (!this.form.valid) {
			this.clrForm.markAsTouched();
			this.toaster.error('Please fill all required fields!');
			return;
		}
		const formData = this.form.value;
		this.submitBtnState = ClrLoadingState.LOADING;
		this.cd.markForCheck();
		const { secret } = await this.http.get<any>('/api/public/intent-secret').toPromise();
		const result = await this.stripe.confirmCardSetup(secret, {
			payment_method: {
				card: this.card,
				billing_details: { email: formData.email },
			},
		});
		if (result.error) {
			this.stripeError = result.error.message;
			this.submitBtnState = ClrLoadingState.DEFAULT;
			this.cd.markForCheck();
			return;
		}
		formData.stripeToken = result.setupIntent.payment_method;
		this.store.dispatch(new UpdatePayment(formData));
	}

	getCompanyDisabledLabel(c) {
		if (!c.userIsActive) {
			return this.translateService.instant('(User is deactivated)');
		}
		if (c.companyIsActive) {
			return this.translateService.instant('(Company is active)');
		}
		return '';
	}
}
