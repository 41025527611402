import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { UserDto } from '../../../../../pss2-shared/apiModels/userModel';
import { AuthHttpService } from '../services/auth-http.service';
import {
	Logout,
	LoginSuccess,
	Login,
	PageRequestFailure,
	LoginRedirect,
	RegisterSuccess,
	PageRequestSuccess,
	Register,
	ConfirmRegistration,
	CheckLoginSuccess,
	GetUserAppSettings,
	GotUserAppSettings,
	PatchUserAppSettings,
	UpdatePayment,
	ForgotPassword,
	ValidateResetToken,
	ChangePassword,
	MuteNews,
	HttpError401,
	UpdateUser,
	SwitchCompany,
	IntentToSwitchCompany,
	HttpError403,
	UpdateSecurityInfo,
	GetLoginBackground,
	VerifyOtpCode,
	GetCompanySharedSettings,
} from './auth.actions';
import { RouteNavigate } from 'src/app/route-handler.service';
import { CompanySettings, CompanySettingsHttpService } from 'src/app/company-settings/services/company-settings-http.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { of, zip } from 'rxjs';
import { Injectable } from '@angular/core';
import { SetDefaultState } from 'src/app/main-app.actions';
import { UsersHttpService } from 'src/app/company-settings/services/users-http.service';
import { PersistenceService } from 'src/app/shared/persistence.service';

interface AppSettings {
	hideWizard?: boolean;
}

export class AuthStateModel {
	user: UserDto;
	appSettings: AppSettings;
	companySharedSettings: CompanySettings;
	question: string;
}

@State<AuthStateModel>({
	name: 'auth',
	defaults: {
		user: null,
		appSettings: { hideWizard: true },
		companySharedSettings: null,
		question: null,
	},
})
@Injectable()
export class AuthState {
	@Selector()
	static user(state: AuthStateModel) {
		return state.user;
	}

	@Selector()
	static appSettings(state: AuthStateModel) {
		return state.appSettings;
	}

	@Selector()
	static companySharedSettings(state: AuthStateModel) {
		return state.companySharedSettings;
	}

	@Selector()
	static question(state: AuthStateModel) {
		return state.question;
	}

	constructor(
		private authHttpService: AuthHttpService,
		private companySettingsHttpService: CompanySettingsHttpService,
		private translateService: TranslateService,
		private toasterService: ToastrService,
		private usersHttpService: UsersHttpService,
		private persistenceService: PersistenceService
	) {}

	@Action(UpdatePayment)
	updatePayment(ctx: StateContext<AuthStateModel>, { payload }: UpdatePayment) {
		return this.authHttpService.updatePayment(payload).pipe(
			tap((user) => ctx.dispatch(new LoginSuccess({ user }))),
			catchError((error) => ctx.dispatch(new PageRequestFailure(error)))
		);
	}

	@Action(Login)
	login(ctx: StateContext<AuthStateModel>, { payload, returnUrl }: Login) {
		return this.authHttpService.login(payload).pipe(
			tap((user) => ctx.dispatch(new LoginSuccess({ user, returnUrl }))),
			catchError((error) => ctx.dispatch(new PageRequestFailure(error)))
		);
	}

	@Action(ForgotPassword)
	forgotPassword(ctx: StateContext<AuthStateModel>, { payload }: ForgotPassword) {
		return this.authHttpService.forgotPassword(payload).pipe(
			tap(() => ctx.dispatch([new PageRequestSuccess(), new RouteNavigate(['/auth/reset-password-sent'])])),
			catchError((error) => ctx.dispatch(new PageRequestFailure(error)))
		);
	}

	@Action(ValidateResetToken)
	validateResetToken(ctx: StateContext<AuthStateModel>, { payload }: ValidateResetToken) {
		return this.authHttpService.validateResetToken(payload.token).pipe(
			tap((data: any) => {
				ctx.patchState({ question: data.securityQuestion });
			}),
			catchError((error) => ctx.dispatch(new PageRequestFailure(error)))
		);
	}

	@Action(ChangePassword)
	changePassword(ctx: StateContext<AuthStateModel>, { payload }: ChangePassword) {
		return this.authHttpService.changePassword(payload).pipe(
			tap((user) => ctx.dispatch(new LoginSuccess({ user }))),
			catchError((error) => ctx.dispatch(new PageRequestFailure(error)))
		);
	}

	@Action(CheckLoginSuccess)
	checkLoginSuccess(ctx: StateContext<AuthStateModel>, { payload }: CheckLoginSuccess) {
		ctx.patchState({ user: Object.assign({}, payload.user, { permissions: new Set(payload.user.permissions) }) });
		return ctx.dispatch([new GetUserAppSettings()]);
	}

	@Action(UpdateUser)
	UpdateUser(ctx: StateContext<AuthStateModel>, { payload }: UpdateUser) {
		const state = ctx.getState();
		ctx.patchState({ user: { ...state.user, ...payload } });
	}

	@Action(LoginSuccess)
	loginSuccess(ctx: StateContext<AuthStateModel>, { payload }: LoginSuccess) {
		ctx.patchState({ user: Object.assign({}, payload.user, { permissions: new Set(payload.user.permissions) }) });
		if (payload.isJustAfterConfirmation) {
			return ctx.dispatch([new RouteNavigate([`/setup/users/edit/${payload.user.id}/profile`])]);
		}
		if (!payload.user.companyIsActive) {
			if (payload.tryToSwitchCompany) {
				const params = payload.tryToSwitchCompany ? { companyId: payload.user.companyId } : {};
				return ctx.dispatch([new RouteNavigate(['/auth/company-non-active'], params)]);
			}
			return ctx.dispatch(new RouteNavigate(['/auth/company-non-active'], { returnUrl: payload.returnUrl }));
		}
		const actions = [new PageRequestSuccess(), new RouteNavigate([payload.returnUrl || '/'], {})];
		if (payload.user.isOtpAuthPassed) {
			actions.push(new GetLoginBackground());
		}
		return ctx.dispatch(actions);
	}

	@Action(UpdateSecurityInfo)
	updateSecurityInfo(ctx: StateContext<AuthStateModel>, { payload }: UpdateSecurityInfo) {
		return this.usersHttpService.updateSecurityData(payload.security).pipe(
			tap(() => ctx.dispatch([new LoginSuccess({ user: { ...ctx.getState().user, securityUpdateRequired: false }, returnUrl: payload.returnUrl })])),
			catchError((error) => ctx.dispatch(new PageRequestFailure(error)))
		);
	}

	@Action(Logout)
	logout(ctx: StateContext<AuthStateModel>) {
		return this.authHttpService.logout().pipe(
			tap(() => {
				ctx.patchState({ user: null });
				ctx.dispatch(new LoginRedirect());
			})
		);
	}

	@Action(LoginRedirect)
	loginRedirect(ctx: StateContext<AuthStateModel>, { payload }) {
		return ctx.dispatch(new RouteNavigate(['/auth'], payload));
	}

	@Action(Register)
	register(ctx: StateContext<AuthStateModel>, { payload }: Register) {
		return this.authHttpService.register(payload).pipe(
			tap(() => ctx.dispatch(new RegisterSuccess())),
			catchError((error) => ctx.dispatch(new PageRequestFailure(error)))
		);
	}

	@Action(ConfirmRegistration)
	confirm(ctx: StateContext<AuthStateModel>, { payload }: ConfirmRegistration) {
		return this.authHttpService.confirmRegistration(payload).pipe(
			catchError((error: any) => {
				ctx.dispatch(new PageRequestFailure(error));
				return of(null);
			}),
			tap((user: UserDto) => (user ? ctx.dispatch(new LoginSuccess({ user, isJustAfterConfirmation: true })) : null))
		);
	}

	@Action(RegisterSuccess)
	registerSuccess(ctx: StateContext<AuthStateModel>) {
		return ctx.dispatch([new PageRequestSuccess(), new RouteNavigate(['/auth/registration-completed'])]);
	}

	@Action(GetUserAppSettings)
	getUserAppSettings(ctx: StateContext<AuthStateModel>) {
		return this.companySettingsHttpService.getUserAppSettings().pipe(tap((settings) => ctx.dispatch(new GotUserAppSettings({ settings }))));
	}

	@Action(GotUserAppSettings)
	gotUserAppSettings(ctx: StateContext<AuthStateModel>, { payload }: GotUserAppSettings) {
		ctx.patchState({
			appSettings: payload.settings,
		});
	}

	@Action(PatchUserAppSettings)
	patchUserAppSettings(ctx: StateContext<AuthStateModel>, { payload }: PatchUserAppSettings) {
		return this.companySettingsHttpService.patchUserAppSettings(payload.settings).pipe(
			tap(() =>
				ctx.patchState({
					appSettings: Object.assign({}, ctx.getState().appSettings, payload.settings),
				})
			)
		);
	}

	@Action(MuteNews)
	muteNews(ctx: StateContext<AuthStateModel>) {
		return this.companySettingsHttpService.muteNews().pipe(
			tap(() =>
				ctx.patchState({
					user: Object.assign({}, ctx.getState().user, { needToShowNews: false }),
				})
			)
		);
	}

	@Action(IntentToSwitchCompany)
	intentToSwitchCompany(ctx: StateContext<AuthStateModel>, { payload }: IntentToSwitchCompany) {
		return ctx.dispatch(new RouteNavigate(['/switch-company'], { companyId: payload.companyId }, true));
	}

	@Action(SwitchCompany)
	switchCompany(ctx: StateContext<AuthStateModel>, { payload }: SwitchCompany) {
		return this.authHttpService.switchCompany(payload.companyId).pipe(
			switchMap((user: UserDto) => {
				return ctx.dispatch(new SetDefaultState()).pipe(tap(() => ctx.dispatch(new LoginSuccess({ user: user, tryToSwitchCompany: true }))));
			})
		);
	}

	@Action(HttpError401)
	handleHttp401(ctx: StateContext<AuthStateModel>, { payload }) {
		const err = payload;
		const msg$ = this.translateService.get(err.error?.message || 'Authentication required. Please refresh the page.');
		const title$ = this.translateService.get(err.error?.title || 'Pavement Software Solutions');
		zip(msg$, title$).subscribe(([msg, title]) => {
			this.toasterService.error(msg, title, { positionClass: 'toast-bottom-center' });
		});
	}

	@Action(HttpError403)
	handleHttp403(ctx: StateContext<AuthStateModel>, { payload }) {
		const err = payload;
		const msg$ = this.translateService.get(err.error?.message || 'You do not have permission to perform this action.');
		const title$ = this.translateService.get(err.error?.title || 'Pavement Software Solutions');
		zip(msg$, title$).subscribe(([msg, title]) => {
			this.toasterService.error(msg, title, { positionClass: 'toast-bottom-center' });
		});
	}

	@Action(GetLoginBackground)
	getLoginBackground() {
		return this.companySettingsHttpService.getCompanyLoginBackground().subscribe((response) => {
			if (!response.loginBackground) {
				return;
			}

			this.persistenceService.setLoginBackground(response.loginBackground);
		});
	}

	@Action(VerifyOtpCode)
	verifyOtpCode(ctx: StateContext<AuthStateModel>, { payload }: VerifyOtpCode) {
		return this.authHttpService.verifyOtpCode(payload.data).pipe(
			tap((user) => {
				ctx.dispatch(new LoginSuccess({ user, returnUrl: payload.returnUrl }));
			}),
			catchError((error) => ctx.dispatch(new PageRequestFailure(error)))
		);
	}

	@Action(GetCompanySharedSettings)
	getCompanySharedSettings(ctx: StateContext<AuthStateModel>) {
		return this.companySettingsHttpService.getCompanySharedSettings().pipe(tap((settings) => ctx.patchState({ companySharedSettings: settings })));
	}
}
