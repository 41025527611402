<form clrForm clrLayout="vertical">
	<clr-date-container [style.margin]="0">
		<label>From</label>
		<input #input_low type="date" clrDate name="low" [(ngModel)]="low" #focusMe />
	</clr-date-container>
	<clr-date-container>
		<label>To</label>
		<input #input_high type="date" clrDate name="high" [(ngModel)]="high" />
	</clr-date-container>
	<div class="clr-form-control">
		<div class="btn-group">
			<button class="btn btn-primary" type="submit" (click)="apply()">Apply</button>
			<button class="btn" type="button" (click)="clear()">Clear</button>
		</div>
	</div>
</form>
