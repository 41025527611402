export interface ChatMessage {
	from: number; // user id
	to: number; // user id
	text: string; // plain text
	sentAt: Date; // timestamp
	uuid: string; // uniq message id
	read: boolean; // true if the message is already read by receiver
}

export interface ChatContact {
	id: number; //user id
	name: string;
	newMessages: number;
	lastMessageTime: Date;
	online: boolean;
}

export interface ChatUserDto {
	userId: number;
	companyId: number;
	authToken: string;
}

export interface ChatConnectionConfig {
	token: string;
}

export interface ChatHistoryQuery {
	partnerId: number;
	since?: Date;
	page?: number; // default is 1
	pageSize?: number; // default is 50
}

export interface ChatMarkMessagesReadQuery {
	partnerId: number;
	upto: Date;
}

export interface ChatUserState {
	userId: number;
	online: boolean;
}

export interface ChatOnlineState {
	contacts: ChatContact[];
}

export interface ChatSystemNotification {
	title: string;
	message: string;
}

export type ChatLoginQuery = ChatUserDto;

export enum CHAT_RPC_NOTIFICATIONS {
	user_state = 'user_state',
	incoming_message = 'incoming_message',
	incoming_system_notification = 'incoming_system_notification',
}

export enum CHAT_RPC_COMMANDS {
	login = 'login',
	sendChatMessage = 'sendChatMessage',
	pullChatHistory = 'pullChatHistory',
	pullChatState = 'pullChatState',
	markMessagesRead = 'markMessagesRead',
}
