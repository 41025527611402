import { Component, ChangeDetectionStrategy, Inject, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Validators, FormControl, FormArray, FormGroup } from '@angular/forms';
import { Phone } from '@pss2-shared/phone';
import { COUNTRIES, STATES } from '@pss2-shared/countries';
import { DetailedPaveAmericaClientRequest, PaveAmericaClient, UpdatePaveAmericaClientRequest } from '@pss2-shared/apiModels';
import { paveAmericaClientRequestStatusToLabel } from '@pss2-shared/paveAmerica';
import { POPUP_DLG_DATA, PopupDlgOverlayRef } from 'src/app/shared/popup-dlg.service';
import { getAddressFormGroup } from 'src/app/shared/components/address/address.component';
import { PhonesComponent } from '../phones/phones.component';
import { arrayExactLength } from '../../utils/formUtils';

export type PaveAmericaClientRequestDlgComponentData =
	| {
			mode: 'create';
			paveAmericaClient: PaveAmericaClient;
	  }
	| {
			mode: 'view';
			paveAmericaClientRequest: DetailedPaveAmericaClientRequest;
			canApprove: boolean;
			canUpdate: boolean; // if can update = can decline
	  };

export type PaveAmericaClientRequestDlgComponentOutput =
	| { type: 'save'; payload: UpdatePaveAmericaClientRequest }
	| { type: 'approve'; payload: UpdatePaveAmericaClientRequest }
	| { type: 'decline' };

@Component({
	selector: 'app-pave-america-client-request-dlg',
	templateUrl: './pave-america-client-request-dlg.component.html',
	styleUrls: ['./pave-america-client-request-dlg.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaveAmericaClientRequestDlgComponent implements OnInit, AfterViewInit {
	@ViewChild('phones', { static: true })
	phones: PhonesComponent;

	isUpdateRequest = false;
	showPaveAmericaClientColumn = false;

	form = new FormGroup({
		name: new FormControl<string>(null, Validators.required),
		email: new FormControl<string>(null),
		address: getAddressFormGroup(false),
		phones: new FormArray<FormControl<Phone>>([], arrayExactLength(1)),
	});

	countries = COUNTRIES;
	states = STATES;
	statusToLabel = paveAmericaClientRequestStatusToLabel;

	paveAmericaClientForm = new FormGroup({
		name: new FormControl<string>({ value: '', disabled: true }),
		email: new FormControl<string>({ value: '', disabled: true }),
		address: getAddressFormGroup(false, true),
		phone: new FormGroup({
			type: new FormControl<string>({ value: '', disabled: true }),
			number: new FormControl<string>({ value: '', disabled: true }),
		}),
	});

	get paveAmericaClientCountry(): string {
		return this.paveAmericaClientForm.value.address.country;
	}

	get paveAmericaClientState(): string {
		return this.paveAmericaClientForm.value.address.state;
	}

	get paveAmericaClientGeolocation() {
		return this.paveAmericaClientForm.value.address.geolocation;
	}

	get canEdit(): boolean {
		return this.data?.mode === 'view' ? this.data.canUpdate : true;
	}

	constructor(
		private cd: ChangeDetectorRef,
		public dialogRef: PopupDlgOverlayRef,
		@Inject(POPUP_DLG_DATA) public data: PaveAmericaClientRequestDlgComponentData
	) {}

	ngOnInit() {
		switch (this.data.mode) {
			case 'create': {
				this.isUpdateRequest = Boolean(this.data.paveAmericaClient.id);
				this.form.patchValue(this.data.paveAmericaClient);
				break;
			}
			case 'view': {
				this.isUpdateRequest = Boolean(this.data.paveAmericaClientRequest.paveAmericaClientId);
				this.form.patchValue({
					name: this.data.paveAmericaClientRequest.name,
					email: this.data.paveAmericaClientRequest.email,
					address: this.data.paveAmericaClientRequest.address,
				});
				if (this.isUpdateRequest) {
					this.paveAmericaClientForm.patchValue(this.data.paveAmericaClientRequest.paveAmericaClient);
				}
				if (!this.canEdit) {
					this.form.disable();
				}
				break;
			}
		}
		this.cd.markForCheck();
	}

	ngAfterViewInit(): void {
		switch (this.data.mode) {
			case 'create': {
				this.phones.dataEmitter.emit([this.data.paveAmericaClient.phone ?? { type: 'Phone', number: '' }]);
				break;
			}
			case 'view': {
				this.phones.dataEmitter.emit([this.data.paveAmericaClientRequest.phone]);
				break;
			}
		}
		this.cd.markForCheck();
	}

	getStateLabel() {
		return this.states[this.paveAmericaClientCountry].find((state) => state.abbreviation === this.paveAmericaClientState)?.name ?? '';
	}

	save() {
		this.dialogRef.close({ type: 'save', payload: this.getPaveAmericaClient() } satisfies PaveAmericaClientRequestDlgComponentOutput);
	}

	approve() {
		this.dialogRef.close({ type: 'approve', payload: this.getPaveAmericaClient() } satisfies PaveAmericaClientRequestDlgComponentOutput);
	}

	decline() {
		this.dialogRef.close({ type: 'decline' } satisfies PaveAmericaClientRequestDlgComponentOutput);
	}

	cancel() {
		this.dialogRef.close(null);
	}

	getPaveAmericaClient(): UpdatePaveAmericaClientRequest {
		return {
			name: this.form.value.name,
			email: this.form.value.email,
			address: this.form.value.address,
			phone: this.form.value.phones[0] ?? null,
		};
	}
}
