<clr-alerts [clrCurrentAlertIndex]="currentStepIndex" (clrCurrentAlertIndexChange)="onStepChange($event)">
	<clr-alert
		(clrAlertClosedChange)="stepClosed(step, $event)"
		*ngFor="let step of steps | filterCallback: stepVisible"
		[clrAlertType]="'info'"
		[clrAlertAppLevel]="true">
		<clr-alert-item>
			<!-- <span>
				<div class="alert-actions">
					<button (click)="runStepAction(step)" class="btn alert-action">Get me there</button>
				</div>
			</span> -->
			<span class="alert-text">
				{{step.text}}
			</span>
			<div class="alert-actions">
				<button (click)="runStepAction(step)" class="btn alert-action">Take me there</button>
			</div>
			<!-- <div class="alert-actions">
				<button (click)="cancelWizard()" class="btn alert-action">Do not show this</button>
			</div> -->
		</clr-alert-item>
	</clr-alert>
</clr-alerts>
