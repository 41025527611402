import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/auth.store';
import { RouteNavigate } from 'src/app/route-handler.service';

@Injectable({
	providedIn: 'root',
})
export class UserGuard {
	constructor(private store: Store) {}
	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		return this.store.selectOnce(AuthState.user).pipe(
			map((user) => user != null),
			tap((isUser) => {
				return isUser || this.store.dispatch(new RouteNavigate(['/']));
			})
		);
	}
}
