import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { SwitchCompanyComponent } from './switch-company/switch-company.component';

const routes: Routes = [
	{
		path: '',
		component: MainPageComponent,
		children: [
			{ path: '', redirectTo: '/workflow/dashboard', pathMatch: 'full' },
			{ path: 'setup', loadChildren: () => import('./company-settings/company-settings.module').then((m) => m.CompanySettingsModule) },
			{ path: 'crm', loadChildren: () => import('./crm/crm.module').then((m) => m.CrmModule) },
			{ path: 'workflow', loadChildren: () => import('./workflow/workflow.module').then((m) => m.WorkflowModule) },
			{ path: 'schedule', loadChildren: () => import('./schedule/schedule.module').then((m) => m.ScheduleModule) },
			{ path: 'executive-reports', loadChildren: () => import('./executive-reports/executive-reports.module').then((m) => m.ExecutiveReportsModule) },
			{ path: 'tools', loadChildren: () => import('./tools/tools.module').then((m) => m.ToolsModule) },
			{ path: 'company-files', loadChildren: () => import('./company-files/company-files.module').then((m) => m.SharedFilesModule) },
		],
		canActivate: [AuthGuard],
	},
	{ path: 'switch-company', component: SwitchCompanyComponent },
	{ path: 'measure', loadChildren: () => import('./measure-tool/measure-tool.module').then((m) => m.MeasureToolModule), canActivate: [AuthGuard] },
	{ path: 'admin', loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule) },
	{ path: 'client-portal', loadChildren: () => import('./client-portal/client-portal.module').then((m) => m.ClientPortalModule) },
	{ path: '**', component: NotFoundPageComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
