import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CompanySettingsHttpService } from 'src/app/company-settings/services/company-settings-http.service';
import { PopupDlgOverlayRef } from 'src/app/shared/popup-dlg.service';
import { Store } from '@ngxs/store';

@Component({
	selector: 'app-user-news',
	templateUrl: './user-news.component.html',
	styleUrls: ['./user-news.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNewsComponent implements OnInit {
	news;
	constructor(
		private httpService: CompanySettingsHttpService,
		private dialogRef: PopupDlgOverlayRef,
		private cd: ChangeDetectorRef,
		private store: Store
	) {}

	ngOnInit() {
		this.httpService.getNews().subscribe((data) => {
			this.news = data.text;
			this.cd.markForCheck();
		});
	}

	close() {
		// this.store.dispatch(new MuteNews()).subscribe(() => {
		// 	this.dialogRef.close( null );
		// });
		this.dialogRef.close(null);
	}
}
