<div class="modal">
	<div class="modal-dialog modal-size fadeDown in" role="dialog" aria-hidden="true">
		<div class="modal-content">
			<div class="modal-header">
				<button aria-label="Close" class="close" type="button" (click)="cancel()">
					<clr-icon shape="close"></clr-icon>
				</button>
				<h3 class="modal-title dialog-title">
					<clr-icon shape="cog"></clr-icon>
					<div>Column Order Settings</div>
				</h3>
			</div>
			<div class="modal-body" cdkScrollable>
				<div cdkDropList class="columns-list" (cdkDropListDropped)="drop($event)">
					<div *ngFor="let column of columns; let i = index" cdkDrag class="columns-list__item">
						<div class="placeholder" *cdkDragPlaceholder></div>

						<div class="columns-list__item-name">
							<div class="columns-list__item-badge-wrapper">
								<span class="badge badge-info columns-list__item-badge">{{i + 1}}</span>
							</div>
							<span>{{columnToLabel[column]}}</span>
						</div>
						<clr-icon shape="drag-handle" size="24"></clr-icon>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button class="btn btn-outline" type="button" (click)="cancel()">Close</button>
				<button class="btn btn-primary" type="button" (click)="ok()">Save</button>
			</div>
		</div>
	</div>
</div>
