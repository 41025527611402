import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

enum SpinnerSize {
	small,
	medium,
	large,
}

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
	@Input()
	size = SpinnerSize.small;

	constructor() {}
}
