import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { PopupDlgOverlayRef } from 'src/app/shared/popup-dlg.service';
import { CompanySettingsHttpService } from 'src/app/company-settings/services/company-settings-http.service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngxs/store';
import { UpdateUser } from 'src/app/auth/store/auth.actions';

@Component({
	selector: 'app-survey',
	templateUrl: './survey.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyComponent implements OnInit {
	constructor(
		public dialogRef: PopupDlgOverlayRef,
		private toaster: ToastrService,
		private httpService: CompanySettingsHttpService,
		private store: Store
	) {}

	ngOnInit() {}

	numClick(score: number) {
		this.httpService
			.sendCompanySurvey(score)
			.toPromise()
			.then((surveyDate) => {
				this.toaster.info('Survey sent!');
				this.store.dispatch(new UpdateUser(surveyDate));
			});
		this.dialogRef.close(null);
	}
}
