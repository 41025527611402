import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'app-registration-completed',
	templateUrl: './registration-completed.component.html',
	styleUrls: ['./registration-completed.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationCompletedComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
