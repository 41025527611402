export async function injectAndLoadStripe() {
	if ((window as any).Stripe) {
		return Promise.resolve((window as any).Stripe);
	}

	return new Promise((resolve) => {
		let head: any = document.getElementsByTagName('head');

		if (head.length) head = head[0];
		else head = document.getElementsByTagName('body')[0];

		const script = document.createElement('script');
		script.setAttribute('src', 'https://js.stripe.com/v3/');
		script.setAttribute('type', 'text/javascript');

		script.addEventListener('load', () => {
			resolve((window as any).Stripe);
		});

		head.appendChild(script);
	});
}
