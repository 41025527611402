import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Modal } from '../../modal-service-models/modal.model';

@Component({
	selector: 'app-signature-dialog',
	templateUrl: './signature-dialog.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureDialogComponent extends Modal {
	name = null;
	backdrop = false;
	constructor() {
		super();
	}

	onInjectInputs(inputs: any) {
		this.backdrop = inputs.backdrop ?? false;
		this.name = inputs.name ?? null;
	}

	save(signature: string) {
		this.close(signature);
	}
}
