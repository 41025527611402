export enum AuthActionTypes {
	SetTitle = '[Main] Set Title',
	SetDefaultState = '[Main] Set Default State',
}

export class SetTitle {
	static readonly type = AuthActionTypes.SetTitle;
	constructor(public payload: { title: string }) {}
}

export class SetDefaultState {
	static readonly type = AuthActionTypes.SetDefaultState;
	constructor() {}
}
