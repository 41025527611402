import { Injectable } from '@angular/core';
import { ResourceHttpService } from 'src/app/shared/resource-http.service';
import { HttpClient } from '@angular/common/http';
import { TempFileModel } from '../../../../../pss2-shared/apiModels/index';

@Injectable({
	providedIn: 'root',
})
export class LaborEmployeesHttpService extends ResourceHttpService {
	constructor(http: HttpClient) {
		super(http, 'labor/employees');
	}

	getEmployeesCSV(params) {
		return this.http.get<TempFileModel>(`${this.entityUrl}/csv`, { params });
	}

	updateEmployeesCSV(fd: FormData) {
		return this.http.put<any>(`${this.entityUrl}/csv`, fd);
	}

	getTrainingCourseReportOptions() {
		return this.http.get<
			{
				id: number;
				name: string;
				trainingCourseCategoryName: string;
			}[]
		>(`${this.entityUrl}/training-course-report/options`);
	}

	getTrainingCourseReportPdf(trainingCoursesIds: number[]) {
		return this.http.get<TempFileModel>(`${this.entityUrl}/training-course-report/pdf`, { params: { trainingCoursesIds } });
	}

	getEmployeeTrainingCourses(id: number, params = {}) {
		return this.http.get<any>(`${this.entityUrl}/${id}/training-courses`, {
			params,
		});
	}

	getEmployeeTrainingCoursesPdf(id: number, params = {}) {
		return this.http.get<TempFileModel>(`${this.entityUrl}/${id}/training-courses/pdf`, { params });
	}

	getAllAvailableTrainingCourses(employeeId: number) {
		return this.http.get<any>(`${this.entityUrl}/${employeeId}/available-training-courses`);
	}

	assignTrainingCourseToEmployee(employeeId: number, payload) {
		return this.http.post(`${this.entityUrl}/${employeeId}/assign-training-course`, payload);
	}

	getEmployeeTrainingCoursesHistory(id: number, params = {}) {
		return this.http.get<any>(`${this.entityUrl}/${id}/training-courses-history`, { params });
	}

	toggleTrainingCourseIsRequired(id: number, courseId: number) {
		return this.http.post<any>(`${this.entityUrl}/${id}/training-courses/${courseId}/toggle-is-required`, {});
	}

	revokeTrainingCourseCompletion(id: number, courseId: number) {
		return this.http.post<any>(`${this.entityUrl}/${id}/training-courses/${courseId}/revoke-completion`, {});
	}
}
