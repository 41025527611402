import { ChangeDetectorRef, OnInit, OnDestroy, Directive, inject } from '@angular/core';
import { Observable, of, Subject, zip } from 'rxjs';
import { ClrDatagridStateInterface } from '@clr/angular';
import { switchMap, map, filter, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, ResolveEnd, Router } from '@angular/router';
import { clrStateToQueryObject } from './utils/tableUtils';
import { GeneralTableQueryResult } from '../../../../pss2-shared/apiModels/tableQueryResult';
import { OfficesHttpService } from '../company-settings/services/offices-http.service';
import { ResourceHttpService } from './resource-http.service';
import { PersistenceService } from './persistence.service';
import { ModalService } from './modal-service.service';
import { GridSettingsService } from './grid-settings.service';

@Directive()
export abstract class GeneralTableComponent implements OnInit, OnDestroy {
	_response: GeneralTableQueryResult = { total: 0, results: [], summary: { totalValue: 0 } };

	public get response(): GeneralTableQueryResult {
		return this._response;
	}

	public set response(value: GeneralTableQueryResult) {
		this._response = value;
	}

	showInactive = false;
	offices = [];
	showOffices = [];

	reloader$ = new Subject();

	loading = true;

	prevGridState: any = {};

	get officesEnabled() {
		return this.offices.length > 1;
	}

	get httpService(): ResourceHttpService {
		return this.route.snapshot.data.httpService;
	}

	get additionalParams(): Observable<any> {
		return of({});
	}

	lastQueryParams = {};
	ignoreRefresh = false;
	destroyed$ = new Subject();

	public gridSettingsService = inject(GridSettingsService);

	constructor(
		protected cd: ChangeDetectorRef,
		protected route: ActivatedRoute,
		protected persistence: PersistenceService,
		private router: Router,
		private officesHttpService: OfficesHttpService = null,
		protected modalService: ModalService = null
	) {
		this.router.events
			.pipe(
				takeUntil(this.destroyed$),
				filter((e) => e instanceof ResolveEnd)
			)
			.subscribe(() => {
				this.ignoreRefresh = true;
			});

		this.additionalParams.subscribe(() => {
			this.ignoreRefresh = false;
			this.reloadItems(null);
		});
	}

	reloadItems(state: ClrDatagridStateInterface<any>) {
		this.loading = true;
		this.reloader$.next(state || this.prevGridState);
		if (state != null) {
			this.prevGridState = state;
			this.gridSettingsService.pageSize = state?.page?.size ?? 25;
		}
	}

	ngOnInit() {
		this.reloader$
			.pipe(
				takeUntil(this.destroyed$),
				filter(() => !this.ignoreRefresh),
				map(clrStateToQueryObject),
				switchMap((queryObject: any) => {
					return zip(of(queryObject), this.additionalParams);
				}),
				switchMap(([queryObject, addParams]: [any, any]) => {
					const params = {
						...queryObject,
						showInactive: this.showInactive,
						office: this.showOffices,
						...addParams,
					};
					this.lastQueryParams = params;
					return this.httpService.getAll(params);
				})
			)
			.subscribe((response: any) => {
				this.response = response;
				this.loading = false;
				this.cd.markForCheck();
			});

		this.officesHttpService?.getAll()?.subscribe((offices) => {
			this.offices = offices;
			this.cd.markForCheck();
		});
	}

	ngOnDestroy() {
		this.destroyed$.next(null);
		this.destroyed$.complete();
	}

	rowClickToOpen(item) {
		window.open(window.location.pathname + '/edit/' + item.id, '_blank');
	}
}
