import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { RouteNavigate } from 'src/app/route-handler.service';
import { SwitchCompany } from '../auth/store/auth.actions';

@Component({
	selector: 'app-switch-company',
	template: '',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchCompanyComponent implements OnInit {
	constructor(
		private store: Store,
		private toasterService: ToastrService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		const companyId = this.route.snapshot.queryParams.companyId;
		if (!companyId) {
			this.store.dispatch(new RouteNavigate(['/']));
			return;
		}
		this.store
			.dispatch(new SwitchCompany({ companyId }))
			.pipe(
				catchError((e) => {
					this.toasterService.error(e?.error?.error);
					this.store.dispatch(new RouteNavigate(['/']));
					return throwError(() => e);
				})
			)
			.subscribe(() => {});
	}
}
