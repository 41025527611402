import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './components/chat/chat.component';
import { NgxsModule } from '@ngxs/store';
import { ChatState } from './store/chat.store';

@NgModule({
	declarations: [ChatComponent],
	imports: [CommonModule, NgxsModule.forFeature([ChatState])],
	exports: [ChatComponent],
})
export class ChatModule {}
