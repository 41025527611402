import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { Register } from '../../store/auth.actions';
import { COUNTRIES } from '../../../../../../pss2-shared/countries';
import { RegistrationModelRequest } from '../../../../../../pss2-shared/apiModels/index';
import { omit } from 'ramda';
import { SECURITY_QUESTIONS } from 'src/app/shared/utils/securityQuestions';
import { Store } from '@ngxs/store';
import { AuthPageState } from '../../store/auth-page.store';
import { injectAndLoadStripe } from '../../stripeHelper';
import { Observable, from, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { getCurrentTimezone } from 'src/app/shared/utils/timezoneUtils';
import { lowerCharacters, minLength, specialCharacters, upperCharacters } from 'src/app/shared/utils/formUtils';
import { PersistenceService } from 'src/app/shared/persistence.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit, OnDestroy {
	@ViewChild(ClrForm, { static: true })
	clrForm: ClrForm;

	error$;

	pending$ = null;

	get password() {
		return this.form.value.password;
	}

	get userInputs() {
		const form = this.form.value;
		return [form.name, form.userName, form.email];
	}

	submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	form: UntypedFormGroup = new UntypedFormGroup({
		name: new UntypedFormControl('', Validators.required),
		userName: new UntypedFormControl('', Validators.required),
		phone: new UntypedFormControl('', Validators.required),
		email: new UntypedFormControl('', Validators.required),
		country: new UntypedFormControl('US', Validators.required),
		password: new UntypedFormControl('', [
			Validators.required,
			minLength(9),
			specialCharacters,
			upperCharacters,
			lowerCharacters,
			this.passwordsUpdaterValidator,
		]),
		confirmPassword: new UntypedFormControl('', [
			Validators.required,
			minLength(9),
			specialCharacters,
			upperCharacters,
			lowerCharacters,
			this.passwordsTheSameValidator,
		]),
		question: new UntypedFormControl('', Validators.required),
		answer: new UntypedFormControl('', Validators.required),
		agreeToTerms: new UntypedFormControl(false, this.agreeToTermsValidator),
	});

	countries = COUNTRIES;
	questions = SECURITY_QUESTIONS;

	stripe;

	stripeLoader$: Observable<any>;
	stripeError: string;
	card;

	subject$ = new Subject();

	backgroundImage: string | null;

	constructor(
		private store: Store,
		private toaster: ToastrService,
		private cd: ChangeDetectorRef,
		private http: HttpClient,
		private persistenceService: PersistenceService
	) {
		this.backgroundImage = this.persistenceService.getLoginBackground();
		this.stripeLoader$ = from(injectAndLoadStripe());
		this.error$ = this.store.select(AuthPageState.error);
	}

	passwordsTheSameValidator(confirmPass: UntypedFormControl): ValidationErrors | null {
		if (!confirmPass || !confirmPass.parent) {
			return null;
		}
		const pass = confirmPass.parent.get('password');
		if (!pass) return null;
		return pass && confirmPass && pass.value === confirmPass.value ? null : { passwordsNotSame: true };
	}

	passwordsUpdaterValidator(pass: UntypedFormControl): ValidationErrors | null {
		if (!pass || !pass.parent) {
			return null;
		}
		const confirmPass = pass.parent.get('confirmPassword');
		if (!confirmPass) return null;
		confirmPass.updateValueAndValidity();
		return null;
	}

	agreeToTermsValidator(agree: AbstractControl): ValidationErrors | null {
		return agree.value ? null : { notAgreed: true };
	}

	ngOnInit() {
		this.stripeLoader$.subscribe((Stripe) => {
			this.stripe = new Stripe(environment.STRIPE_KEY);
			const elements = this.stripe.elements();
			this.card = elements.create('card');
			this.card.mount('#card-element');
			this.card.addEventListener('change', (event) => {
				if (event.error) {
					this.stripeError = event.error.message;
				} else {
					this.stripeError = '';
				}
				this.cd.markForCheck();
			});
		});
		this.error$.pipe(takeUntil(this.subject$)).subscribe(() => {
			this.submitBtnState = ClrLoadingState.DEFAULT;
			this.cd.markForCheck();
		});
		this.pending$ = this.store
			.select(AuthPageState.pending)
			.pipe(tap((isPending) => (isPending ? this.form.disable() : this.form.enable())))
			.subscribe();
	}

	ngOnDestroy() {
		if (this.pending$) this.pending$.unsubscribe();
		this.subject$.complete();
	}

	async submit() {
		if (!this.stripe) {
			console.log('No stripe!');
			return;
		}
		if (!this.form.valid) {
			this.clrForm.markAsTouched();
			this.toaster.error('Please fill all required fields!');
			return;
		}
		const registrationData = omit(['confirmPassword'], this.form.value);
		const billingDetails = {
			email: registrationData.email,
			name: registrationData.name,
			address: {
				country: registrationData.country,
			},
		};
		this.submitBtnState = ClrLoadingState.LOADING;
		this.cd.markForCheck();
		const { secret } = await this.http.get<any>('/api/public/intent-secret').toPromise();
		const result = await this.stripe.confirmCardSetup(secret, {
			payment_method: {
				card: this.card,
				billing_details: billingDetails,
			},
		});
		if (result.error) {
			this.stripeError = result.error.message;
			this.submitBtnState = ClrLoadingState.DEFAULT;
			this.cd.markForCheck();
			return;
		}
		registrationData.stripeToken = result.setupIntent.payment_method;

		// const result = await this.stripe.createToken(this.card);
		// if (result.error) {
		// 	console.log(result.error);
		// 	this.stripeError = result.error.message;
		// 	return;
		// }
		// registrationData.stripeToken = result.token;
		registrationData.timezone = getCurrentTimezone();
		this.store.dispatch(new Register(registrationData as RegistrationModelRequest));
	}

	getBackgroundImage() {
		if (!this.backgroundImage) {
			return null;
		}
		return `url(${this.backgroundImage})`;
	}
}
