import { Injectable } from '@angular/core';
import { ResourceHttpService } from 'src/app/shared/resource-http.service';
import { HttpClient } from '@angular/common/http';
import { TempFileModel } from '../../../../../pss2-shared/apiModels/index';

@Injectable({
	providedIn: 'root',
})
export class SiteReportFormsHttpService extends ResourceHttpService {
	constructor(http: HttpClient) {
		super(http, 'entities/site-report-forms');
	}

	getDefaultSiteReports(params) {
		return this.http.get<any>(`${this.entityUrl}/default`, { params });
	}

	getDefaultSiteReport(id: number) {
		return this.http.get<any>(`${this.entityUrl}/default/${id}`);
	}

	preview(id: number) {
		return this.http.get<TempFileModel>(`${this.entityUrl}/${id}/preview`);
	}
}
