import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Modal } from '../../../shared/modal-service-models/modal.model';
import { SiteReportFormsHttpService } from 'src/app/company-settings/services/site-report-forms-http.service';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SITE_REPORT_EMPLOYEE_ROSTER_TYPE, SITE_REPORT_FORM_QUESTION_TYPE, SiteReport } from '../../../../../../pss2-shared/siteReport';
import { LaborEmployeesHttpService } from 'src/app/company-settings/services/labor-employees-http.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../../shared/modal-service.service';
import { SignatureDialogComponent } from '../../../shared/components/signature-dialog/signature-dialog.component';
import { BehaviorSubject, Observable, catchError, distinctUntilChanged, filter, lastValueFrom, map, of, switchMap, tap } from 'rxjs';
import { ClrLoadingState } from '@clr/angular';
import { SelectEmployeesSiteReportDlgComponent } from '../select-employees-site-report-dlg/select-employees-site-report-dlg.component';
import { SiteReportsHttpService } from '../../site-reports-http.service';
import { DndFileSelectorDlgComponent } from 'src/app/shared/components/dnd-file-selector-dlg/dnd-file-selector-dlg.component';

@Component({
	selector: 'app-complete-site-report-dlg',
	templateUrl: './complete-site-report-dlg.component.html',
	styleUrls: ['./complete-site-report-dlg.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompleteSiteReportDlgComponent extends Modal {
	siteReportId;
	jobId;
	completeBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	form = new UntypedFormGroup({
		id: new UntypedFormControl(),
		jobId: new UntypedFormControl(),
		employeeRosterType: new UntypedFormControl(SITE_REPORT_EMPLOYEE_ROSTER_TYPE.no),
		isSignatureRequired: new UntypedFormControl(false),
		atLeastOneImageRequired: new UntypedFormControl(false),
		isPreWork: new UntypedFormControl(false),
		employeeRoster: new UntypedFormArray([]),
		sections: new UntypedFormArray([]),
		signature: new UntypedFormGroup({
			base64: new UntypedFormControl(),
			fileName: new UntypedFormControl(),
			fileType: new UntypedFormControl(),
		}),
	});

	itemsSearchResults$: Observable<any>;
	itemsSearchLoading = false;
	itemsSearchInput$ = new BehaviorSubject<string>(null);
	isFirstSearch = true;

	get sections(): UntypedFormArray {
		return this.form.get('sections') as UntypedFormArray;
	}

	get isPreWork(): boolean {
		return this.form.get('isPreWork').value;
	}

	get employeeRosterType(): SITE_REPORT_EMPLOYEE_ROSTER_TYPE {
		return this.form.get('employeeRosterType').value;
	}

	get employeeRoster(): UntypedFormArray {
		return this.form.get('employeeRoster') as UntypedFormArray;
	}

	get isEmployeeRoster(): boolean {
		const employeeRosterType = this.employeeRosterType;
		return employeeRosterType != null && employeeRosterType !== SITE_REPORT_EMPLOYEE_ROSTER_TYPE.no;
	}

	isLoading = true;
	siteReportFormTitle = '';
	employees = [];

	employeeIds = [];

	headerInfo = new UntypedFormControl({ value: null, disabled: true });
	footerInfo = new UntypedFormControl({ value: null, disabled: true });

	filesCount = 0;

	constructor(
		private siteReportFormsHttpService: SiteReportFormsHttpService,
		private cd: ChangeDetectorRef,
		private employeesHttpService: LaborEmployeesHttpService,
		private toaster: ToastrService,
		private modalService: ModalService,
		private siteReportHttpService: SiteReportsHttpService
	) {
		super();
	}

	addSection() {
		const section = new UntypedFormGroup({
			id: new UntypedFormControl(),
			title: new UntypedFormControl(null),
			questions: new UntypedFormArray([]),
		});

		this.sections.push(section);
	}

	addQuestion(section: UntypedFormGroup) {
		(section.controls.questions as UntypedFormArray).push(
			new UntypedFormGroup({
				id: new UntypedFormControl(),
				question: new UntypedFormControl(),
				type: new UntypedFormControl('specify'),
				options: new UntypedFormControl(),
				answer: new UntypedFormControl([]),
				details: new UntypedFormControl(),
				isRequired: new UntypedFormControl({ value: false, disabled: true }),
			})
		);
	}

	isQuestionDropdown(question) {
		return [SITE_REPORT_FORM_QUESTION_TYPE.dropdown, SITE_REPORT_FORM_QUESTION_TYPE.multiple_dropdown].includes(question.value.type);
	}

	questionMaxAnswers(question) {
		return SITE_REPORT_FORM_QUESTION_TYPE.dropdown == question.value.type ? 1 : 'none';
	}

	questionOptions(question) {
		const options = question.value.options;
		return options ? options.split(',') : [];
	}

	async onInjectInputs(inputs: any) {
		this.siteReportId = inputs.id;
		this.employeeIds = inputs.employeeIds ?? [];

		const inputSections = inputs?.sections;
		const inputsEmployeeRoster = inputs.employeeRoster;

		const siteReport = await lastValueFrom(this.siteReportHttpService.getOne<SiteReport>(this.siteReportId));

		this.jobId = inputs.jobId || siteReport.jobId;

		this.filesCount = siteReport.files.length;

		this.form.patchValue({
			id: siteReport.id,
			jobId: this.jobId,
			employeeRosterType: siteReport.employeeRosterType,
			isSignatureRequired: siteReport.isSignatureRequired,
			atLeastOneImageRequired: siteReport.atLeastOneImageRequired,
			isPreWork: siteReport.isPreWork,
		});

		if (this.form.value.isPreWork) {
			this.form.controls.jobId.disable();
		}

		this.siteReportFormsHttpService.getOne(siteReport.companySiteReportFormId).subscribe((item) => {
			this.siteReportFormTitle = item.title;
			delete item.id;
			this.headerInfo.patchValue(item.headerInfo);
			this.footerInfo.patchValue(item.footerInfo);
			item.sections.forEach((s) => {
				delete s.id;
				s.questions.forEach((q) => {
					delete q.id;
				});
			});
			const sections = siteReport.startedBy != null ? inputSections ?? siteReport.sections : item.sections;
			sections.forEach((s, i) => {
				this.addSection();
				const section = this.sections.controls[i] as UntypedFormGroup;
				s.questions.forEach(() => {
					this.addQuestion(section);
				});
			});

			const employeeRoster = inputsEmployeeRoster ?? siteReport?.employeeRoster;

			employeeRoster.forEach(() => {
				this.addEmployeeInRoster();
			});

			this.form.patchValue({ sections, employeeRoster });
			this.isLoading = false;
			this.cd.markForCheck();
		});

		if (this.isEmployeeRoster) {
			this.employeesHttpService.getAll({ showInactive: false }).subscribe((employees) => {
				this.employees = employees;

				if (this.isEmployeeRoster && this.employeeIds.length) {
					const employeeIds = [
						...new Set([
							...this.form.value.employeeRoster.map((e) => e.employeeId),
							...this.employees.map((e) => e.id).filter((id) => this.employeeIds.includes(id)),
						]),
					];
					this.syncEmployeeRoster(employeeIds);
				}

				this.cd.markForCheck();
			});
		}

		this.itemsSearchResults$ = this.itemsSearchInput$.pipe(
			filter((term) => (term != null && term !== '') || (this.jobId != null && this.isFirstSearch)),
			distinctUntilChanged(),
			tap(() => (this.itemsSearchLoading = true)),
			switchMap((term) =>
				this.siteReportHttpService.searchJobs({ term, jobId: this.jobId }).pipe(
					map((items) => {
						return [...items];
					}),
					catchError(() => of([]))
				)
			),
			tap(() => {
				this.itemsSearchLoading = false;
				this.isFirstSearch = false;
			})
		);
	}

	getEmployeeName(employeeId: number) {
		return this.employees.find((e) => e.id === employeeId)?.name || '';
	}

	isValidFormValue(): boolean {
		const formValue = this.form.getRawValue();
		if (this.isEmployeeRoster && !formValue.employeeRoster.length) {
			this.toaster.error('This report requires at least one employee in employee roster!');
			return false;
		}
		const questions = formValue.sections.map((s) => s.questions).flat();
		if (questions.some((q) => q.isRequired && !q.answer.length && !q.details)) {
			this.toaster.error('Please answer all required questions!');
			return false;
		}
		if (formValue.atLeastOneImageRequired && this.filesCount == 0) {
			this.toaster.error('This report requires at least one attachment!');
			return false;
		}
		return true;
	}

	syncEmployeeRoster(employeeRosterIds: number[]) {
		for (const employeeId of employeeRosterIds) {
			const employeeRosterValue = this.employeeRoster.value;
			if (!employeeRosterValue.find((e) => e.employeeId === employeeId)) {
				this.addEmployeeInRoster(employeeId);
			}
		}
		const employeeRoster = this.employeeRoster.value.filter((e) => employeeRosterIds.includes(e.employeeId));

		this.employeeRoster.clear();

		employeeRoster.forEach(() => {
			this.addEmployeeInRoster();
		});

		this.employeeRoster.patchValue(employeeRoster);
	}

	async selectEmployees() {
		const employeeRosterIds = await lastValueFrom<number[]>(
			this.modalService
				.open(
					SelectEmployeesSiteReportDlgComponent,
					{
						employeeRoster: this.employeeRoster.value.map((e) => e.employeeId),
					},
					false
				)
				.onResult()
		);
		if (!employeeRosterIds) return;
		this.syncEmployeeRoster(employeeRosterIds);
		this.cd.markForCheck();
	}

	addEmployeeInRoster(employeeId = null) {
		(this.employeeRoster as UntypedFormArray).push(
			new UntypedFormGroup({
				id: new UntypedFormControl(),
				employeeId: new UntypedFormControl(employeeId),
				signatureId: new UntypedFormControl(),
				signature: new UntypedFormGroup({
					base64: new UntypedFormControl(),
					fileName: new UntypedFormControl(),
					fileType: new UntypedFormControl(),
					url: new UntypedFormControl(),
				}),
			})
		);
	}

	async complete(justStart = false) {
		if (!justStart) {
			const isValid = this.isValidFormValue();
			if (!isValid) return;
		}

		this.completeBtnState = ClrLoadingState.LOADING;
		this.cd.markForCheck();
		const formValue = this.form.getRawValue();

		const formData = {
			id: formValue.id,
			employeeRoster: formValue.employeeRoster,
			sections: formValue.sections,
			signature: formValue.signature,
			jobId: formValue.jobId,
		};

		if (justStart) {
			this.close({
				formData,
				justStart,
			});
			return;
		}

		if (this.employeeRosterType == SITE_REPORT_EMPLOYEE_ROSTER_TYPE.yes_with_signature) {
			for (const employee of formValue.employeeRoster) {
				if (employee.signatureId || employee.signature.base64) continue;
				const name = this.employees.find((e) => e.id === employee.employeeId)?.name;
				const signature = await lastValueFrom<string>(this.modalService.open(SignatureDialogComponent, { name, backdrop: false }, false).onResult());
				if (!signature) {
					this.completeBtnState = ClrLoadingState.DEFAULT;
					this.cd.markForCheck();
					return;
				}
				employee.signature = {
					base64: signature.split(';base64,').pop(),
					fileName: 'signature.png',
					fileType: 'image/png',
				};
			}
		}

		if (formValue.isSignatureRequired) {
			const signature = await lastValueFrom<string>(
				this.modalService.open(SignatureDialogComponent, { name: 'Performer', backdrop: false }, false).onResult()
			);
			if (!signature) {
				this.completeBtnState = ClrLoadingState.DEFAULT;
				this.cd.markForCheck();
				return;
			}

			formData.signature = {
				base64: signature.split(';base64,').pop(),
				fileName: 'signature.png',
				fileType: 'image/png',
			};
		}

		this.close({
			formData,
			justStart,
		});
	}

	async selectAttachments() {
		this.modalService
			.open(DndFileSelectorDlgComponent, {}, false)
			.onResult()
			.subscribe((files: File[]) => {
				if (files == null) return;
				this.addAttachment(files);
			});
	}

	async addAttachment(files: File[]) {
		if (!files.length) return;
		this.completeBtnState = ClrLoadingState.LOADING;
		this.cd.markForCheck();
		const fd = new FormData();
		for (const file of Array.from(files)) {
			fd.append('files', file);
		}
		try {
			await lastValueFrom(this.siteReportHttpService.uploadAttachment(this.form.value.id, fd));
			const siteReport = await lastValueFrom(this.siteReportHttpService.getOne<SiteReport>(this.siteReportId));
			this.filesCount = siteReport.files.length;
			this.toaster.info('File attached');
		} catch (e) {
			this.toaster.error(e.error.error);
		}
		this.completeBtnState = ClrLoadingState.DEFAULT;
		this.cd.markForCheck();
	}
}
