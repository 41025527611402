import { Component, OnInit } from '@angular/core';
import { ConfirmRegistration, LoginRedirect } from '../../store/auth.actions';
import { ActivatedRoute } from '@angular/router';
import { AuthPageState } from '../../store/auth-page.store';
import { Store } from '@ngxs/store';

@Component({
	selector: 'app-registration-confirmation',
	templateUrl: './registration-confirmation.component.html',
	styleUrls: ['./registration-confirmation.component.css'],
})
export class RegistrationConfirmationComponent implements OnInit {
	pending$;
	error$;

	constructor(
		private store: Store,
		private route: ActivatedRoute
	) {
		this.pending$ = this.store.select(AuthPageState.pending);
		this.error$ = this.store.select(AuthPageState.error);
	}

	ngOnInit() {
		this.store.dispatch(new ConfirmRegistration({ token: this.route.snapshot.params.activationToken }));
	}

	close() {
		this.store.dispatch(new LoginRedirect());
	}
}
