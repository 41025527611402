<div class="modal">
	<div class="modal-dialog modal-xl" role="dialog" aria-hidden="true">
		<div class="modal-content">
			<div class="modal-dlg-header cursor-default">
				<h3 class="modal-title">Complete {{siteReportFormTitle}}</h3>
				<button (click)="close(null)" aria-label="Close" class="close-modal-btn btn btn-link" type="button">
					<clr-icon aria-hidden="true" shape="close"></clr-icon>
				</button>
			</div>
			<div class="modal-body">
				<app-spinner *ngIf="isLoading" class="spinner-wrapper" [size]="1"></app-spinner>
				<ng-container *ngIf="!isLoading">
					<form clrForm>
						<div class="clr-form-control clr-row">
							<label class="clr-control-label clr-col-12 clr-col-md-2">Header Info</label>
							<div class="clr-control-container clr-col-12 clr-col-md-10">
								<app-proposal-text-editor [placeholders]="null" [formControl]="headerInfo" [hideToolBar]="true"></app-proposal-text-editor>
							</div>
						</div>
					</form>
					<form clrForm [formGroup]="form">
						<clr-control-container>
							<label>
								<a *ngIf="jobId" target="_blank" [routerLink]="['/workflow/jobs/edit', jobId]">Assigned to Job</a>
								<span *ngIf="!jobId">Assigned to Job</span>
							</label>
							<ng-select
								clrControl
								formControlName="jobId"
								[items]="itemsSearchResults$ | async"
								bindLabel="number"
								bindValue="id"
								[typeahead]="itemsSearchInput$"
								[loading]="itemsSearchLoading"
								placeholder="Select Job...">
							</ng-select>
							<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
						</clr-control-container>
						<clr-control-container *ngIf="isEmployeeRoster">
							<label>
								<span>Employee Roster</span>
							</label>
							<label class="clr-col-12 clr-col-md-10 display-flex">
								<div>
									<span class="employee-line" *ngFor="let employee of employeeRoster.value">
										{{getEmployeeName(employee.employeeId)}}
									</span>
								</div>
								<button class="btn btn-sm btn-sq btn-icon btn-link btn-list" (click)="selectEmployees()">
									<clr-icon shape="list"></clr-icon>
								</button>
							</label>
						</clr-control-container>
						<table class="section-table" formArrayName="sections">
							<thead>
								<tr>
									<th class="section-placeholder"></th>
									<th class="section-question text-align-left">{{'Question'| translate}}</th>
									<th class="section-answer text-align-left">{{'Answer'| translate}}</th>
									<th class="section-required-control">{{'Required'| translate}}</th>
								</tr>
							</thead>
							<tbody>
								<ng-container *ngFor="let section of sections.controls; let i = index;" [formGroupName]="i">
									<tr>
										<td colspan="5">
											<b>{{section.value.title}}</b>
										</td>
									</tr>
									<ng-container formArrayName="questions">
										<tr *ngFor="let question of section.controls.questions.controls; let j = index;" [formGroupName]="j">
											<th></th>
											<td>{{question.value.question}}</td>
											<td>
												<div class="answer-wrapper">
													<div *ngIf="isQuestionDropdown(question)">
														<ng-select
															class="clr-input"
															formControlName="answer"
															[multiple]="true"
															[maxSelectedItems]="questionMaxAnswers(question)"
															[closeOnSelect]="questionMaxAnswers(question) == 1">
															<ng-option *ngFor="let option of questionOptions(question)" [value]="option">{{option}}</ng-option>
														</ng-select>
													</div>
													<input type="text" placeholder="[Specify]" formControlName="details" class="clr-input" />
												</div>
											</td>
											<td>
												<input type="checkbox" formControlName="isRequired" />
											</td>
										</tr>
									</ng-container>
								</ng-container>
							</tbody>
						</table>
					</form>
					<form clrForm>
						<div class="clr-form-control clr-row">
							<label class="clr-control-label clr-col-12 clr-col-md-2">Footer Info</label>
							<div class="clr-control-container clr-col-12 clr-col-md-10">
								<app-proposal-text-editor [placeholders]="null" [formControl]="footerInfo" [hideToolBar]="true"></app-proposal-text-editor>
							</div>
						</div>
					</form>
				</ng-container>
			</div>
			<div class="modal-footer display-flex clr-justify-content-between">
				<div>
					<button class="btn btn-primary" type="button" [clrLoading]="completeBtnState" (click)="selectAttachments()">Add Attachments</button>
				</div>
				<div class="actions-wrapper">
					<button class="btn" type="button" [clrLoading]="completeBtnState" *ngIf="!isPreWork" (click)="complete(true)">Save</button>
					<button class="btn btn-primary" type="button" [clrLoading]="completeBtnState" (click)="complete()">Complete</button>
					<button class="btn" type="button" (click)="close(null)">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</div>
