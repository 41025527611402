import { Component } from '@angular/core';
import { GridSettingsService } from '../../grid-settings.service';
import { LinksInNewTabsService } from '../../links-in-new-tabs.service';

@Component({
	selector: 'app-clr-dg-footer-content',
	templateUrl: './clr-dg-footer-content.component.html',
	styleUrls: ['./clr-dg-footer-content.component.scss'],
	host: { class: 'clr-row clr-no-gutters clr-align-items-center' },
})
export class ClrDgFooterContentComponent {
	public constructor(
		public gridSettingsService: GridSettingsService,
		public linksInNewTabsService: LinksInNewTabsService
	) {}
}
