<ng-container [formGroup]="phones.parent" *ngIf="!isReadonly; else readonlyTemplate">
	<div class="margin-top">
		<section class="section-with-button title">
			<h4>Phones</h4>
			<button *ngIf="!withoutControls" class="btn btn-icon" type="button" (click)="addPhone()">
				<clr-icon shape="plus"></clr-icon>
			</button>
		</section>
		<div class="clr-form" [formArrayName]="formArrayName">
			<div class="clr-row">
				<div class="clr-col-auto" *ngFor="let phone of phones.controls; let i = index;">
					<div [formGroupName]="i">
						<clr-select-container>
							<label>Type</label>
							<select clrSelect formControlName="type">
								<option *ngFor="let type of phoneTypes" [value]="type">{{type}}</option>
							</select>
						</clr-select-container>
						<clr-input-container>
							<label>Number</label>
							<input clrInput type="text" formControlName="number" />
							<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
						</clr-input-container>
						<button *ngIf="!withoutControls" class="btn clr-form-control" type="button" (click)="removePhone(i)">Remove Phone</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-template #readonlyTemplate>
	<div class="margin-top">
		<section class="section-with-button title readonly-title">
			<h4>Phones</h4>
		</section>
		<div class="clr-form">
			<div *ngFor="let phone of phones.controls; let i = index;">
				<div class="clr-form-control clr-row clr-align-items-end readonly-select">
					<label class="clr-control-label clr-col-2">Type</label>
					<label class="clr-control-label clr-col-10">{{getPhoneType(i)}}</label>
				</div>
				<div class="clr-form-control clr-row clr-align-items-end readonly-field">
					<label class="clr-control-label clr-col-2">Number</label>
					<label class="clr-control-label clr-col-10">{{getPhoneNumber(i)}}</label>
				</div>
			</div>
		</div>
	</div>
</ng-template>
