import { Address } from './address';
import { BidItemBlock } from './bidItemCalculator';

export enum CONCRETE_TYPES {
	sap = 'sap',
	cg = 'cg',
	cylinders = 'cylinders',
}

export enum ADD_COST_CATEGORIES {
	UTILITY = 'Utility',
	MUNICIPAL = 'Municipal',
	PRIVATE = 'Private',
}

export function getConcreteTypeName(concreteType: CONCRETE_TYPES): string {
	switch (concreteType) {
		case CONCRETE_TYPES.sap:
			return 'Flat Work';
		case CONCRETE_TYPES.cg:
			return 'Curb&Gutter';
		case CONCRETE_TYPES.cylinders:
			return 'Cylinders';
		default:
			return 'Unknown';
	}
}

export function getCostsBlockShortAmountLabel(costsBlock: COSTS_BLOCKS) {
	switch (costsBlock) {
		case COSTS_BLOCKS.cement:
		case COSTS_BLOCKS.stone:
		case COSTS_BLOCKS.asphalt: {
			return 't';
		}
		case COSTS_BLOCKS.concrete: {
			return 'y';
		}
		case COSTS_BLOCKS.crackfill:
		case COSTS_BLOCKS.mastic: {
			return 'b';
		}
		case COSTS_BLOCKS.tac:
		case COSTS_BLOCKS.stripings:
		case COSTS_BLOCKS.sealer:
		case COSTS_BLOCKS.thermo: {
			return 'g';
		}
		case COSTS_BLOCKS.trucking: {
			return 'trk';
		}
		default:
			return '';
	}
}

export enum COSTS_BLOCKS {
	asphalt = 'asphalt',
	tac = 'tac',
	trucking = 'trucking',
	dump = 'dump',
	equip_delivery = 'equip_delivery',
	milling = 'milling',
	crackfill = 'crackfill',
	sealer = 'sealer',
	mastic = 'mastic',
	stone = 'stone',
	concrete = 'concrete',
	concrete_rebar = 'concrete_rebar',
	labor = 'labor',
	add_costs = 'add_costs',
	stripings = 'stripings',
	equipment = 'equipment',
	subcontractors = 'subcontractors',
	warranty = 'warranty',
	signage = 'signage',
	thermo = 'thermo',
	cement = 'cement',
}

export enum DAY_COSTS_BLOCKS {
	overhead = 'overhead',
}

// if cost_block has non-empty linkedTo array, such a cost_block cannot be added as separate bid_item_block or day_item_block
export const COSTS_BLOCKS_DEPENDENCIES: { [key in COSTS_BLOCKS]: { linkedTo: JOB_DAY_COSTS_BLOCKS[] } } = {
	add_costs: { linkedTo: [] },
	asphalt: { linkedTo: [] },
	cement: { linkedTo: [] },
	concrete: { linkedTo: [] },
	concrete_rebar: { linkedTo: [COSTS_BLOCKS.concrete] },
	crackfill: { linkedTo: [] },
	dump: { linkedTo: [] },
	equip_delivery: { linkedTo: [] },
	equipment: { linkedTo: [] },
	labor: { linkedTo: [] },
	mastic: { linkedTo: [] },
	milling: { linkedTo: [] },
	sealer: { linkedTo: [] },
	signage: { linkedTo: [] },
	stone: { linkedTo: [] },
	stripings: { linkedTo: [] },
	subcontractors: { linkedTo: [] },
	tac: { linkedTo: [] },
	thermo: { linkedTo: [] },
	trucking: { linkedTo: [] },
	warranty: { linkedTo: [] },
};

export function getLinkedCostsBlock(costsBlock: JOB_DAY_COSTS_BLOCKS): COSTS_BLOCKS[] {
	return Object.keys(COSTS_BLOCKS_DEPENDENCIES)
		
			.filter((k: string) => {
				const res = COSTS_BLOCKS_DEPENDENCIES[k];
				
		return res.linkedTo.includes(costsBlock);
		})
		.map((k) => COSTS_BLOCKS[k]);
}

export type JOB_DAY_COSTS_BLOCKS = COSTS_BLOCKS | DAY_COSTS_BLOCKS;

export const isMultiItemsCostsBlock = (test: COSTS_BLOCKS) =>
	[
		COSTS_BLOCKS.add_costs,
		COSTS_BLOCKS.subcontractors,
		COSTS_BLOCKS.equipment,
		COSTS_BLOCKS.stripings,
		COSTS_BLOCKS.equip_delivery,
		COSTS_BLOCKS.warranty,
		COSTS_BLOCKS.thermo,
		COSTS_BLOCKS.signage,
	].includes(test);

export const EQUIPMENT_LIKE_BLOCKS = [COSTS_BLOCKS.subcontractors, COSTS_BLOCKS.equipment, COSTS_BLOCKS.warranty] as const;
export type EquipmentLikeCostsBlock = (typeof EQUIPMENT_LIKE_BLOCKS)[number];

export const isAreaCostsBlock = (test: COSTS_BLOCKS) =>
	[COSTS_BLOCKS.asphalt, COSTS_BLOCKS.concrete, COSTS_BLOCKS.sealer, COSTS_BLOCKS.crackfill, COSTS_BLOCKS.cement].includes(test);

export const isOverheadBlock = (test: JOB_DAY_COSTS_BLOCKS) => test === DAY_COSTS_BLOCKS.overhead;

export const isSignageLikeBlock = (test: COSTS_BLOCKS) => [COSTS_BLOCKS.signage, COSTS_BLOCKS.thermo].includes(test);

export const isExcludableFromGeoAreaBlock = (test: COSTS_BLOCKS) => [COSTS_BLOCKS.asphalt, COSTS_BLOCKS.concrete].includes(test);

export function getCostsBlockName(costsBlock: JOB_DAY_COSTS_BLOCKS): string {
	switch (costsBlock) {
		case COSTS_BLOCKS.asphalt:
			return 'Asphalt';
		case COSTS_BLOCKS.tac:
			return 'Tac';
		case COSTS_BLOCKS.trucking:
			return 'Trucking';
		case COSTS_BLOCKS.dump:
			return 'Dump Fees';
		case COSTS_BLOCKS.equip_delivery:
			return 'Equip delivery';
		case COSTS_BLOCKS.milling:
			return 'Milling';
		case COSTS_BLOCKS.crackfill:
			return 'Crackfill';
		case COSTS_BLOCKS.sealer:
			return 'Sealer';
		case COSTS_BLOCKS.mastic:
			return 'Mastic';
		case COSTS_BLOCKS.stone:
			return 'Stone';
		case COSTS_BLOCKS.concrete:
			return 'Concrete';
		case COSTS_BLOCKS.concrete_rebar:
			return 'Concrete Rebar';
		case COSTS_BLOCKS.labor:
			return 'Labor';
		case COSTS_BLOCKS.add_costs:
			return 'Additional Costs';
		case COSTS_BLOCKS.stripings:
			return 'Stripings';
		case COSTS_BLOCKS.equipment:
			return 'Equipment';
		case COSTS_BLOCKS.subcontractors:
			return 'Subcontractors';
		case COSTS_BLOCKS.warranty:
			return 'Warranty';
		case COSTS_BLOCKS.signage:
			return 'Signage';
		case COSTS_BLOCKS.thermo:
			return 'Thermo';
		case COSTS_BLOCKS.cement:
			return 'Cement';
		case DAY_COSTS_BLOCKS.overhead:
			return 'Overhead';
		default:
			return 'Unknown';
	}
}
export type BlockMetaData = AreaCalculatorMeta;

export interface GeneralBlockData {
	meta?: BlockMetaData;
}

export interface BlockData extends GeneralBlockData {
	sourceId?: number;
	sourceLabel?: string;
}

export interface AreaCalculatorMeta {
	areaCalculator?: {
		[key: string]: {
			//name of the block's data property to apply to
			rows: {
				width: number;
				length: number;
				diameter: number;
				area?: number;
			}[];
		};
	};
	[key: string]: any;
}

export function isAreaCalculatorMeta(object: BlockMetaData): object is AreaCalculatorMeta {
	return object && 'areaCalculator' in object;
}

export interface TypedSourceBlockData extends BlockData {
	sourceType?: string;
}

export interface MultiItemsBlockData<T> extends GeneralBlockData {
	items: T[];
}

export function isMultiItemsBlockData(object: any): object is MultiItemsBlockData<any> {
	return 'items' in object && Array.isArray(object.items);
}

export interface PlantBlockData {
	plant?: {
		address: Address;
		company: string;
		name: string;
	};
}

export interface TacData extends BlockData {
	area: number;
	isOverride: boolean;
	rate: number;
	factor: number;
	//calculated
	amount: number;
}

export interface LaborDataItem extends BlockData {
	personsOnCrew: number;
	totalDays: number;
	hoursPerDay: number;
	rate: number;
	//calculated
	totalHours?: number;
}

export interface MillingData extends BlockData {
	totalDays: number;
	hoursPerDay: number;
	rate: number;
	//calculated
	totalHours?: number;
}

export interface LaborData extends BlockData, MultiItemsBlockData<LaborDataItem> {}

export interface TruckingData extends BlockData {
	tons: number;
	days: number;
	hoursPerTruckPerDay: number;
	addHoursPerTruck: number;
	tonsPerLoad: number;
	hoursPerLoad: number;
	isOverride: boolean;
	rate: number;
	//calculated
	truckLoads?: number;
	loadHours?: number;
	trucksCount?: number;
	additionalHours?: number;
}

export interface EquipDeliveryDataItem {
	sourceId?: number;
	name: string;
	amount: number;
	rate: number;
}

export interface EquipDeliveryData extends MultiItemsBlockData<EquipDeliveryDataItem> {}
export interface CrackfillData extends BlockData {
	length: number;
	yield: number;
	minimum: number;
	isOverride: boolean;
	rate: number;
	//calculated
	amount: number;
}
export interface SealerData extends BlockData {
	area: number;
	factor: number;
	minimum: number;
	isOverride: boolean;
	rate: number;
	//calculated
	amount: number;
}
export interface MasticData extends BlockData {
	length: number;
	width: number;
	depth: number;
	factor: number;
	isOverride: boolean;
	rate: number;
	//calculated
	amount: number;
}

export interface GeneralEquipmentDataItem {
	sourceId?: number;
	name: string;
	unit: string;
	amount: number;
	rate: number;
}

export interface AddCostsDataItem extends GeneralEquipmentDataItem {
	category: ADD_COST_CATEGORIES;
}

export interface SubcontractorsDataItem extends GeneralEquipmentDataItem {
	address?: Address;
}

export interface SignageDataItem extends GeneralEquipmentDataItem {
	factor?: number;
	factoredAmount?: number;
}

export interface StripingsDataItem extends SignageDataItem {
	color?: string;
}

export type EquipmentData = MultiItemsBlockData<GeneralEquipmentDataItem>;

export type AddCostsData = MultiItemsBlockData<AddCostsDataItem>;
export type SubcontractorsData = MultiItemsBlockData<SubcontractorsDataItem>;
export type WarrantyData = EquipmentData;
export type StripingsData = MultiItemsBlockData<StripingsDataItem>;
export type SignageData = MultiItemsBlockData<SignageDataItem>;
export type ThermoData = SignageData;

export interface AsphaltData extends TypedSourceBlockData, PlantBlockData {
	area: number;
	isExcludedFromGeoArea: boolean;
	factor: number;
	depth: number;
	isOverride: boolean;
	rate: number;
	fees: {
		name: string;
		enabled: boolean;
		value: number;
	}[];
	//calculated
	amount: number;
}
export interface StoneData extends TypedSourceBlockData, PlantBlockData {
	area: number;
	factor: number;
	depth: number;
	isOverride: boolean;
	rate: number;
	deliveryRate: number;
	undercutDepth: number;
	//calculated
	amount: number;
	undercutAmount?: number;
}

export interface CementData extends TypedSourceBlockData, PlantBlockData {
	area: number;
	factor: number;
	depth: number;
	isOverride: boolean;
	rate: number;
	fees: {
		name: string;
		enabled: boolean;
		value: number;
	}[];
	//calculated
	amount: number;
}

export interface DumpData extends TypedSourceBlockData, PlantBlockData {
	tons: number;
	tonsPerLoad: number;
	isOverride: boolean;
	rate: number;
	//calculated
	amount?: number;
}
export interface ConcreteRebar {
	sourceId: number;
	sourceName: string;
	sourceSize: number;
	sourceLength: number;
	widthFt: number;
	widthIn: number;
	lengthFt: number;
	lengthIn: number;
	spacingIn: number;
	isSeparateHeightSpacing: boolean;
	spacingHeightIn: number;
	edgeClearanceIn: number;
	overlap: number;
	totalBarLength: number;
	amount: number;
	rate: number;
}

export interface ConcreteData extends TypedSourceBlockData, PlantBlockData {
	standardId: number;
	standardIdLabel: string;
	areaUnit: string;
	isOverride: boolean;
	factor: number;
	area: number;
	isExcludedFromGeoArea: boolean;
	rateWithoutFees: number;
	standardType: CONCRETE_TYPES;
	fees: {
		name: string;
		enabled: boolean;
		value: number;
	}[];
	isRebar: boolean;
	rebar: ConcreteRebar;
	isWire: boolean;
	wire: number;
	//calculated
	yards?: number;
	rate?: number;
	depth?: number;
}

export function removeIsSynchronized(data: any, costsBlock: COSTS_BLOCKS) {
	switch (costsBlock) {
		case COSTS_BLOCKS.equip_delivery:
		case COSTS_BLOCKS.labor:
		case COSTS_BLOCKS.stripings:
		case COSTS_BLOCKS.thermo:
		case COSTS_BLOCKS.signage:
		case COSTS_BLOCKS.warranty:
		case COSTS_BLOCKS.equipment:
		case COSTS_BLOCKS.subcontractors:
		case COSTS_BLOCKS.add_costs: {
			data?.items?.forEach((i) => {
				delete i.isSynchronized;
			});
			break;
		}
		case COSTS_BLOCKS.tac:
		case COSTS_BLOCKS.trucking:
		case COSTS_BLOCKS.dump:
		case COSTS_BLOCKS.milling:
		case COSTS_BLOCKS.crackfill:
		case COSTS_BLOCKS.sealer:
		case COSTS_BLOCKS.mastic:
		case COSTS_BLOCKS.concrete:
		case COSTS_BLOCKS.stone:
		case COSTS_BLOCKS.asphalt:
		case COSTS_BLOCKS.cement: {
			delete data.isSynchronized;
		}
	}
}

export function clearBlockAmount(block: BidItemBlock) {
	block.total = 0;
	switch (block.costsBlock) {
		case COSTS_BLOCKS.asphalt: {
			const data = block.data as AsphaltData;
			data.amount = 0;
			data.area = 0;
			data.depth = 0;
			data.isOverride = false;
			data.fees = [];
			break;
		}
		case COSTS_BLOCKS.cement: {
			const data = block.data as CementData;
			data.amount = 0;
			data.area = 0;
			data.depth = 0;
			data.isOverride = false;
			data.fees = [];
			break;
		}
		case COSTS_BLOCKS.tac: {
			const data = block.data as TacData;
			data.area = 0;
			data.amount = 0;
			data.isOverride = false;
			break;
		}
		case COSTS_BLOCKS.trucking: {
			const data = block.data as TruckingData;
			data.tons = 0;
			data.hoursPerTruckPerDay = 0;
			data.addHoursPerTruck = 0;
			data.tonsPerLoad = 0;
			data.hoursPerLoad = 0;
			data.isOverride = false;
			data.days = 1;
			data.trucksCount = 1;
			break;
		}
		case COSTS_BLOCKS.dump: {
			const data = block.data as DumpData;
			data.amount = 0;
			data.tons = 0;
			data.tonsPerLoad = 0;
			data.isOverride = false;
			break;
		}
		case COSTS_BLOCKS.milling: {
			const data = block.data as MillingData;
			data.totalDays = 0;
			data.hoursPerDay = 0;
			break;
		}
		case COSTS_BLOCKS.crackfill: {
			const data = block.data as CrackfillData;
			data.amount = 0;
			data.length = 0;
			data.isOverride = false;
			break;
		}
		case COSTS_BLOCKS.sealer: {
			const data = block.data as SealerData;
			data.amount = 0;
			data.area = 0;
			data.isOverride = false;
			break;
		}
		case COSTS_BLOCKS.mastic: {
			const data = block.data as MasticData;
			data.amount = 0;
			data.length = 0;
			data.width = 0;
			data.depth = 0;
			data.isOverride = false;
			break;
		}
		case COSTS_BLOCKS.stone: {
			const data = block.data as StoneData;
			data.amount = 0;
			data.area = 0;
			data.depth = 0;
			data.isOverride = false;
			data.deliveryRate = 0;
			data.undercutDepth = 0;
			data.undercutAmount = 0;
			break;
		}
		case COSTS_BLOCKS.concrete: {
			const data = block.data as ConcreteData;
			data.yards = 0;
			data.area = 0;
			data.depth = 0;
			data.isOverride = false;
			data.fees = [];
			break;
		}
		case COSTS_BLOCKS.labor: {
			block.data.items = [];
			break;
		}
		case COSTS_BLOCKS.add_costs: {
			block.data.items?.forEach((i) => {
				i.amount = 0;
			});
			break;
		}
		case COSTS_BLOCKS.equip_delivery:
		case COSTS_BLOCKS.equipment:
		case COSTS_BLOCKS.warranty:
		case COSTS_BLOCKS.subcontractors: {
			block.data.items?.forEach((i) => {
				i.amount = 0;
			});
			break;
		}
		case COSTS_BLOCKS.signage:
		case COSTS_BLOCKS.thermo:
		case COSTS_BLOCKS.stripings: {
			block.data.items?.forEach((i) => {
				i.factor = 0;
				i.amount = 0;
			});
			break;
		}
		default:
			break;
	}
}

export function clearBlockRate(block: BidItemBlock) {
	block.total = 0;
	switch (block.costsBlock) {
		case COSTS_BLOCKS.asphalt: {
			const data = block.data as AsphaltData;
			data.rate = 0;
			data.fees = [];
			break;
		}
		case COSTS_BLOCKS.cement: {
			const data = block.data as CementData;
			data.rate = 0;
			data.fees = [];
			break;
		}
		case COSTS_BLOCKS.tac: {
			const data = block.data as TacData;
			data.rate = 0;
			break;
		}
		case COSTS_BLOCKS.trucking: {
			const data = block.data as TruckingData;
			data.rate = 0;
			break;
		}
		case COSTS_BLOCKS.dump: {
			const data = block.data as DumpData;
			data.rate = 0;
			break;
		}
		case COSTS_BLOCKS.milling: {
			const data = block.data as MillingData;
			data.rate = 0;
			break;
		}
		case COSTS_BLOCKS.crackfill: {
			const data = block.data as CrackfillData;
			data.rate = 0;
			break;
		}
		case COSTS_BLOCKS.sealer: {
			const data = block.data as SealerData;
			data.rate = 0;
			data.minimum = 0;
			break;
		}
		case COSTS_BLOCKS.mastic: {
			const data = block.data as MasticData;
			data.rate = 0;
			break;
		}
		case COSTS_BLOCKS.stone: {
			const data = block.data as StoneData;
			data.rate = 0;
			data.deliveryRate = 0;
			break;
		}
		case COSTS_BLOCKS.concrete: {
			const data = block.data as ConcreteData;
			data.rate = 0;
			data.rateWithoutFees = 0;
			if (data.rebar) {
				data.rebar.rate = 0;
			}
			data.fees = [];
			break;
		}
		case COSTS_BLOCKS.labor:
		case COSTS_BLOCKS.add_costs:
		case COSTS_BLOCKS.equip_delivery:
		case COSTS_BLOCKS.equipment:
		case COSTS_BLOCKS.warranty:
		case COSTS_BLOCKS.subcontractors:
		case COSTS_BLOCKS.signage:
		case COSTS_BLOCKS.thermo:
		case COSTS_BLOCKS.stripings: {
			block.data.items?.forEach((i) => {
				i.rate = 0;
			});
			break;
		}
	}
}

export const getCostUnit = (costsBlock: COSTS_BLOCKS) => {
	switch (costsBlock) {
		case COSTS_BLOCKS.labor:
		case COSTS_BLOCKS.trucking: {
			return  'Hours'
		}
		case COSTS_BLOCKS.equip_delivery:{
			return  'Deliveries'
		}
		case COSTS_BLOCKS.concrete_rebar:{
			return 'Each'
		}
		case COSTS_BLOCKS.concrete: {
			return 'Yards'
		}
		case COSTS_BLOCKS.asphalt: {
			return 'Tons'
		}
		case COSTS_BLOCKS.stone:
		case COSTS_BLOCKS.dump:
		case COSTS_BLOCKS.cement: {
			return 'Amount'
		}
	}
	return null;
}
