import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { AdminDto } from '../../../../../pss2-shared/apiModels/admin/index';
import { PSS_ADMIN_PERMISSION_ID } from '../../../../../pss2-shared/adminPermissions';
import { AdminAuthState } from '../store/admin.store';
import { ProtectedModule } from '../protected.module';

@Injectable({
	providedIn: ProtectedModule,
})
export class AdminPermissionsService {
	public constructor(
		private permissionsService: NgxPermissionsService,
		private store: Store
	) {}

	public loadPermissions(admin: AdminDto) {
		this.permissionsService.loadPermissions(admin.permissions);
	}

	public flushPermissions() {
		this.permissionsService.flushPermissions();
	}

	public async hasPermission(permission: PSS_ADMIN_PERMISSION_ID | PSS_ADMIN_PERMISSION_ID[]): Promise<boolean> {
		const admin = await lastValueFrom(this.store.selectOnce(AdminAuthState.admin));

		return admin.isSuperAdmin ? true : this.permissionsService.hasPermission(permission);
	}
}
