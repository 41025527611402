<div class="clr-col-auto">
	<button
		type="button"
		(click)="gridSettingsService.openColumnsOrderDlg()"
		class="btn btn-link btn-icon btn-columns-order"
		aria-label="Column order settings">
		<clr-icon shape="cog"></clr-icon>
	</button>
</div>
<div class="clr-col-auto links-in-new-tabs-column" title="{{'Open links in new tabs'|translate}}">
	<clr-icon shape="host-group"></clr-icon>
	<input type="checkbox" [(ngModel)]="linksInNewTabsService.linksInNewTabs" />
</div>
<div class="clr-col clr-row">
	<ng-content></ng-content>
</div>
