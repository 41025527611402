<div class="modal">
	<div [style.width.px]="inputs.widthOverride" class="modal-dialog fadeDown in" [class.modal-sm]="!inputs.widthOverride" role="dialog" aria-hidden="true">
		<div class="modal-content">
			<div class="modal-header">
				<!-- <button aria-label="Close" class="close" type="button" (click)="cancel()">
					<clr-icon aria-hidden="true" shape="close"></clr-icon>
				</button> -->
				<h3 class="modal-title clr-row">
					<clr-icon [attr.shape]="inputs.iconShape" size="24"></clr-icon>
					<span class="clr-col-10">{{inputs.title | translate}}</span>
				</h3>
			</div>
			<div class="modal-body">
				<p>{{inputs.message | translate}}</p>
			</div>
			<div class="modal-footer">
				<button class="btn btn-outline" type="button" (click)="cancel()">{{inputs.cancelBtn | translate}}</button>
				<button class="btn" [ngClass]="{'btn-danger': inputs.isDanger, 'btn-primary': !inputs.isDanger}" type="button" (click)="ok()">
					{{inputs.okBtn | translate}}
				</button>
			</div>
		</div>
	</div>
</div>
