import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthPageState } from '../../store/auth-page.store';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { VerifyOtpCode } from '../../store/auth.actions';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import { AuthHttpService } from '../../services/auth-http.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-verify-otp',
	templateUrl: './verify-otp.component.html',
	styleUrls: ['./verify-otp.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyOtpComponent implements OnInit, OnDestroy {
	@ViewChild(ClrForm, { static: true })
	clrForm: ClrForm;

	error$;

	submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
	sendCodeBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	subject$ = new Subject();

	form: UntypedFormGroup = new UntypedFormGroup({
		token: new UntypedFormControl(null, [Validators.required]),
		rememberMe: new UntypedFormControl(false),
	});

	get password() {
		return this.form.value.password;
	}

	isCodeSent = false;

	constructor(
		private store: Store,
		private toaster: ToastrService,
		private cd: ChangeDetectorRef,
		private authHttpService: AuthHttpService,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		this.error$ = this.store.select(AuthPageState.error);
		this.error$.pipe(takeUntil(this.subject$)).subscribe(() => {
			this.submitBtnState = ClrLoadingState.DEFAULT;
			this.cd.markForCheck();
		});
	}

	ngOnDestroy() {
		this.subject$.complete();
	}

	async resendCode() {
		try {
			this.sendCodeBtnState = ClrLoadingState.LOADING;
			await lastValueFrom(this.authHttpService.requestOtpCode());
			this.isCodeSent = true;
			this.cd.markForCheck();
		} catch (e) {
			this.toaster.error(e?.error?.error);
		}
		this.sendCodeBtnState = ClrLoadingState.DEFAULT;
		this.cd.markForCheck();
	}

	async submit() {
		if (!this.form.valid) {
			this.clrForm.markAsTouched();
			this.toaster.error('Please fill all required fields!');
			return;
		}
		this.submitBtnState = ClrLoadingState.LOADING;
		this.cd.markForCheck();
		const date = new Date();
		const data = this.form.value;
		date.setHours(0, 0, 0, 0);
		data.localTime = date.toISOString();
		this.store.dispatch(new VerifyOtpCode({ data, returnUrl: this.route.snapshot.queryParams.returnUrl }));
	}
}
