import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { omit } from 'ramda';

@Injectable({
	providedIn: 'root',
})
export class HttpCleanNullIdInterceptor implements HttpInterceptor {
	toastrThrottle = false;

	constructor() {}

	/**
	 * Removes invalid id from body (like null or 0)
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler) {
		if (request.method === 'POST' && request.body && 'id' in request.body && !request.body.id) {
			const newBody = omit(['id'], request.body);
			// clone request and set its body
			const newReq = request.clone({ body: newBody });
			// send the cloned request to the next handler.
			return next.handle(newReq);
		}
		return next.handle(request);
	}
}
