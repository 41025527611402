<quill-editor [formControl]="control" [modules]="modules">
	<div quill-editor-toolbar [ngClass]="{'hidden-toolbar': hideToolBar}">
		<span class="ql-formats">
			<button title="Bold" class="ql-bold"></button>
			<button title="Italic" class="ql-italic"></button>
			<button title="Underline" class="ql-underline"></button>
			<button title="Strike" class="ql-strike"></button>
		</span>
		<span class="ql-formats">
			<button title="Ordered List" class="ql-list" value="ordered"></button>
			<button title="Bullet List" class="ql-list" value="bullet"></button>
			<select class="ql-align">
				<option selected></option>
				<option title="Center" value="center"></option>
				<option title="Right" value="right"></option>
				<option title="Justify" value="justify"></option>
			</select>
		</span>
		<span class="ql-formats">
			<button title="Subscript" class="ql-script" value="sub"></button>
			<button title="Superscript" class="ql-script" value="super"></button>
		</span>
		<span class="ql-formats">
			<button title="Increase Indent" class="ql-indent" value="-1"></button>
			<button title="Decrease Indent" class="ql-indent" value="+1"></button>
		</span>
		<span class="ql-formats">
			<select title="Font" class="ql-font">
				<option selected></option>
				<option value="serif"></option>
				<option value="monospace"></option>
			</select>
			<select title="Font Size" class="ql-size">
				<option *ngFor="let size of sizes" [selected]="size==='20pt'" value="{{size}}">{{size}}</option>
			</select>
		</span>
		<span class="ql-formats">
			<select title="Text color" class="ql-color"></select>
			<select title="Background Color" class="ql-background"></select>
		</span>
		<span class="ql-formats">
			<button title="Clean Formatting" class="ql-clean"></button>
		</span>
		<span class="ql-formats">
			<button title="Insert Image" class="ql-image"></button>
			<button title="Hyperlink" class="ql-link"></button>
			<select *ngIf="placeholders" title="Insert Placeholder" class="ql-placeholder">
				<option *ngFor="let placeholder of placeholders" value="{{placeholder.id}}">{{placeholder.label}}</option>
			</select>
		</span>
	</div>
</quill-editor>
