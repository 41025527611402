<div class="login-wrapper">
	<form clrForm class="login" [formGroup]="form" (ngSubmit)="submit()">
		<section class="title">
			<h3 class="welcome">Reset password to</h3>
			Pavement Software Solutions
			<h5 class="hint">Type in email to send instruction to</h5>
		</section>
		<div class="login-group">
			<clr-input-container>
				<input type="email" clrInput placeholder="Email" formControlName="email" />
				<clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
			</clr-input-container>
			<div class="error active" *ngIf="error$ | async as error">
				{{ error.error }}
			</div>
			<button class="btn btn-primary" type="submit">Send</button>
		</div>
	</form>
</div>
