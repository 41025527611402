import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetDefaultState, SetTitle } from './main-app.actions';
import { Injectable } from '@angular/core';

export class MainAppStateModel {
	title: string;
}

@State<MainAppStateModel>({
	name: 'main',
	defaults: {
		title: '',
	},
})
@Injectable()
export class MainAppState {
	@Selector()
	static title(state: MainAppStateModel) {
		return state.title;
	}

	constructor() {}

	@Action(SetTitle)
	login(ctx: StateContext<MainAppStateModel>, { payload }: SetTitle) {
		ctx.patchState({ title: payload.title });
	}

	@Action(SetDefaultState)
	setDefault(ctx: StateContext<MainAppStateModel>) {
		return ctx.setState({
			title: '',
		});
	}
}
