import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, lastValueFrom, of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { indexBy } from 'ramda';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/auth/store/auth.store';
import { PSS_PERMISSION_ID } from '@pss2-shared/permissions';
import {
	FINAL_PAVE_AMERICA_CLIENT_REQUEST_STATUSES,
	PaveAmericaClientRequestStatus,
	paveAmericaClientRequestStatusOptions,
	paveAmericaClientRequestStatusToLabel,
} from '@pss2-shared/paveAmerica';
import { PaveAmericaHttpService } from 'src/app/crm/services/pave-america-http.service';
import { GeneralTableComponent } from 'src/app/shared/general-table.component';
import { PersistenceService } from 'src/app/shared/persistence.service';
import { ResourceHttpService } from 'src/app/shared/resource-http.service';
import { PopupDlgOverlayRef, PopupDlgService } from 'src/app/shared/popup-dlg.service';
import {
	PaveAmericaClientRequestDlgComponent,
	PaveAmericaClientRequestDlgComponentOutput,
} from '../../pave-america-client-request-dlg/pave-america-client-request-dlg.component';
import { LoadActivePaveAmericaClientRequestsCount } from 'src/app/crm/store/pave-america-client-requests.actions';
import { ClrLoadingState } from '@clr/angular';
import { ColumnInfo, GRID_SETTINGS_SERVICE_CONFIG_TOKEN, GridSettingsService, GridSettingsServiceConfig } from 'src/app/shared/grid-settings.service';

const columnsInfo: ColumnInfo[] = [
	{ key: 'name', label: 'Name' },
	{ key: 'requestedAt', label: 'Requested At' },
	{ key: 'requester', label: 'Requester' },
	{ key: 'inboundReviewer', label: 'Inbound Reviewer' },
	{ key: 'status', label: 'Status' },
	{ key: 'view', label: 'View' },
];

interface User {
	id: number;
	name: string;
	permissions: PSS_PERMISSION_ID[];
}

@Component({
	selector: 'app-pave-america-client-requests',
	templateUrl: './pave-america-client-requests.component.html',
	styleUrls: ['./pave-america-client-requests.component.scss'],
	providers: [
		GridSettingsService,
		{
			provide: GRID_SETTINGS_SERVICE_CONFIG_TOKEN,
			useValue: {
				gridName: 'pave-america-client-requests',
				columnsInfo,
			} satisfies GridSettingsServiceConfig,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaveAmericaClientRequestsComponent extends GeneralTableComponent {
	@Input()
	clientId: number;

	@Input()
	selectedStatuses: PaveAmericaClientRequestStatus[] = [];

	users: User[] = [];
	inboundReviewers: User[] = [];
	usersIndex: Record<number, User> = {};

	statusToLabel = paveAmericaClientRequestStatusToLabel;
	statusOptions = paveAmericaClientRequestStatusOptions;

	viewLoadingState: ClrLoadingState;

	private wrappedService = {
		getAll: (params) => {
			return this.paveAmericaHttpService.getPaveAmericaClientRequests(params) as any;
		},
	} as ResourceHttpService;

	public override get httpService() {
		return this.wrappedService;
	}

	public override get additionalParams() {
		return of({ ...(this.clientId && { clientId: this.clientId }) });
	}

	constructor(
		cd: ChangeDetectorRef,
		route: ActivatedRoute,
		router: Router,
		persistanceService: PersistenceService,
		private toastrService: ToastrService,
		private store: Store,
		private paveAmericaHttpService: PaveAmericaHttpService,
		private popupDlgService: PopupDlgService
	) {
		super(cd, route, persistanceService, router);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.paveAmericaHttpService
			.getUsers()
			.pipe(
				tap((users) => {
					users.sort((a, b) => Number(b.isActive) - Number(a.isActive));
					[this.users, this.inboundReviewers] = users.reduce(
						(result, user) => {
							if (user.permissions.includes(PSS_PERMISSION_ID.crm_access_ui)) {
								result[0].push(user);
							}
							if (user.permissions.includes(PSS_PERMISSION_ID.review_pave_america_client_requests)) {
								result[1].push(user);
							}

							return result;
						},
						[[], []]
					);

					this.usersIndex = indexBy((user) => user.id, users);

					this.cd.markForCheck();
				})
			)
			.subscribe();
	}

	popupDlg: PopupDlgOverlayRef;

	killPopupDlg() {
		if (this.popupDlg) {
			this.popupDlg.destroy();
		}
		this.popupDlg = null;
	}

	async viewRequest(id: number) {
		this.killPopupDlg();
		const user = this.store.selectSnapshot(AuthState.user);
		try {
			this.viewLoadingState = ClrLoadingState.LOADING;
			this.cd.markForCheck();

			const paveAmericaClientRequest = await lastValueFrom(this.paveAmericaHttpService.getPaveAmericaClientRequest(id));
			this.viewLoadingState = ClrLoadingState.DEFAULT;
			this.cd.markForCheck();

			const canApprove =
				paveAmericaClientRequest.status === PaveAmericaClientRequestStatus.in_inbound_review &&
				user.permissions.has(PSS_PERMISSION_ID.review_pave_america_client_requests);
			const canUpdate =
				(!FINAL_PAVE_AMERICA_CLIENT_REQUEST_STATUSES.includes(paveAmericaClientRequest.status) &&
					user.permissions.has(PSS_PERMISSION_ID.review_pave_america_client_requests)) ||
				(paveAmericaClientRequest.status === PaveAmericaClientRequestStatus.in_inbound_review && paveAmericaClientRequest.requesterId === user.id);

			this.popupDlg = this.popupDlgService.open({
				componentClass: PaveAmericaClientRequestDlgComponent,
				backdropClose: false,
				hasBackdrop: false,
				data: {
					mode: 'view',
					paveAmericaClientRequest,
					canApprove,
					canUpdate,
				},
			});

			this.popupDlg.close$
				.pipe(
					filter(Boolean),
					switchMap((output: PaveAmericaClientRequestDlgComponentOutput) => {
						switch (output.type) {
							case 'save':
								return this.paveAmericaHttpService.updatePaveAmericaClientRequest(id, output.payload);
							case 'approve':
								return this.paveAmericaHttpService.approvePaveAmericaClientRequest(id, output.payload);
							case 'decline':
								return this.paveAmericaHttpService.declinePaveAmericaClientRequest(id);
						}
					}),
					tap(() => {
						this.reloadItems(this.prevGridState);
						if (user.permissions.has(PSS_PERMISSION_ID.review_pave_america_client_requests)) {
							this.store.dispatch(new LoadActivePaveAmericaClientRequestsCount());
						}
					}),
					catchError((e) => {
						this.toastrService.error(e.error ? e.error.error : e.statusText);

						return EMPTY;
					})
				)
				.subscribe();
		} catch (e) {
			if (e) {
				this.toastrService.error(e.error.error);
			}
			this.viewLoadingState = ClrLoadingState.DEFAULT;
			this.cd.markForCheck();
		}
	}
}
