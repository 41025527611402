import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import zxcvbn from 'zxcvbn';

@Component({
	selector: 'app-password-strength-meter',
	templateUrl: './password-strength-meter.component.html',
	styleUrls: ['./password-strength-meter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordStrengthMeterComponent implements OnChanges {
	@Input()
	password: string;

	@Input()
	userInputs: string[];

	passwordStrength: { score: number };
	passwordStrengthTitles = ['Very Weak', 'Weak', 'Medium', 'Strong', 'Very Strong'];
	passwordStrengthColors = ['#db2100', '#db2100', '#F8CF2A', '#73DC1E', '#73DC1E'];

	ngOnChanges() {
		if (this.password) {
			this.passwordStrength = zxcvbn(this.password, this.userInputs);
		} else {
			this.passwordStrength = null;
		}
	}

	passwordStrengthStyle() {
		if (this.passwordStrength?.score == null) {
			return { width: '0%' };
		}

		return { background: this.passwordStrengthColors[this.passwordStrength.score], width: `${100 * (this.passwordStrength.score / 4)}%` };
	}
}
