import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { ChatUserDto } from '@pss2-shared/chatTypes';
import { Subject, Subscription } from 'rxjs';
import { ConnectToServer, DisconnectFromServer } from '../../store/chat.actions';
// import { ChatContact, ChatMessage, ChatUserDto } from '@pss2-shared/chatTypes';
// import { Observable, of, Subject, Subscription, zip } from 'rxjs';
// import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
// import { ConnectToServer, DisconnectFromServer, MarkChatMessagesRead, PullChatHistory, PullChatOnlineState, SendMessage } from '../../store/chat.actions';
// import { ChatState } from '../../store/chat.store';

// import { v4 as uuid } from 'uuid';

// function convertContact(user: ChatContact): ParticipantResponse {
// 	return {
// 		participant: {
// 			participantType: ChatParticipantType.User,
// 			id: user.id,
// 			displayName: user.name,
// 			// avatar: "http://pm1.narvii.com/6201/dfe7ad75cd32130a5c844d58315cbca02fe5b804_128.jpg",
// 			avatar: null,
// 			status: user.online ? ChatParticipantStatus.Online : ChatParticipantStatus.Offline,
// 		},
// 		metadata: {
// 			totalUnreadMessages: user.newMessages,
// 		},
// 	};
// }

// function backToUiMessage(msg: ChatMessage): Message {
// 	return {
// 		type: MessageType.Text,
// 		fromId: msg.from,
// 		toId: msg.to,
// 		message: msg.text,
// 		dateSent: msg.sentAt,
// 		dateSeen: msg.read ? msg.sentAt : null,
// 	};
// }

// function uiToBackMessage(msg: Message): ChatMessage {
// 	return {
// 		from: msg.fromId,
// 		to: msg.toId,
// 		text: msg.message,
// 		sentAt: msg.dateSent,
// 		uuid: uuid(),
// 		read: false,
// 	};
// }

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit, OnDestroy {
	subscriptions: Subscription;

	user: ChatUserDto;
	// contacts: ParticipantResponse[] = [];

	breaker = new Subject();

	constructor(
		private cd: ChangeDetectorRef,
		private store: Store
	) {
		// store.select(ChatState.contacts).subscribe(contacts => {
		// 	this.contacts = this.user ? contacts.filter(user => user.id !== this.user.userId).map(convertContact) : [];
		// });

		// store.select(ChatState.user).pipe(takeUntil(this.breaker)).subscribe(user => {
		// 	this.user = user;
		// 	if (user) {
		// 		store.dispatch(new PullChatOnlineState());
		// 	}
		// 	this.cd.markForCheck();
		// });

		// store.select(ChatState.incomingMessage).pipe(
		// 	takeUntil(this.breaker),
		// 	filter(m => m != null),
		// 	map(message => {
		// 		const participantResponse = this.contacts.find(c => c.participant.id === message.from);
		// 		return {
		// 			participant: participantResponse.participant,
		// 			message: backToUiMessage(message),
		// 		};
		// 	})
		// ).subscribe(({ message, participant }) => {
		// 	this.cd.markForCheck();
		// });

		store.dispatch(new ConnectToServer());
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.breaker.complete();
		this.store.dispatch(new DisconnectFromServer({ code: 200, reason: 'closed' }));
	}

	// chatOpened(participant: IChatParticipant) {
	// 	// console.log('opened', participant.id);
	// }

	// chatClosed(participant: IChatParticipant) {
	// 	// console.log('closed', participant.id);
	// }

	// listFriends(): Observable<ParticipantResponse[]> {
	// 	return of(this.contacts);
	// }

	// getMessageHistory( destinataryId: any ): Observable<Message[]> {
	// 	return this.store.dispatch(new PullChatHistory({ page: 0, pageSize: 50, partnerId: destinataryId })).pipe(
	// 		catchError(e => {
	// 			return of();
	// 		}),
	// 		map(() => this.store.selectSnapshot(ChatState.messages)
	// 			.filter(m => m.to === destinataryId || m.from === destinataryId)
	// 			.map(backToUiMessage)
	// 		),
	// 		tap( ( messages: Message[] ) => {
	// 			this.cd.markForCheck();

	// 			if ( !messages.length ) return;
	// 			let upto: Date;
	// 			messages.forEach( msg => {
	// 				const d = new Date( msg.dateSent );
	// 				if ( !upto || upto < d ) {
	// 					upto = d;
	// 				}
	// 			} );
	// 			this.store.dispatch( [new MarkChatMessagesRead( { partnerId: destinataryId, upto } ), new PullChatOnlineState()]);
	// 		}),
	// 	);
	// }

	// sendMessage( message: Message ): void {
	// 	this.store.dispatch(new SendMessage(uiToBackMessage(message)));
	// }
}
