import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthPageComponent } from './containers/auth-page/auth-page.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ClarityModule } from '@clr/angular';
import { RegistrationConfirmationComponent } from './components/registration-confirmation/registration-confirmation.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from './store/auth.store';
import { AuthPageState } from './store/auth-page.store';
import { RegistrationCompletedComponent } from './components/registration-completed/registration-completed.component';
import { CompanyNonActiveComponent } from './components/company-non-active/company-non-active.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordSentComponent } from './components/reset-password-sent/reset-password-sent.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { SharedModule } from '../shared/shared.module';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';

@NgModule({
	providers: [],
	declarations: [
		AuthPageComponent,
		LoginComponent,
		RegisterComponent,
		RegistrationConfirmationComponent,
		LogoutComponent,
		RegistrationCompletedComponent,
		CompanyNonActiveComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		ResetPasswordSentComponent,
		UpdatePasswordComponent,
		VerifyOtpComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		ClarityModule,
		AuthRoutingModule,
		NgxsModule.forFeature([AuthState, AuthPageState]),
	],
})
export class AuthModule {}
