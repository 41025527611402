import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { HttpError403 } from '../auth/store/auth.actions';

@Injectable({
	providedIn: 'root',
})
export class Http403Interceptor implements HttpInterceptor {
	toastrThrottle = false;

	constructor(private store: Store) {}

	intercept(request: HttpRequest<any>, next: HttpHandler) {
		return next.handle(request).pipe(
			catchError((err) => {
				if (err.status === 403) this.showToast(err);
				return throwError(err);
			})
		);
	}

	showToast(err: any) {
		// only emit once per second or less
		if (this.toastrThrottle) {
			console.log('Ignored http error 403', err);
			return;
		}
		this.toastrThrottle = true;
		setTimeout(() => (this.toastrThrottle = false), 999);
		this.store.dispatch(new HttpError403(err));
	}
}
