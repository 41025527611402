import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import SignaturePad, { Options, PointGroup } from 'signature_pad';

export interface NgSignaturePadOptions extends Options {
	canvasHeight: number;
	canvasWidth: number;
}

@Component({
	templateUrl: './signature-pad.component.html',
	selector: 'app-signature-pad',
	styleUrls: ['./signature-pad.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignaturePadComponent implements AfterContentInit, OnDestroy {
	@Input() public options: NgSignaturePadOptions;
	@Output() onSave = new EventEmitter<string>();
	@Output() onCancel = new EventEmitter<void>();

	private signaturePad: SignaturePad;

	constructor(
		private elementRef: ElementRef,
		private cd: ChangeDetectorRef
	) {
		this.options = this.options || { canvasHeight: 200, canvasWidth: 450 };
	}

	public ngAfterContentInit(): void {
		const canvas: HTMLCanvasElement = this.getCanvas();
		if (this.options.canvasHeight) {
			canvas.height = this.options.canvasHeight;
		}

		if (this.options.canvasWidth) {
			canvas.width = this.options.canvasWidth;
		}

		this.signaturePad = new SignaturePad(canvas, this.options);
		this.signaturePad.addEventListener('endStroke', () => this.cd.markForCheck());
	}

	public ngOnDestroy(): void {
		const canvas: HTMLCanvasElement = this.getCanvas();
		canvas.width = 0;
		canvas.height = 0;
		this.signaturePad.off();
	}

	/**
	 * Returns signature image as an array of point groups
	 */
	public toData(): PointGroup[] {
		if (this.signaturePad) {
			return this.signaturePad.toData();
		} else {
			return [];
		}
	}

	/**
	 * Draws signature image from an array of point groups
	 */
	public fromData(points: Array<PointGroup>): void {
		this.signaturePad.fromData(points);
	}

	/**
	 * Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
	 */
	public toDataURL(imageType?: string, quality?: number): string {
		return this.signaturePad.toDataURL(imageType, quality); // save image as data URL
	}

	/**
	 * Draws signature image from data URL
	 */
	public fromDataURL(dataURL: string, options: { ratio?: number; width?: number; height?: number } = {}): void {
		// set default height and width on read data from URL
		if (!Object.prototype.hasOwnProperty.call(options, 'height') && this.options.canvasHeight) {
			options.height = this.options.canvasHeight;
		}
		if (!Object.prototype.hasOwnProperty.call(options, 'width') && this.options.canvasWidth) {
			options.width = this.options.canvasWidth;
		}
		this.signaturePad.fromDataURL(dataURL, options);
	}

	/**
	 * Clears the canvas
	 */
	clear(): void {
		this.signaturePad.clear();
	}

	get isEmpty() {
		return this.signaturePad.isEmpty();
	}

	public getCanvas(): HTMLCanvasElement {
		return this.elementRef.nativeElement.querySelector('canvas');
	}

	save() {
		this.onSave.emit(this.toDataURL());
	}

	cancel() {
		this.onCancel.emit();
	}
}
