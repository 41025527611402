import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, of, merge } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError, map, debounceTime, withLatestFrom } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SMART_SEARCH_IDS } from '../../../../../pss2-shared/sharedConstants';
import { addressToString } from '../../../../../pss2-shared/utilFuncs';
import { SmartSearchHttpService, SmartSearchResult } from 'src/app/smart-search-http.service';

const smartSearchGroupCaptions = {
	[SMART_SEARCH_IDS.OPPORTUNITIES]: 'Opportunities',
	[SMART_SEARCH_IDS.BIDS]: 'Bids',
	[SMART_SEARCH_IDS.JOBS]: 'Jobs',
	[SMART_SEARCH_IDS.CLIENTS]: 'Clients',
	[SMART_SEARCH_IDS.PROPERTIES]: 'Properties',
	[SMART_SEARCH_IDS.MANAGERS]: 'Managers',
};

const smartSearchGroupIcons = {
	[SMART_SEARCH_IDS.OPPORTUNITIES]: 'plus-circle',
	[SMART_SEARCH_IDS.BIDS]: 'clipboard-list',
	[SMART_SEARCH_IDS.JOBS]: 'wrench',
	[SMART_SEARCH_IDS.CLIENTS]: 'users',
	[SMART_SEARCH_IDS.PROPERTIES]: 'building',
	[SMART_SEARCH_IDS.MANAGERS]: 'user-tie',
};

@Component({
	selector: 'app-smart-search',
	templateUrl: './smart-search.component.html',
	styleUrls: ['./smart-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartSearchComponent implements OnInit, OnDestroy {
	@ViewChild(NgSelectComponent, { static: true })
	ngSelectComponent: NgSelectComponent;

	smartSearchResults$: Observable<any>;
	smartSearchLoading = false;
	smartSearchInput$ = new Subject<string>();
	smartSearchGroupToggled$ = new Subject<void>();

	expandedGroups: SMART_SEARCH_IDS[] = [];

	withAdditionalInfo = [SMART_SEARCH_IDS.CLIENTS];

	constructor(
		private cd: ChangeDetectorRef,
		private router: Router,
		private smartSearchHttpService: SmartSearchHttpService
	) {}

	ngOnInit() {
		this.smartSearchResults$ = merge(
			this.smartSearchInput$.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				map((term) => ({ term, shouldFetchData: true }))
			),
			this.smartSearchGroupToggled$.pipe(
				withLatestFrom(this.smartSearchInput$),
				map(([, term]) => ({ term, shouldFetchData: false }))
			)
		).pipe(
			tap(() => (this.smartSearchLoading = true)),
			switchMap(({ shouldFetchData, term }) => {
				if (!shouldFetchData) {
					return this.smartSearchHttpService.lastSearchResult$;
				}

				return this.smartSearchHttpService.search(term).pipe(catchError(() => of<SmartSearchResult[]>([])));
			}),
			map((smartSearchResults) =>
				smartSearchResults.map(({ searchId, results }) => ({
					isGroup: true,
					searchId,
					results: this.getGroupExpandedState(searchId) ? results.map((result) => ({ ...result, searchId })) : [],
					resultsCount: results.length,
				}))
			),
			tap(() => {
				this.smartSearchLoading = false;
			})
		);
	}

	ngOnDestroy() {
		this.smartSearchInput$.complete();
	}

	getSmartSearchGroupCaption(searchId: SMART_SEARCH_IDS): string {
		return smartSearchGroupCaptions[searchId];
	}

	getSmartSearchGroupIcon(searchId: SMART_SEARCH_IDS): string {
		return smartSearchGroupIcons[searchId];
	}

	formatSmartSearchItem(searchId: SMART_SEARCH_IDS, item): string {
		switch (searchId) {
			case SMART_SEARCH_IDS.OPPORTUNITIES:
				return `#${item.number} ${item.clientName} | ${item.propertyName}`;
			case SMART_SEARCH_IDS.BIDS:
				return `#${item.number} ${item.projectTitle || ''} ${item.clientName} | ${item.propertyName}`;
			case SMART_SEARCH_IDS.JOBS:
				return `#${item.number} ${item.clientName} | ${item.propertyName}`;
			case SMART_SEARCH_IDS.CLIENTS:
				return `${item.name}`;
			case SMART_SEARCH_IDS.PROPERTIES:
				return `${item.name} ${addressToString(item.address)}`;
			case SMART_SEARCH_IDS.MANAGERS:
				return `${item.name}`;
			default:
				return 'unknown';
		}
	}

	formatSmartSearchItemAdditionalInfo(searchId: SMART_SEARCH_IDS, item): string {
		switch (searchId) {
			case SMART_SEARCH_IDS.CLIENTS:
				return addressToString(item.address);
			default:
				return '';
		}
	}

	smartSearchChange(item) {
		if (!item) {
			return;
		}

		const isGroup = item?.isGroup;

		if (isGroup) {
			this.toggleGroup(item.searchId);
		} else {
			this.navigateFromSmartSearch(item);
		}

		this.ngSelectComponent.clearModel();
	}

	navigateFromSmartSearch(item) {
		this.ngSelectComponent.close();

		switch (item.searchId) {
			case SMART_SEARCH_IDS.OPPORTUNITIES:
				this.router.navigate(['workflow/opportunities/edit', item.id]);
				break;
			case SMART_SEARCH_IDS.BIDS:
				this.router.navigate(['workflow/bids/edit', item.id]);
				break;
			case SMART_SEARCH_IDS.JOBS:
				this.router.navigate(['workflow/jobs/edit', item.id]);
				break;
			case SMART_SEARCH_IDS.CLIENTS:
				this.router.navigate(['workflow/clients/edit', item.id]);
				break;
			case SMART_SEARCH_IDS.PROPERTIES:
				this.router.navigate(['workflow/properties/edit', item.id]);
				break;
			case SMART_SEARCH_IDS.MANAGERS:
				this.router.navigate(['workflow/managers/edit', item.id]);
				break;
		}
	}

	getGroupExpandedState(searchId: SMART_SEARCH_IDS): boolean {
		return this.expandedGroups.includes(searchId);
	}

	toggleGroup(searchId: SMART_SEARCH_IDS) {
		const isExpanded = this.getGroupExpandedState(searchId);

		if (isExpanded) {
			this.expandedGroups = this.expandedGroups.filter((expandedGroup) => expandedGroup !== searchId);
		} else {
			this.expandedGroups = [...this.expandedGroups, searchId];
		}
		this.smartSearchGroupToggled$.next();
	}

	close() {
		this.expandedGroups = [];
	}
}
