import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { SMART_SEARCH_IDS } from '../../../pss2-shared/sharedConstants';
import { Address } from '../../../pss2-shared/address';

export interface SmartSearchResult {
	searchId: SMART_SEARCH_IDS;
	results: { id: number; name: string; address?: Address }[];
}
[];

@Injectable({
	providedIn: 'root',
})
export class SmartSearchHttpService {
	private _lastSearchResult$ = new BehaviorSubject<SmartSearchResult[]>([]);

	get lastSearchResult$(): Observable<SmartSearchResult[]> {
		return this._lastSearchResult$.asObservable();
	}

	constructor(private http: HttpClient) {}

	search(term: string) {
		return this.http
			.get<SmartSearchResult[]>(`/api/restricted/tools/smart-search/search`, {
				params: { term },
			})
			.pipe(
				tap((searchResult) => {
					this._lastSearchResult$.next(searchResult);
				})
			);
	}
}
