import {
	ChatHistoryQuery,
	ChatLoginQuery,
	ChatMarkMessagesReadQuery,
	ChatMessage,
	ChatSystemNotification,
	ChatUserState,
} from '../../../../../pss2-shared/chatTypes';

enum WsActionTypes {
	CONNECT_TO_SERVER = 'CONNECT_TO_SERVER',
	DISCONNECT_FROM_SERVER = 'DISCONNECT_FROM_SERVER',
}

enum ChatActionTypes {
	CHAT_LOGIN = 'CHAT_LOGIN',
	SEND_MESSAGE = 'SEND_MESSAGE',
	INCOMING_MESSAGE = 'INCOMING_MESSAGE',
	PULL_CHAT_HISTORY = 'PULL_CHAT_HISTORY',
	PULL_CHAT_ONLINE_STATE = 'PULL_CHAT_ONLINE_STATE',
	USER_CHAT_STATE_UPDATED = 'USER_CHAT_STATE_UPDATED',
	MARK_CHAT_MESSAGES_READ = 'MARK_CHAT_MESSAGES_READ',
	INCOMING_SYSTEM_NOTIFICATION = 'INCOMING_SYSTEM_NOTIFICATION',
	REMOVE_SYSTEM_NOTIFICATION = 'REMOVE_SYSTEM_NOTIFICATION',
}

export class ChatLogin {
	static readonly type = ChatActionTypes.CHAT_LOGIN;
	constructor(public payload: ChatLoginQuery) {}
}

export class IncomingMessage {
	static readonly type = ChatActionTypes.INCOMING_MESSAGE;
	constructor(public payload: ChatMessage) {}
}

export class PullChatHistory {
	static readonly type = ChatActionTypes.PULL_CHAT_HISTORY;
	constructor(public payload: ChatHistoryQuery) {}
}

export class PullChatOnlineState {
	static readonly type = ChatActionTypes.PULL_CHAT_ONLINE_STATE;
	constructor() {}
}

export class UserChatStateUpdated {
	static readonly type = ChatActionTypes.USER_CHAT_STATE_UPDATED;
	constructor(public payload: ChatUserState) {}
}

export class SendMessage {
	static readonly type = ChatActionTypes.SEND_MESSAGE;
	constructor(public payload: ChatMessage) {}
}

export class MarkChatMessagesRead {
	static readonly type = ChatActionTypes.MARK_CHAT_MESSAGES_READ;
	constructor(public payload: ChatMarkMessagesReadQuery) {}
}

export class ConnectToServer {
	static readonly type = WsActionTypes.CONNECT_TO_SERVER;
	constructor(public payload?: { reconnectionTimeout?: number }) {}
}

export class DisconnectFromServer {
	static readonly type = WsActionTypes.DISCONNECT_FROM_SERVER;
	constructor(public payload: { code: number; reason: string }) {}
}

export class IncomingSystemNotification {
	static readonly type = ChatActionTypes.INCOMING_SYSTEM_NOTIFICATION;
	constructor(public payload: ChatSystemNotification) {}
}

export class RemoveSystemNotification {
	static readonly type = ChatActionTypes.REMOVE_SYSTEM_NOTIFICATION;
	constructor(public payload: { index: number }) {}
}
