import { Component, Input, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ClrDatagridFilterInterface, ClrDatagridFilter, ClrPopoverEventsService, ClrPopoverToggleService } from '@clr/angular';
import { Store } from '@ngxs/store';
import { zonedTimeToUtc } from 'date-fns-tz';
import { endOfDay } from 'date-fns/esm';
import { Subject, Observable, Subscription } from 'rxjs';
import { AdminAuthState } from 'src/app/admin/store/admin.store';
import { AuthState } from 'src/app/auth/store/auth.store';
import { parseDatesUS } from 'src/app/shared/utils/dateUtils';

@Component({
	selector: 'app-clr-dg-date-filter',
	templateUrl: './clr-dg-date-filter.component.html',
	styles: [],
})
export class ClrDgDateFilterComponent<T = any> implements ClrDatagridFilterInterface<T, any>, AfterViewInit, OnDestroy {
	@Input()
	property: string;

	@Input()
	low: Date = null;

	@Input()
	high: Date = null;

	@Input()
	isAdmin: boolean = false;

	@Input()
	clearSubject: Subject<string> = null;

	sub: Subscription;

	@ViewChild('focusMe')
	focusMe: ElementRef;

	/**
	 * The Observable required as part of the Filter interface
	 */
	private pChanges = new Subject<[Date, Date]>();

	user;
	constructor(
		filterContainer: ClrDatagridFilter,
		private clrPopoverEventsService: ClrPopoverEventsService,
		private store: Store,
		private smartToggleService: ClrPopoverToggleService
	) {
		filterContainer.setFilter(this);
	}

	// We do not want to expose the Subject itself, but the Observable which is read-only
	public get changes(): Observable<[Date, Date]> {
		return this.pChanges.asObservable();
	}

	public get state() {
		this.user = !this.isAdmin ? this.store.selectSnapshot(AuthState.user) : this.store.selectSnapshot(AdminAuthState);
		const dates = parseDatesUS(['low', 'high'], { low: this.low, high: this.high }, this.user.timezone);
		return {
			property: this.property,
			value: [dates.low ? dates.low.toISOString() : null, dates.high ? zonedTimeToUtc(endOfDay(dates.high), this.user.timezone).toISOString() : null],
		};
	}

	ngAfterViewInit() {
		this.clrPopoverEventsService.outsideClickClose = false;

		this.sub = this.smartToggleService.openChange.subscribe((openChange) => {
			if (openChange)
				setTimeout(() => {
					this.focusMe.nativeElement.focus();
				});
		});
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	accepts(item: T): boolean {
		return true;
	}

	/**
	 * Indicates if the filter is currently active, (at least one input is set)
	 */
	public isActive(): boolean {
		return this.low !== null || this.high !== null;
	}

	public equals(other: any): boolean {
		return other.prop === this.property && other.low === this.low && other.high === this.high;
	}
	apply() {
		this.pChanges.next([this.low, this.high]);
	}

	clear() {
		this.low = null;
		this.high = null;
		this.apply();
	}
}
