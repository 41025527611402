export function getCurrentTimezone(): string | null {
	let timezone;

	try {
		timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	} catch {
		timezone = null;
	}

	return timezone;
}
