import { partial } from 'ramda';
import { endOfDay, parse } from 'date-fns/esm';
import { zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz';
import { format, isBefore, isSameDay, isValid } from 'date-fns';

export const US_DATE_FMT = 'MM/dd/yyyy';

export const validateUSDates = (dateStrings: string[], params?: { allowEmpty: boolean }) =>
	dateStrings.every((date) => (!date ? Boolean(params?.allowEmpty) : isValid(parse(date, US_DATE_FMT, new Date()))));

export const validateUSDateRange = (startDate, endDate, params?: { allowEmpty: boolean }) =>
	validateUSDates([startDate, endDate], { allowEmpty: params?.allowEmpty }) &&
	(!startDate || !endDate || isBefore(new Date(startDate), new Date(endDate)) || isSameDay(new Date(startDate), new Date(endDate)));

export function formatDates(fmt: string, dateProps: string[], data: any, timezone: string) {
	const fixed = {};
	dateProps.forEach((prop) => {
		if (data[prop] != null) {
			fixed[prop] = formatInTimeZone(new Date(data[prop]), timezone, fmt);
		}
	});
	return Object.assign({}, data, fixed);
}
export const formatDatesUS = partial(formatDates, [US_DATE_FMT]);

export function parseDates(fmt: string, dateProps: string[], data: any, timezone: string) {
	const fixed = {};
	dateProps.forEach((prop) => {
		if (data[prop] != null) {
			fixed[prop] = zonedTimeToUtc(parse(data[prop], fmt, new Date()), timezone);
		}
	});
	return Object.assign({}, data, fixed);
}
export const parseDatesUS = partial(parseDates, [US_DATE_FMT]);

export const formatDateUS = (date: Date) => format(date, US_DATE_FMT);
export const formatDateUSTz = (date: Date, timezone: string) => formatInTimeZone(date, timezone, US_DATE_FMT);
export const parseUSDate = (dateString: string) => parse(dateString, US_DATE_FMT, new Date());
export const parseUSDateTz = (dateString: string, timezone: string) => zonedTimeToUtc(parse(dateString, US_DATE_FMT, new Date()), timezone);
export const convertUSDateToISO = (dateString: string, timezone: string) => zonedTimeToUtc(parse(dateString, US_DATE_FMT, new Date()), timezone).toISOString();
export const convertUSDateToISOEndOfDay = (dateString: string, timezone: string) =>
	zonedTimeToUtc(endOfDay(parse(dateString, US_DATE_FMT, new Date())), timezone).toISOString();

export const formatTime = (date: Date, timezone: string): string => formatInTimeZone(date, timezone, 'HH:mm:ssX');
export const parseTime = (timeString: string, dateStr: string): Date => parse(timeString, 'HH:mm:ssX', new Date(dateStr));
