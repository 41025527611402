import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthPageState } from '../../store/auth-page.store';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidationErrors } from '@angular/forms';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { omit } from 'ramda';
import { UpdateSecurityInfo } from '../../store/auth.actions';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { lowerCharacters, minLength, specialCharacters, upperCharacters } from 'src/app/shared/utils/formUtils';
import { SECURITY_QUESTIONS } from 'src/app/shared/utils/securityQuestions';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-update-password',
	templateUrl: './update-password.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
	@ViewChild(ClrForm, { static: true })
	clrForm: ClrForm;

	error$;

	submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	subject$ = new Subject();

	form: UntypedFormGroup = new UntypedFormGroup({
		newPassword: new UntypedFormControl('', [
			Validators.required,
			minLength(9),
			specialCharacters,
			upperCharacters,
			lowerCharacters,
			this.passwordsUpdaterValidator,
		]),
		confirmPassword: new UntypedFormControl('', [
			Validators.required,
			minLength(9),
			specialCharacters,
			upperCharacters,
			lowerCharacters,
			this.passwordsTheSameValidator,
		]),
		newSecurityQuestion: new UntypedFormControl('', Validators.required),
		newSecurityAnswer: new UntypedFormControl('', Validators.required),
		currentPassword: new UntypedFormControl(null, Validators.required),
	});

	get password() {
		return this.form.value.password;
	}

	passwordsTheSameValidator(confirmPass: UntypedFormControl): ValidationErrors | null {
		if (!confirmPass || !confirmPass.parent) {
			return null;
		}
		const pass = confirmPass.parent.get('newPassword');
		if (!pass) return null;
		return pass && confirmPass && pass.value === confirmPass.value ? null : { passwordsNotSame: true };
	}

	passwordsUpdaterValidator(pass: UntypedFormControl): ValidationErrors | null {
		if (!pass || !pass.parent) {
			return null;
		}
		const confirmPass = pass.parent.get('confirmPassword');
		if (!confirmPass) return null;
		confirmPass.updateValueAndValidity();
		return null;
	}

	constructor(
		private store: Store,
		private toaster: ToastrService,
		private cd: ChangeDetectorRef,
		private route: ActivatedRoute
	) {}

	questions = SECURITY_QUESTIONS;

	ngOnInit() {
		this.error$ = this.store.select(AuthPageState.error);
		this.error$.pipe(takeUntil(this.subject$)).subscribe(() => {
			this.submitBtnState = ClrLoadingState.DEFAULT;
			this.cd.markForCheck();
		});
		this.store
			.select(AuthPageState.pending)
			.pipe(
				tap((isPending) => (isPending ? this.form.disable() : this.form.enable())),
				takeUntil(this.subject$)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.subject$.complete();
	}

	async submit() {
		if (!this.form.valid) {
			this.clrForm.markAsTouched();
			this.toaster.error('Please fill all required fields!');
			return;
		}
		this.submitBtnState = ClrLoadingState.LOADING;
		this.cd.markForCheck();
		const newPasswordData: any = omit(['confirmPassword'], this.form.value);
		this.store.dispatch(new UpdateSecurityInfo({ security: newPasswordData, returnUrl: this.route.snapshot.queryParams.returnUrl }));
	}
}
