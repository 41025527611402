export const DEFAULT_TIMEZONE = 'Etc/UTC';

export const TIMEZONES = [
	{
		label: 'GMT/UTC (UTC-00:00)',
		value: DEFAULT_TIMEZONE,
	},
	{
		label: 'New York (UTC-04:00)',
		value: 'America/New_York',
	},
	{
		label: 'Chicago (UTC-05:00)',
		value: 'America/Chicago',
	},
	{
		label: 'Denver (UTC-06:00)',
		value: 'America/Denver',
	},
	{
		label: 'Phoenix (UTC-07:00)',
		value: 'America/Phoenix',
	},
	{
		label: 'Los Angeles (UTC-07:00)',
		value: 'America/Los_Angeles',
	},
	{
		label: 'Anchorage (UTC-08:00)',
		value: 'America/Anchorage',
	},
	{
		label: 'Adak (UTC-09:00)',
		value: 'America/Adak',
	},
	{
		label: 'Honolulu (UTC-10:00)',
		value: 'Pacific/Honolulu',
	},
];
