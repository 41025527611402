import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProtectedModule } from '../protected.module';
import { UserDto } from '../../../../../pss2-shared/apiModels/index';
import { AdminDto, AdminLoginModelRequest } from '../../../../../pss2-shared/apiModels/admin/index';

@Injectable({
	providedIn: ProtectedModule,
})
export class AdminAuthHttpService {
	constructor(private http: HttpClient) {}

	login(payload: AdminLoginModelRequest) {
		return this.http.post<AdminDto>('/api/public/admin-login', payload);
	}

	loginAsUser(payload: { email: string }) {
		return this.http.post<UserDto>(`/api/admin/login-as-user`, payload);
	}

	checkLogin() {
		return this.http.get<AdminDto>('/api/admin/check-login');
	}

	logout() {
		return this.http.post('/api/admin/logout', {});
	}

	requestOtpCode() {
		return this.http.get<any>('/api/verify-otp/admin');
	}

	verifyOtpCode(data) {
		return this.http.post<AdminDto>('/api/verify-otp/admin', data);
	}
}
