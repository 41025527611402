import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceHttpService } from 'src/app/shared/resource-http.service';
import { UserSiteReportTask } from '../../../../../pss2-shared/siteReport';

@Injectable({
	providedIn: 'root',
})
export class UsersHttpService extends ResourceHttpService {
	constructor(http: HttpClient) {
		super(http, 'company/users');
	}

	addUser(user) {
		return this.http.post(`${this.entityUrl}/add-user`, user);
	}

	getUserOffices(userId: number) {
		return this.http.get<any[]>(`${this.entityUrl}/${userId}/offices`);
	}

	addUserOffice(userId: number, officeId: number) {
		return this.http.put(`${this.entityUrl}/${userId}/offices`, { officeId });
	}

	setDefaultUserOffice(userId: number, officeId: number) {
		return this.http.put(`${this.entityUrl}/${userId}/offices/default`, { officeId });
	}

	deleteUserOffice(userId: number, officeId: number) {
		return this.http.delete(`${this.entityUrl}/${userId}/offices/${officeId}`);
	}

	updateSecurityData(data) {
		return this.http.put(`${this.entityUrl}/security-data`, data);
	}

	getSecurityData() {
		return this.http.get(`${this.entityUrl}/security-data`);
	}

	deleteOneSafe(userId: number, newUserId: number) {
		return this.http.post(`${this.entityUrl}/safe-remove-user`, { userId, newUserId });
	}

	deactivateUser(userId: number) {
		return this.http.put(`${this.entityUrl}/deactivate-user`, { id: userId });
	}

	activateUser(userId: number) {
		return this.http.put(`${this.entityUrl}/activate-user`, { id: userId });
	}

	getUserGoals(userId: number) {
		return this.http.get(`${this.entityUrl}/${userId}/goals`);
	}

	setUserGoals(userId: number, goals: any) {
		return this.http.post(`${this.entityUrl}/${userId}/goals`, goals);
	}

	removeUser(removeUserId: number, assignToUserId: number) {
		return this.http.put(`${this.entityUrl}/remove-user`, { removeUserId, assignToUserId });
	}

	getUserSiteReportTasks(userId: number) {
		return this.http.get<UserSiteReportTask[]>(`${this.entityUrl}/${userId}/siteReportTasks`);
	}

	saveUserSiteReportTask(userId: number, body: Partial<UserSiteReportTask>) {
		return this.http.post(`${this.entityUrl}/${userId}/siteReportTasks`, body);
	}

	removeUserSiteReportTask(userId: number, siteReportTaskId: number) {
		return this.http.delete(`${this.entityUrl}/${userId}/siteReportTasks/${siteReportTaskId}`);
	}
}
